import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";
import {dotenv, routes} from "../../config/config";
import {
    createObjectFromObjectsArray,
    deepCopyObject,
    deepGet, deepSet,
    inArray,
    isMainProject,
    objectLength,
    toNum,
} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {notice} from "../../library/notice";
import {FormTitle} from "../../components/Form/FormTitle";
import {FormFields} from "../../components/Form/Field/FormFields";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {messengers} from "../../schemas/frontend/effects";

const {Content} = Layout;

export const createObjectFromIntegrations = (t, array = [], addPlatformTypes = true, filterByTypes = null) => {
    let result = {};
    for (const item of array) {
        const platform_name = deepGet(item, 'integration.platform', 'none')
        if (filterByTypes && !inArray(platform_name, filterByTypes)) continue

        result[item.integration.id] = item.title ?? String(item.integration.id);

        if (addPlatformTypes) {
            const platform = t('integration.' + platform_name + '.title')
            result[item.integration.id] += ' - ' + platform
        }
    }
    return result;
}

export const getPlatformItems = (array = [], platformName = null) => {
    let result = {};
    for (const item of array) {
        if (item.integration.platform !== platformName) continue
        result[item.integration.unique_hash] = item.title ?? String(item.integration.id);
    }
    return result;
}

const IntegrationEdit = () => {
    const section = 'platform'

    // data from GET params
    const params = useParams()
    const id = Number(params.id)

    const [searchParams] = useSearchParams();
    const platform_name = searchParams.get('platform')

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    // sync with store
    const {admin, project, integration, site, sect} = useSelector(store => store);
    const sites = createObjectFromObjectsArray(site.list || []);

    const project_list = createObjectFromObjectsArray(project.list || [])
    const shared_list = createObjectFromObjectsArray(project.shared || [])
    const projects = {...project_list, ...shared_list}

    const [showExtra, setShowExtra] = useState(false);

    let item = {id: 0, integration: {id: 0, platform: platform_name}}
    const integration_id = deepGet(integration, 'item.integration.id', {});
    const platform = item.integration.platform;
    if (objectLength(integration.item) && id && integration_id === id) item = integration.item;

    const project_id = project.item.id;
    const isMainProj = isMainProject(project_id);
    const backToPath = `${routes.project_list}/${project_id}${routes.local.integration_list}/${platform}`
    // const backToSection = `${routes.project_list}/${project_id}${routes.local.integration_list}`

    const vars = {
        object: {
            id: id,
            project_id: project_id,
            platform: platform,
            username: deepGet(item, 'integration.params.username'),
            is_token_editable: deepGet(item, 'integration.is_private') ? '0' : '1',
        },
        form: {
            isAdding: id === 0 ? '1' : '0',
            isEditing: id > 0 ? '1' : '0',
        },
        app: {
            name: dotenv.home_page_domain.replace('.','').toLowerCase(),
        },
        locale: {
            placeholder: {
                menu: t('common.placeholder.menu')
            }
        }
    }

    let formFields = [];
    for (const intItem of sect.integration) {
        if (intItem.name === platform) {
            // console.log('intItem', intItem)
            formFields = intItem.fields.map((field) => {
                return {...field}
            })
            break;
        }
    }

    // get form and set values
    const [form] = Form.useForm()
    const formValues = item;

    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (!admin.authorized) return
        else if (project_id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'getIntegrationItem', admin, id, project_id});
        }

        if (admin.authorized && project_id && !integration.list.length) {
            dispatch({type: 'getIntegrationList', admin, filters: {project_id}});
        }

        if (admin.authorized && project_id && platform === 'vk') {
            dispatch({type: 'getSiteList', admin, filters: {project_id}});
        }

        if (admin.authorized && !sect.integration.length) {
            dispatch({type: 'getSectData', admin, section: 'integration', filters: {
                // is_on: true, // required for other sections!
                page_size: 0,
            }});
        }

        if (!platform) {
            notice.warning(t(section + '.error.platform_required'));
            //navigate(backToSection);
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_id])



    useEffect(() => {
        // set field values if correct data received
        let values = deepCopyObject(item)

        // convert json to string
        let prices = deepGet(values, ['integration', 'params', 'prices'], null)
        if (prices) prices = JSON.stringify(prices, null, 2)

        // set prices as string value
        values = deepSet(values, ['integration', 'params', 'prices'], prices)
        // console.log('values', prices, values)

        values.integration.project_id = values.integration.project_id ? String(values.integration.project_id) : null;
        values.integration.event_project_id = values.integration.event_project_id ? String(values.integration.event_project_id) : null;
        values.integration.site_id = values.integration.site_id ? String(values.integration.site_id) : null;

        form.setFieldsValue(values);

        //eslint-disable-next-line
    }, [item.id + item.integration.token + item.updated_at])

    const backToList = () => {
        navigate(backToPath);
    }
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        // console.log('onFinish values', values);
        let data = deepCopyObject(values);
        if (!data.integration) data['integration'] = {};

        data['project_id'] = project_id;
        data['integration']['platform'] = platform;

        if (item.integration.id) data['integration']['id'] = item.integration.id;
        if (!data['integration']['params']) data['integration']['params'] = {};

        const site_id = deepGet(data['integration']['params'], ['site_id']);
        if (site_id !== undefined && !site_id) {
            data['integration']['params']['site_id'] = null;
        }

        const prices = deepGet(data['integration']['params'], ['prices']);
        if (prices) {
            try {
                data['integration']['params']['prices'] = JSON.parse(prices);
            } catch (e) {
                notice.error('Json parse error: ' + e.message);
                return;
            }
        }

        if (inArray('project_id', Object.keys(values.integration || {}))) {
            data['integration']['project_id'] = toNum(data['integration']['project_id']);
            data['integration']['event_project_id'] = toNum(data['integration']['event_project_id']);
        }

        // console.log('onFinish final values', inArray('project_id', Object.keys(values.integration)), data);

        const platformDispatchType = item.integration.id ? 'updateIntegration' : 'createIntegration';
        if (platformDispatchType) dispatch({type: platformDispatchType, admin, data});

        // form.setFieldsValue(data);
        // if (!item.integration.id)
        backToList()
    }

    // check is platform already set
    // if (!formFields[platform]) return null;

    const commonFields = [
        {
            name: 'title',
            type: 'text',
            section: section,
            placeholder: t('common.placeholder.text'),
            isVisible: vars.form.isEditing,
        },
        {
            name: 'is_on',
            type: 'switcher',
            section: section,
            isVisible: vars.form.isEditing,
        },
        {
            name: 'integration.is_public',
            type: 'switcher',
            section: section,
            isVisible: isMainProj && vars.form.isEditing,
        },
        {
            name: "integration.params.prices",
            type: "textarea",  // TODO: fix editor field for deep names
            isVisible: isMainProj && vars.form.isEditing,
            section: section,
            form: form,
            language: 'js',
            placeholder: '[{"model_name": "gpt-4o-example", "price": {"currency": "usd", "per_tokens": 1000, "input": 0.0005, "output": 0.0015}}]',
        },
    ]

    const extra_fields = (item.id && !item.integration.is_private && inArray(platform_name, messengers)) ? [
        {
            name: 'show_extra',
            type: 'switcher',
            section: section,
            isVisible: vars.form.isEditing,
            onChange: (value) => setShowExtra(value),
        },
        {
            name: 'integration.project_id',
            type: 'menu',
            section: section,
            values: projects,
            localized: false,
            isVisible: vars.form.isEditing && showExtra,
        },
        {
            name: 'integration.event_project_id',
            type: 'menu',
            section: section,
            values: projects,
            localized: false,
            isVisible: vars.form.isEditing && showExtra,
        },
    ] : []

    if (formFields) {
        for (const field of formFields) {
            if (field.data === 'site') {
                field['values'] = sites;
            } else if (field.data === 'integration') {
                field['values'] = getPlatformItems(
                    integration.list || [],
                    deepGet(field, ['data_filters', 'integration.platform'])
                );
            }
        }
    }

    let formFieldsResult = [...commonFields, ...formFields, ...extra_fields]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                {/*{(!id || item.id === id) ? */}
                <Form
                    form={form}
                    name="settings"
                    layout="vertical"
                    autoComplete='off'
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    // initialValues={formValues}
                >
                    <FormTitle onBack={backToList}>
                        {item.id ? t('common.action.edit') : t('common.action.add')} {t(section + '.object.title')}
                    </FormTitle>

                    <Content className="page-container form-container site-layout-background">
                        <FormFields
                            t={t}
                            section={"integration." + platform}
                            fields={formFieldsResult}
                            formValues={formValues}
                            vars={vars}
                        />
                        <FormSubmit onCancel={backToList}/>
                    </Content>
                </Form>
                {/*: <Preloader/>}*/}
            </Layout>
        </AppWrapper>
    )
}

export default IntegrationEdit