import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {ListGameManager} from "../../../components/List/Folder/ListGameManager";
import {setLoadingStatus} from "../../../redux/reducers/StatusReducer";
import GameCommonListTable from "./GameCommonListTable";
import Preloader from "../../System/Preloader";

const {Content} = Layout;

const GameCommonList = () => {
    const default_ordering = ['-created_at', '-id'];

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const section = params.section

    // store and state
    const {app, admin, project, field} = useSelector(store => store)
    const [ordering, setOrdering] = useState('default')
    const [filters, setFilters] = useState({})

    // init universal vars
    const project_item = project.item
    const status = app.status
    const list = field.list.filter(item => item.system_field_type === section)

    // get data from API
    useEffect(() => {
        dispatch(setLoadingStatus('loading'))

        if (section && admin.authorized && project_item.id) {
            const order = (ordering === 'default') ? default_ordering : ordering
            let filters_result = {project_id: project_item.id, ordering: order, system_field_types: [section]}
            if (filters) filters_result = {...filters_result, ...filters}
            dispatch({type: 'getFieldList', admin, filters: filters_result})
        }
        //eslint-disable-next-line
    }, [section, admin.authorized, project_item.id, filters, ordering])

    if (!section) return null

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordCreate = (folder_id = 0) => {
        navigate(`${routes.project_list}/${project_item.id}/${section}/edit/0?folder=${folder_id}`)
    }

    const filterFields = [
        [
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimaryWithFolder={recordCreate}
                />
                <Content className="page-container site-layout-background">
                    {status === 'ready' ?
                        <GameCommonListTable
                            admin={admin}
                            project={project_item}
                            section={section}
                            list={list}
                            filters={filters}
                            setFilters={setFilters}
                            setOrdering={setOrdering}
                            recordCreate={recordCreate}
                        /> : <Preloader/>}
                    <ListGameManager section={section} project_item={project_item} list={list}/>
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default GameCommonList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link