import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Button, Form, Layout, PageHeader, Tabs} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    createObjectFromObjectsArray,
    deepCopyObject,
    unixFromMoment,
    momentFromUnix,
    objDeepExtend,
    objectLength,
    getItemValues,
    ucFirst,
    toNum,
    is,
} from "../../../library/functions";
import {dotenv, routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {useTranslation} from "react-i18next";
import {
    ArrowLeftOutlined,
    BgColorsOutlined,
    CheckOutlined,
    LineChartOutlined,
    MenuOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {FormSubmit} from "../../../components/Form/FormSubmit";
import {FormFields} from "../../../components/Form/Field/FormFields";
import {useHotkeys} from "react-hotkeys-hook";

const {Content, Header} = Layout;

const SiteEdit = () => {
    const {t} = useTranslation()
    const section = 'site'
    const Section = ucFirst(section)
    const adminSection = 'site'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    const back_to_page = toNum(searchParams.get('back_to_page'))
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // init states
    const [iconUrl, setIconUrl] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [tab, setTab] = useState('styles')

    // sync with store
    const {admin, project, site, theme, page, folder, sect} = useSelector(store => store)
    const item = site.item
    const project_item = project.item;
    const pages = createObjectFromObjectsArray(page.short.list);
    const themes = createObjectFromObjectsArray(theme.short.list, 'name');

    // get form and set values
    const [formPixels] = Form.useForm();
    const [pixelsValues, setPixelsValues] = useState({});

    let pixelsFields = [];
    let pixelsLocale = 'common';

    for (const pixel_set of sect.pixel) {
        pixelsLocale = pixel_set.section + '.' + pixel_set.name
        pixelsFields = [...pixelsFields, ...pixel_set.fields]
    }

    const defaultGlobalValues = {
        logo_url: '',
        favicon_url: '',
        descriptor: '',
    }

    const defaultNavValues = {
        footer: '',
        footer_logo: false,

        menu: {
            main: null,
            user: null,
            footer: null,
            bottom: null,
            law: null,
        },
    }

    // itis form state
    const [folderId, setFolderId] = useState(0)
    const [formValues, setFormValues] = useState({
        title: '',
        theme: null,
        params: {global: defaultGlobalValues}
    })
    const [navValues, setNavValues] = useState({params: {global: defaultNavValues}})
    const [settingsValues, setSettingsValues] = useState({
        description: '',
        published_at: '',
        type: 'common',
        params: {
            folder_id: folder_id,
            lang: dotenv.default_lang,
            style: {
                dark: false,
            },
            privacy: {
                hide_desc: false,
            },
            page: {
                undefined: null,
                unauthorized: null,
                forbidden: null,
            }
        }
    })

    const [form] = Form.useForm();
    const formFields = [
        {
            name: "title",
            type: "text",
            placeholder: true,
            required: true,
            // label: t('common.form.title.label'),
            // placeholder: t('common.form.title.placeholder'),
            // desc: t('common.form.title.desc'),
        },
        {
            name: "theme_name",
            type: "menu",
            data: 'theme',
            values: themes,
            placeholder: t('common.placeholder.default'),
        },

        {
            name: "params.global.graphics",
            type: "heading",
        },
        {
            name: "params.global.logo_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: logoUrl,
            aspect: 0,
            label: t(section + '.form.params.global.logo_url.label'),
            placeholder: t(section + '.form.params.global.logo_url.label'),
            wrapperClassName: 'fit-contain',
        },
        {
            name: "params.global.descriptor",
            type: "text",
            // label: t('common.form.title.label'),
            placeholder: t('common.placeholder.text'),
            // desc: t('common.form.title.desc'),
        },
        {
            name: "params.global.favicon_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: iconUrl,
            aspect: 0,
            label: t(section + '.form.params.global.favicon_url.label'),
            placeholder: t(section + '.form.params.global.favicon_url.label'),
        },
    ]

    const [formNav] = Form.useForm();
    const formNavFields = [
        {
            name: "params.global.header_title",
            type: "heading",
        },
        {
            name: "params.global.menu.main",
            type: "nav",
            // desc: '',
            placeholder: t('common.placeholder.add'),
            notAllowVars: true, // for submenu
            // form: form,
            // section: section,
        },
        {
            name: "params.global.menu.user",
            type: "nav",
            // desc: '',
            placeholder: t('common.placeholder.add'),
            notAllowVars: true, // for submenu
            // form: form,
            // section: section,
        },

        {
            name: "params.global.footer_title",
            type: "heading",
        },
        {
            name: "params.global.footer_logo",
            type: "switcher",
        },
        {
            name: "params.global.footer",
            type: "editor",
            // desc: '',
            form: formNav,
            extended: true,
            formValues: formValues,
        },
        {
            name: "params.global.menu.footer",
            type: "nav",
            // desc: '',
            placeholder: t('common.placeholder.add'),
            notAllowVars: true,
        },
        {
            name: "params.global.menu.bottom",
            type: "nav",
            // desc: '',
            placeholder: t('common.placeholder.add'),
            notAllowVars: true,
        },
        {
            name: "params.global.menu.law",
            type: "nav",
            // desc: '',
            placeholder: t('common.placeholder.add'),
            notAllowVars: true,
        },

    ]

    const [formSettings] = Form.useForm();
    const formSettingsFields = [
        {
            name: "params.page.title",
            type: "heading",
        },
        {
            name: "params.page.undefined",
            type: "menu",
            data: 'page',
            values: pages,
            isVisible: id > 0,
        },
        {
            name: "params.page.unauthorized",
            type: "menu",
            data: 'page',
            values: pages,
            isVisible: id > 0,
        },
        {
            name: "params.page.forbidden",
            type: "menu",
            data: 'page',
            values: pages,
            isVisible: id > 0,
        },
        {
            name: "params.title",
            type: "heading",
        },
        {
            name: "type",
            type: "menu",
            placeholder: '',
            values: [
                'common',
                'school',
                'blog',
                'shop',
                'base',
                'game',
            ],
            localized: true,
        },
        {
            name: "params.lang",
            type: "menu",
            placeholder: '',
            values: [
                'en',
                'en-us',
                'be',
                'nl',
                'es',
                'it',

                'kk',
                'zh',
                'ko',

                'de',
                'pl',
                'pt',
                'ru',
                'sk',
                'tr',
                'uk',
                'fr',
                'et',
                'ja',
            ],
            localized: true,
        },
        // {
        //     name: "is_on",
        //     type: "switcher",
        //     label: t('common.form.is_on.label'),
        // },
        // {
        //     // todo: fix field saving if needed
        //     name: "published_at",
        //     type: "datetime",
        //     format: 'DD MMMM YYYY HH:mm',
        // },
        // {
        //     name: "description",
        //     type: "textarea",
        // },
        {
            name: "params.folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: t('common.form.folder.desc'),
        },
        {
            name: "additional",
            type: "heading",
        },
        {
            name: "params.privacy.hide_desc",
            type: "switcher",
        },
        {
            name: "params.style.dark",
            type: "switcher",
        },
    ]

    // get data from API first
    useLayoutEffect(() => {
        // console.log('sites useLayoutEffect')

        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !theme.short.list.length) {
            dispatch({type: 'getThemeShortList', admin, filters: {project_id: project_item.id, site_ids: [0, id]}});
        }

        if (admin.authorized && project_item.id && id && (
            !page.short.list.length ||
            page.short.list[0].project_id !== project_item.id ||
            page.short.list[0].site_id !== id
        )) {
            dispatch({type: 'getPageShortList', admin, filters: {project_id: project_item.id, site_id: id}});
        }
        // else {
        //     console.log(
        //         'page.short.list.length', page.short.list.length,
        //         'project_id', page.short.list[0].project_id, project_item.id,
        //         'site_id', page.short.list[0].site_id, id,
        //     )
        // }

        if (admin.authorized && !sect.pixel.length) {
            dispatch({type: 'getSectData', admin, section: 'pixel', filters: {is_on: true, page_size: 0}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (id && item.id === id) {
            let values = {...item}
            if (!item.parent_id) values.parent_id = null

            if (values.params === null) values.params = {}
            else values.params = deepCopyObject(values.params)

            if (!values.params.global) values.params.global = {...defaultGlobalValues, ...defaultNavValues}

            // set values to base form
            const itemFormValues = getItemValues(values, formFields)
            form.setFieldsValue(itemFormValues)

            const itemNavValues = getItemValues(values, formNavFields)
            formNav.setFieldsValue(itemNavValues)

            const pixels = values.params.pixel || {}
            formPixels.setFieldsValue(pixels)

            if (item.published_at) values.published_at = momentFromUnix(item.published_at)
            else values.published_at = ''

            const settings = {
                description: values.description || '',
                published_at: values.published_at || '',
                type: values.type || 'common',
                params: {
                    folder_id: values.params.folder_id || 0,
                    lang: values.params.lang || dotenv.default_lang,
                    style: values.params.style || {},
                    privacy: values.params.privacy || {},
                    page: values.params.page || {},
                }
            }

            // set last form values
            formSettings.setFieldsValue(settings)

            setFormValues(itemFormValues)
            setNavValues(itemNavValues)

            setPixelsValues(pixels)
            setSettingsValues(settings)

            setIconUrl(values.params.global.favicon_url || '')
            setLogoUrl(values.params.global.logo_url || '')

            // for backToList url
            setFolderId(values.params.folder_id || 0)
        }
        //eslint-disable-next-line
    }, [item])

    useHotkeys(['ctrl+s', 'command+s'].join(','), e => {
        e.preventDefault();
        // console.log('useHotkeys', e.code);

        if (!id) return;

        // run key code actions
        switch (e.code) {
            case 'KeyS':
                onFinish(false);
                break;

            default:
                break;
        }
    }, [item, form, formPixels, formSettings]);

    // compose form functions
    const backToList = (folder_id = false) => {
        if (back_to_page) {
            navigate(`${routes.project_list}/${project_item.id}/${section}${routes.local.page_list}/${back_to_page}?site_id=${id}`);
        }
        else {
            if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
            navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`);
        }
    }

    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (back = true) => {
        let values = form.getFieldsValue();
        let nav = formNav.getFieldsValue();
        let settings = formSettings.getFieldsValue();
        let pixels = formPixels.getFieldsValue();

        // for cases like form was not rendered
        if (!objectLength(nav)) nav = navValues;
        if (!objectLength(settings)) settings = settingsValues;
        if (!objectLength(pixels)) pixels = pixelsValues;

        objDeepExtend(values, nav);
        objDeepExtend(values, settings);

        // console.log('Site values', values, '\nnav', nav, '\nsettings', settings);

        if (values.published_at) values.published_at = unixFromMoment(values.published_at);
        else values.published_at = null;

        if (!is(values.theme_name)) values.theme_name = null;
        values.params['pixel'] = pixels;

        formCreateOrUpdate(Section, values, null, id, project_item, admin, dispatch, true, !back)
        if (back) backToList(toNum(values.params.folder_id))
    }

    const renderFormFields = () => {
        return (<Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            initialValues={formValues}
            name={section + '_styles'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} form={form} section={section} fields={formFields} formValues={formValues}/>
        </Form>);
    }

    const renderFormNavFields = () => {
        return (<Form
            form={formNav}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            initialValues={formValues}
            name={section + '_nav'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} form={formNav} section={section} fields={formNavFields} formValues={navValues}/>
        </Form>);
    }

    const renderFormSettingsFields = () => {
        return (<Form
            form={formSettings}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            initialValues={settingsValues}
            name={section + '_params'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={formSettingsFields} formValues={settingsValues}/>
        </Form>);
    }

    const renderPixelsFields = () => {
        return (<Form
            form={formPixels}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_pixels'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={pixelsLocale} fields={pixelsFields} formValues={pixelsValues}/>
        </Form>);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Header className="site-layout-background page-container-horizontal-padding has-banner">
                    <PageHeader
                        title={item.id ?
                            (item.id && item.id === id ?
                                item.title : t('common.action.edit') + ' ' + t(section + '.object.title')) :
                            t('common.action.add') + ' ' + t(section + '.object.title')}
                        className="padding-none-horizontal"
                        extra={[
                            // back
                            <Button
                                key="return-button"
                                className="hidden-sm"
                                type="text"
                                icon={<ArrowLeftOutlined/>}
                                onClick={backToList}
                            >
                                {t('common.action.back')}
                            </Button>,

                            // save
                            /*<Button
                                key="primary-button"
                                type="primary"
                                htmlType="submit"
                                onClick={onFinish}
                                className="hidden-sm"
                            >
                                <span className="hidden-sm-important">{t('common.action.save')}</span>
                            </Button>*/

                            <Button.Group key="primary-set" className="rounded-items-sm">
                                {id ? <Button
                                    key="save-button"
                                    type="primary"
                                    icon={<CheckOutlined/>}
                                    onClick={() => {
                                        onFinish(false);
                                    }}
                                /> : null}
                                <Button
                                    key="primary-button"
                                    type="primary"
                                    htmlType="submit"
                                    onClick={onFinish}
                                    className="hidden-sm"
                                >
                                    <span className="hidden-sm-important">{t('common.action.save')}</span>
                                </Button>
                            </Button.Group>
                        ]}
                    />
                </Header>

                <Content
                    id="effects-list-wrapper"
                    className="page-container form-container site-layout-background padding-top-none"
                >
                    <Tabs
                        defaultActiveKey={tab}
                        onChange={setTab}
                        // size="large"
                        items={[
                            {
                                label: <span>
                                    <BgColorsOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.styles')}</span>
                                </span>,
                                key: 'styles',
                                children: renderFormFields(),
                            },
                            {
                                label: <span>
                                    <MenuOutlined />
                                    <span className="hidden-xs">{t(section + '.tab.nav')}</span>
                                </span>,
                                key: 'nav',
                                children: renderFormNavFields(),
                            },
                            {
                                label: <span>
                                    <SettingOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.settings')}</span>
                                </span>,
                                key: 'settings',
                                children: renderFormSettingsFields(),
                            },
                            {
                                label: <span>
                                    <LineChartOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.pixels')}</span>
                                </span>,
                                key: 'pixels',
                                children: renderPixelsFields(),
                            },
                        ]}
                    />

                    <FormSubmit onSubmit={onFinish} onCancel={backToList} className="margin-top-pm"/>
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default SiteEdit