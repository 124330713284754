// time: 48 min +

export const coreCode = {
    space: {
        content: [{
            type: 'con',
            style: {
                padding: {
                    bottom: '20',
                }
            },
            items: []
        }]
    },
    js: {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/code/js',
                            spec: {
                                content: 'console.log("Hello")',
                            },
                        },
                    ]
                }
            ],
            style: {},
            skip: true,
        }]
    },
    'import': {}
}