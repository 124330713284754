import {dotenv, field_values} from "../../config/config";

export const effects_categories = {
    core: {
        chat: [],
        wall: [],
        crm: [],
        sales: [],
        var: [],
        channel: [],
        site: [],
        game: [],
        project: [],
        timer: [],
    },
    integration: {
        delivery: [],
        sales: [],
        crm: [],
        table: [],
        payment: [],
        ai: [],
        graphic: [],
        tg: [],
        vk: [],
        app: [],
    },
    program: {
        custom: [],
        math: [],
        text: [],
        array: [],
        time: [],
        api: [],
        // app: [],
    }
}

export const platforms = dotenv.platforms
export const messengers = dotenv.messengers
export const contact_platforms = dotenv.contact_platforms
export const messanger_contact_types = dotenv.messanger_contact_types
export const contact_types = dotenv.contact_types

export const author_types = [
    'user',
    'chat',
    'author',
]

export const profile_types = [
    'user',
    'bot',
    'chat',
    'channel',
]


export const fieldLocaleRoot = 'effect.core.crm.field.common'
export const commonLocaleRoot = 'effect.common'

// const field_types = {
//     text: 'обычный текстовый однострочный инпут',
//     textarea: 'многострочное текстовое поле',
//     editor: 'редактор tinymce с различными параметрами, которые находятся в параметре features',
//     menu: 'сингл селект с поиском по значению',
//     tags: 'мульти селект - в виде поля тегов',
//     switcher: 'переключатель',
//     checkbox: 'галочка',
//     datetime: 'как текстовый инпут, но с выбором даты',
//     media: 'видео и фото в виде альбома',
//     photo: 'только фото',
//     video: 'только video',
//     file: 'список файлов в виде названий и иконок',
//     description: 'просто описание без полей',
// }

export const timer = [
    {
        type: 'effect/core/timer',
        icon: 'timer',
        moduleType: 'timer',
        fields: [
            [{
                name: 'seconds',
                value: null,
            }],
        ]
    },
]

export const condition_jump_effect = {
    type: 'effect/core/graph/condition/jump',
    icon: 'arrow-up-big-small',
    fields: [
        [
            {
                name: 'truePorts',
                type: 'tags',
                features: ['adding'],
            },
            {
                name: 'mode',
                type: 'menu',
                values: ['and', 'or'],
                default: 'and',
            },
            {
                name: 'conditions',
                type: 'conditions', // TODO: create it later
                values: [],
            },
        ],
    ]
}

export const graph_jump_effect = {
    type: 'effect/core/graph/jump/to',
    icon: 'arrow-up-big-small',
    fields: [
        [{
            name: 'localIds',
            type: 'tags',
            features: ['adding'],
        }],
        [{
            name: 'type',
            type: 'hidden',
            default: 'jump',
        }],
        [{
            name: 'ignore_next',
            type: 'checkbox',
            default: false,
        }],
    ]
}

export const popularEffectsTypes = {
    message_send: 'effect/core/chat/message/send',
    message_edit: 'effect/core/chat/message/edit',
    delay_run: 'effect/core/timer/delay/run',
}

export const conditionEffectTypes = [
    condition_jump_effect.type,
    'effect/core/var/condition/result',
]


export const effects = [
    // {
    //     type: 'effect/core/timer/',
    //     icon: 'message-check',
    //     fields: []
    // },
    {
        type: 'effect/core/chat/message/send', // effect.core.chat.message.send.title
        description: '',
        icon: 'message-check',
        context: true,
        fields: [
            [{
                type: 'ai',
                name: 'generate',
                label: 'Generate',
                related: 'text',
                btnType: 'text',
                description: '',
                className: 'btn-bordered padding-none-horizontal',
                prompt: 'Act as Telegram message text generator. Formatting mode: HTML. But do not wrap paragraphs by any tags! The app will be broken if u add <p> tag! Add two linebreaks (\n) between paragraphs. Newer wrap text in any quotes!',
                wrapperClassName: 'margin-bottom-xs',
            }],
            [{
                label: '',
                description: '',
                name: 'text',
                type: 'textarea', // editor, textarea
                className: 'margin-none',
                features: ['emoji', 'vars', 'len', 'html'], // lines
                max_length: 4000,
            }],
            [{
                name: 'options',
                type: 'button',
                forTextarea: true,
                default: false,
            }],
            [{
                type: 'description', // empty space (fix textarea margin)
                label: '',
                description: '',
                depends_on: {options: true, remove_keyboard: true}
            }],
            [{
                name: 'keyboard',
                type: 'keyboard',
                default: [],
                depends_on: {remove_keyboard: false, keyboard_as_json: false}
            }],
            [{
                name: 'json_keyboard',
                type: 'textarea',
                placeholder: '[[{"label": "String","type": "text"}]]',
                default: '[[{\n' +
                    '  "label": "String",\n' +
                    '  "type": "text",\n' +
                    '  "color": "primary",\n' +
                    '  "payload": "1"\n' +
                    '}]]',
                depends_on: {remove_keyboard: false, keyboard_as_json: true}
            }],
            // [{
            //     name: 'time',
            //     type: 'datetime',
            //     default: null,
            // }],
            [{
                name: 'remove_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {options: true, inline_keyboard: false}
            }],
            [{
                name: 'inline_keyboard',
                type: 'checkbox',
                description: '',
                default: true,
                depends_on: {remove_keyboard: false, options: true}
            }],
            [{
                name: 'permanent_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {remove_keyboard: false, inline_keyboard: false, options: true}
            }],
            [{
                name: 'keyboard_as_json',
                type: 'checkbox',
                description: '',
                depends_on: {options: true},
            }],
            [{
                name: 'allow_link_preview',
                type: 'checkbox',
                depends_on: {options: true}
            }],
            [{
                name: 'do_nothing_on_empty',
                default: true,
                type: 'checkbox',
                depends_on: {options: true}
            }],
            [{
                name: 'reply',
                type: 'checkbox',
                depends_on: {forward: false, options: true},
                width: 14,
            }, {
                label: '',
                description: '',
                name: 'reply_id',
                type: 'text',
                size: 'small',
                default: '{var:object.id}',
                depends_on: {reply: true, options: true},
                width: 10,
            }],
            [{
                name: 'pass_topic',
                platforms: ['tg'],
                type: 'checkbox',
                localeRoot: 'effect.common',
                depends_on: {options: true},
            }],
            [{
                name: 'topic_id',
                type: 'text',
                features: ['vars'],
                default: '{var:object.topic.id}',
                localeRoot: 'effect.common',
                depends_on: {pass_topic: true, options: true}
            }],
            [{
                name: 'forward',
                type: 'hidden',
                default: false,
            }],
            [{
                name: 'forward',
                type: 'checkbox',
                depends_on: {reply: false, options: true},

                platforms: ['vk'],
                width: 14,
            }, {
                label: '',
                description: '',
                name: 'forward_ids',
                type: 'tags',
                size: 'small',
                features: ['adding'],
                default: ['{var:object.id}'],
                depends_on: {forward: true, options: true},
                width: 10,
            }],
            [{
                description: '',
                placeholder: '',
                // tooltip: false,
                name: 'attach',
                type: 'checkbox',
                platforms: ['vk'],
                depends_on: {options: true},
            }],
            [{
                name: 'attachment',
                type: 'tags',
                features: ['adding'],
                depends_on: {attach: true},
            }],
            [{
                name: 'protect_content',
                default: false,
                type: 'checkbox',
                depends_on: {options: true},
                platforms: ['tg']
            }],
            [{
                name: 'disable_notification',
                default: false,
                type: 'checkbox',
                depends_on: {options: true},
                platforms: ['tg']
            }],
        ]
    },

    {
        type: 'effect/core/chat/message/edit',
        description: '',
        icon: 'message-pen',
        context: true,
        fields: [
            [{
                name: 'message_id',
                type: 'text',
                default: '{var:effect.message.id}',
            }],
            [{
                label: '',
                description: '',
                name: 'text',
                type: 'textarea',
                wrapperClassName: 'margin-none',
                features: ['emoji', 'vars', 'len', 'html'], // lines
                max_length: 4000,
            }],
            [{
                name: 'options',
                type: 'button',
                default: false,
            }],
            [{
                type: 'description', // empty space (fix textarea margin)
                label: '',
                description: '',
                depends_on: {options: true}
            }],
            [{
                name: 'keyboard',
                type: 'keyboard',
                default: [],
                depends_on: {keyboard_as_json: false}
            }],
            [{
                name: 'json_keyboard',
                type: 'textarea',
                placeholder: '[[{"label": "String","type": "text"}]]',
                default: '[[{\n' +
                    '  "label": "String",\n' +
                    '  "type": "text",\n' +
                    '  "color": "primary",\n' +
                    '  "payload": "1"\n' +
                    '}]]',
                depends_on: {keyboard_as_json: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'inline_keyboard',
                type: 'hidden',
                default: true,
                depends_on: {options: true}
            }],
            [{
                name: 'keyboard_as_json',
                type: 'checkbox',
                description: '',
                depends_on: {options: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'edit_caption',
                type: 'checkbox',
                platforms: ['tg'],
                default: false,
                depends_on: {options: true}
            }],
            [{
                name: 'allow_link_preview',
                type: 'checkbox',
                default: false,
                depends_on: {options: true}
            }],
            [{
                name: 'do_nothing_on_empty',
                default: true,
                type: 'checkbox',
                depends_on: {options: true}
            }],
            [{
                description: '',
                placeholder: '',
                // tooltip: false,
                name: 'attach',
                type: 'checkbox',
                platforms: ['vk'],
                depends_on: {options: true},
            }],
            [{
                name: 'attachment',
                type: 'tags',
                features: ['adding'],
                depends_on: {attach: true},
            }],
        ]
    },
    {
        type: 'effect/core/chat/message/reaction',
        description: '',
        icon: 'heart',
        context: true,
        fields: [
            [{
                name: 'message_id',
                type: 'text',
                default: '{var:object.number}',
                placeholder: '{var:object.number}',
            }],
            [{
                name: 'emoji',
                type: 'menu',
                features: ['vars'],
                values: [
                    "👍", "👎", "❤️", "🔥", "🙏", "👌", "😍", "🤣", "🤔", "😁", "😘", "🎉", "😭", "💩", "😡", "⁉️", // vk
                    "🥰", "👏", "🤯", "😱", "🤬", "😢", "🤩", "🤮", "🕊", "🤡", "🥱", "🥴", "🐳", "❤️‍🔥", "🌚", "🌭", "💯", "⚡️", "🍌", "🏆", "💔", "🤨", "😐", "🍓", "🍾", "💋", "🖕", "😈", "😴", "🤓", "👻", "👨‍💻", "👀", "🎃", "🙈", "😇", "😨", "🤝", "✍️", "🤗", "🫡", "🎅", "🎄", "☃️", "💅", "🤪", "🗿", "🆒", "💘", "🙉", "🦄", "💊", "🙊", "😎", "👾", "🤷‍♂️", "🤷", "🤷‍♀️",
                ],
                default: "❤",
                localized: false,
                depends_on: {set_by_var: false}
            }],
            [{
                name: 'emoji',
                type: 'textarea',
                placeholder: '{var:emoji}',
                features: ['vars'],
                depends_on: {set_by_var: true}
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'animate',
                type: 'checkbox',
                platforms: ['tg'],
            }],
        ]
    },

    {
        type: 'effect/core/chat/photo/send', // effect.core.chat.photo.send
        description: '',
        icon: 'image',
        context: true,
        fields: [
            [{
                name: 'message_id',
                type: 'unset',
                default: '',
            }],
            [{
                label: '',
                description: '',
                name: 'code',
                type: 'photo',
                limit: 1,
            }],
            [{
                name: 'add_url',
                type: 'checkbox',
            }],
            [{
                name: 'url',
                type: 'textarea',
                features: ['vars'],
                depends_on: {add_url: true}
            }],
            [{
                name: 'add_caption',
                type: 'checkbox',
            }],
            [{
                name: 'caption',
                type: 'textarea',
                description: '',
                features: ['emoji', 'vars', 'html'],
                wrapperClassName: 'fix-counter',
                max_length: 1000,
                depends_on: {add_caption: true}
            }],
            [{
                name: 'reply',
                type: 'checkbox',
                width: 14,
            }, {
                label: '',
                description: '',
                name: 'reply_id',
                type: 'text',
                size: 'small',
                default: '{var:object.id}',
                depends_on: {reply: true},
                width: 10,
            }],
            [{
                name: 'protect_content',
                type: 'checkbox',
                platforms: ['tg'],
            }],
            [{
                name: 'disable_notification',
                type: 'checkbox',
                platforms: ['tg'],
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'pass_topic',
                platforms: ['tg'],
                type: 'checkbox',
                localeRoot: 'effect.common',
            }],
            [{
                name: 'topic_id',
                type: 'text',
                features: ['vars'],
                default: '{var:object.topic.id}',
                localeRoot: 'effect.common',
                depends_on: {pass_topic: true}
            }],
            [{
                name: 'allow_link_preview',
                type: 'checkbox',
                depends_on: {add_caption: true}
            }],
            [{
                name: 'add_keyboard',
                type: 'checkbox',
                description: '',
            }],
            [{
                name: 'keyboard_as_json',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'keyboard',
                type: 'keyboard',
                default: [],
                depends_on: {add_keyboard: true, remove_keyboard: false, keyboard_as_json: false}
            }],
            [{
                name: 'json_keyboard',
                type: 'textarea',
                placeholder: '[[{"label": "String","type": "text"}]]',
                default: '[[{"label": "String","type": "text"}]]',
                depends_on: {add_keyboard: true, remove_keyboard: false, keyboard_as_json: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'remove_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true, inline_keyboard: false}
            }],
            [{
                name: 'inline_keyboard',
                type: 'checkbox',
                description: '',
                default: true,
                depends_on: {add_keyboard: true, remove_keyboard: false}
            }],
            [{
                name: 'permanent_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true, remove_keyboard: false, inline_keyboard: false}
            }],
        ]
    },

    {
        type: 'effect/core/chat/photo/edit', // effect.core.chat.message.send.title
        inherit: 'effect/core/chat/photo/send',
        icon: 'camera-rotate',
        context: true,
        replace: {
            message_id: {
                name: 'message_id',
                type: 'text',
                default: '{var:effect.message.id}',
            },
            inline_keyboard: {
                name: 'inline_keyboard',
                type: 'hidden',
                default: true
            },
            remove_keyboard: {
                name: 'remove_keyboard',
                type: 'hidden',
                default: false
            },
            add_keyboard: {
                name: 'add_keyboard',
                type: 'checkbox',
                default: false,  // for rename reasons
            },
            permanent_keyboard: {
                name: 'permanent_keyboard',
                type: 'unset'
            },
            reply: {
                name: 'reply',
                type: 'unset'
            },
            reply_id: {
                name: 'reply_id',
                type: 'unset'
            },
            protect_content: {
                name: 'protect_content',
                type: 'unset'
            },
            disable_notification: {
                name: 'disable_notification',
                type: 'unset'
            },
        }
    },
    {
        type: 'effect/core/chat/attachment/send',
        icon: 'paperclip',
        fields: [
            [{
                name: 'type',
                type: 'menu',
                placeholder: '',
                values: [
                    'photo',
                    'audio',
                    'video',
                    'videoNote',
                    'document',
                    'voice',
                    'animation',
                    'sticker',
                    'post',
                ],
                default: 'photo',
            }],
            [{
                name: 'code',
                type: 'text',
                features: ['vars'],
                default: '{var:object.attachments[0].code}',
                // description_pieces: [<Clipboard text="{var:object}" label="{var:object}" />]
            }],
            [{
                name: 'add_caption',
                // platforms: ['vk', 'tg'],
                type: 'checkbox',
            }],
            [{
                name: 'caption',
                type: 'textarea',
                description: '',
                features: ['emoji', 'vars', 'html'],
                wrapperClassName: 'fix-counter',
                max_length: 1000,
                depends_on: {add_caption: true}
            }],
            [{
                description: '',
                name: 'reply',
                type: 'checkbox',
                width: 14,
            }, {
                label: '',
                description: '',
                name: 'reply_id',
                type: 'text',
                default: '{var:object.id}',
                placeholder: '{var:object.id}',
                depends_on: {reply: true},
                width: 10,
            }],
            [{
                name: 'protect_content',
                platforms: ['tg'],
                type: 'checkbox',
            }],
            [{
                name: 'disable_notification',
                type: 'checkbox',
                platforms: ['tg'],
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'pass_topic',
                platforms: ['tg'],
                type: 'checkbox',
                localeRoot: 'effect.common',
            }],
            [{
                name: 'topic_id',
                type: 'text',
                features: ['vars'],
                default: '{var:object.topic.id}',
                localeRoot: 'effect.common',
                depends_on: {pass_topic: true}
            }],
            [{
                name: 'allow_link_preview',
                type: 'checkbox',
                depends_on: {add_caption: true}
            }],

            [{
                name: 'add_keyboard',
                type: 'checkbox',
                description: '',
                // platforms: ['vk', 'tg'],
                localeRoot: 'effect.core.chat.photo.send',
            }],
            [{
                name: 'keyboard_as_json',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'keyboard',
                type: 'keyboard',
                default: [],
                depends_on: {add_keyboard: true, remove_keyboard: false, keyboard_as_json: false},
            }],
            [{
                name: 'json_keyboard',
                type: 'textarea',
                placeholder: '[[{"label": "String","type": "text"}]]',
                default: '[[{"label": "String","type": "text"}]]',
                depends_on: {add_keyboard: true, remove_keyboard: false, keyboard_as_json: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'remove_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true, inline_keyboard: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'inline_keyboard',
                type: 'checkbox',
                description: '',
                default: true,
                depends_on: {add_keyboard: true, remove_keyboard: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'permanent_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true, remove_keyboard: false, inline_keyboard: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
        ]
    },
    {
        type: 'effect/integration/tg/attachment/send',
        icon: 'paperclip',
        inherit: 'effect/core/chat/attachment/send',
    },

    {
        type: 'effect/integration/ai/message/completion',
        icon: 'microchip-ai',
        fields: [
            [{
                name: 'platform',
                type: 'text',
                placeholder: '{var:platform_name}',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'platform',
                type: 'menu',
                placeholder: '',
                default: 'openai',
                values: [
                    'openai',
                    'anthropic',
                    'mistral',
                    'gemini',
                    // 'meta',
                ],
                depends_on: {set_by_var: false},
            }],
            // [{
            //     name: 'use_default',
            //     type: 'checkbox',
            //     default: false,
            //     localeRoot: 'effect.integration.ai.openai.message',
            // }],
            [
                {
                    name: 'integration_var',
                    type: 'text',
                    placeholder: '{var:integration_id}',
                    depends_on: {set_by_var: true},
                    localeRoot: commonLocaleRoot,
                    width: 12,
                },
                {
                    name: 'model',
                    type: 'text',
                    depends_on: {set_by_var: true},
                    placeholder: '{var:model_name}',
                    width: 12,
                },
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'openai'},
                    depends_on: {platform: 'openai', set_by_var: false},
                    width: 12,
                },
                {
                    name: 'model',
                    type: 'menu',
                    depends_on: {platform: 'openai', set_by_var: false},
                    values: [
                        'gpt-4o',
                        'gpt-4o-mini',
                        'gpt-4-turbo',
                        'gpt-3.5-turbo',
                    ],
                    localized: false,
                    width: 12,
                },
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'mistral'},
                    depends_on: {platform: 'mistral', set_by_var: false},
                    // localeRoot: commonLocaleRoot,
                    width: 12,
                },
                {
                    name: 'model',
                    type: 'menu',
                    depends_on: {platform: 'mistral', set_by_var: false},
                    values: [
                        'mistral-large-latest',
                        'mistral-small-latest',
                        'open-mistral-7b',
                    ],
                    localized: false,
                    width: 12,
                },
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'anthropic'},
                    depends_on: {platform: 'anthropic', set_by_var: false},
                    // localeRoot: commonLocaleRoot,
                    width: 12,
                },
                {
                    name: 'model',
                    type: 'menu',
                    depends_on: {platform: 'anthropic', set_by_var: false},
                    values: [
                        'claude-3-opus-20240229',
                        'claude-3-sonnet-20240229',
                        'claude-3-haiku-20240307',
                    ],
                    localized: false,
                    width: 12,
                },
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'gemini'},
                    depends_on: {platform: 'gemini', set_by_var: false},
                    width: 12,
                },
                {
                    name: 'model',
                    type: 'menu',
                    depends_on: {platform: 'gemini', set_by_var: false},
                    values: [
                        'gemini-1.5-pro-latest',
                        // 'gemini-1.0-pro',
                    ],
                    localized: false,
                    width: 12,
                },
            ],

            [{
                name: 'prompt',
                type: 'textarea',
                features: ['vars'],
                default: 'Try to write messages with size not more than 3000 unicode symbols.',
            }],
            [{
                name: 'message',
                type: 'textarea',
                features: ['vars'],
                default: '{var:object.text}',
                localeRoot: 'effect.integration.ai.openai.message',
            }],
            [{
                name: 'add_messages',
                type: 'checkbox',
                default: false,
                localeRoot: 'effect.integration.ai.openai.message',
            }],
            [{
                name: 'messages',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.integration.ai.openai.message',
                depends_on: {add_messages: true}
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
                tooltip: '',
                default: false,
                localeRoot: 'effect.integration.ai.openai.message',
            }],
            [
                {
                    name: 'max_tokens',
                    type: 'text',
                    placeholder: '0',
                    localeRoot: 'effect.integration.ai.openai.message',
                    depends_on: {add_params: true},
                    width: 12,
                },
                {
                    name: 'temperature',
                    type: 'text',
                    placeholder: '0',
                    localeRoot: 'effect.integration.ai.openai.message',
                    depends_on: {add_params: true},
                    width: 12,
                },
            ],
            [{
                name: 'params',
                type: 'params',
                description: '',
                localeRoot: 'graph.container',
                depends_on: {add_params: true},
            }],

            [{
                name: 'json_mode',
                type: 'checkbox',
                depends_on: {add_params: true, platform: ['openai', 'mistral']},
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'store_message',
                type: 'checkbox',
            }],
            [{
                name: 'history_var',
                label: '',
                type: 'text',
                placeholder: 'history',
                depends_on: {store_message: true}
            }],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                localeRoot: 'effect.integration.ai.openai.message',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'message',
                depends_on: {save_result: true}
            }],
        ],
    },
    {
        type: 'effect/integration/ai/openai/message',
        icon: 'message-bot',
        fields: [
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'openai'},
                depends_on: {integration_by_var: false},
            }],
            [{
                name: 'integration_var',
                type: 'text',
                default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {integration_by_var: true},
            }],
            [{
                name: 'text',
                type: 'textarea',
                features: ['vars'],
                default: 'Try to write messages with size not more than 3000 unicode symbols.',
            }],
            [{
                name: 'message',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
                tooltip: '',
                default: false,
            }],
            [{
                name: 'model',
                type: 'text',
                features: ['vars'],
                placeholder: 'gpt-4o-mini',
                depends_on: {add_params: true, integration_by_var: true},
            }],
            [{
                name: 'model',
                type: 'menu',
                values: [
                    'gpt-4o',
                    'gpt-4o-mini',
                    'gpt-4-turbo',
                ],
                default: 'gpt-4o-mini',
                placeholder: '',
                localized: false,
                depends_on: {add_params: true, integration_by_var: false},
            }],
            [
                {
                    name: 'max_tokens',
                    type: 'text',
                    placeholder: '0',
                    depends_on: {add_params: true},
                    width: 12,
                },
                {
                    name: 'temperature',
                    type: 'text',
                    placeholder: '0',
                    depends_on: {add_params: true},
                    width: 12,
                },
            ],
            [{
                name: 'messages',
                type: 'textarea',
                features: ['vars'],
                depends_on: {add_params: true}
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true}
            }],
        ]
    },

    {
        type: 'effect/integration/ai/openai/image',
        icon: 'paint-brush-alt',
        fields: [
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'openai'},
                localeRoot: 'effect.integration.ai.message.completion',
                depends_on: {integration_by_var: false},
            }],
            [{
                name: 'integration_var',
                type: 'text',
                default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {integration_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'model',
                type: 'menu',
                values: [
                    'dall-e-3',
                    'dall-e-2',
                ],
                default: 'dall-e-3',
                placeholder: '',
                localized: true,
            }],
            [{
                name: 'prompt',
                type: 'textarea',
                features: ['vars'],
                default: '{var:object.text}',
            }],
            [
                {
                    name: 'aspect',
                    type: 'menu',
                    values: [
                        '1024x1024',
                        '1792x1024',
                        '1024x1792',
                    ],
                    default: '1024x1024',
                    placeholder: '',
                    depends_on: {model: 'dall-e-3'},
                    width: 12,
                },
                {
                    name: 'size',
                    type: 'menu',
                    values: [
                        '256x256',
                        '512x512',
                        '1024x1024',
                    ],
                    default: '1024x1024',
                    placeholder: '',
                    depends_on: {model: 'dall-e-2'},
                    width: 12,
                },
                {
                    name: 'style',
                    type: 'menu',
                    values: [
                        'natural',
                        'vivid',
                    ],
                    default: 'natural',
                    placeholder: '',
                    depends_on: {model: 'dall-e-3'},
                    width: 12,
                },
                {
                    name: 'n',
                    type: 'menu',
                    placeholder: '',
                    values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    depends_on: {model: 'dall-e-2', integration_by_var: false},
                    localized: false,
                    width: 12,
                },
                {
                    name: 'n',
                    type: 'text',
                    placeholder: '1',
                    depends_on: {model: 'dall-e-2', integration_by_var: true},
                    width: 12,
                },
            ],
            [{
                name: 'hd',
                type: 'checkbox',
                depends_on: {model: 'dall-e-3'},
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],

            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                // label: '',
                placeholder: 'res',
                depends_on: {save_result: true},
                // localeRoot: commonLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/integration/ai/openai/chat',
        icon: 'message-text',
        fields: [
            [{
                name: 'text',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.integration.ai.openai.message',
                default: 'Try to write messages with size not more than 3000 unicode symbols.',
            }],
            [{
                name: 'message',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.integration.ai.openai.message',
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
                tooltip: '',
                default: false,
                localeRoot: 'effect.integration.ai.openai.message',
            }],
            [
                {
                    name: 'max_tokens',
                    type: 'text',
                    placeholder: '0',
                    localeRoot: 'effect.integration.ai.openai.message',
                    depends_on: {add_params: true},
                    width: 12,
                },
                {
                    name: 'temperature',
                    type: 'text',
                    placeholder: '0',
                    localeRoot: 'effect.integration.ai.openai.message',
                    depends_on: {add_params: true},
                    width: 12,
                },
            ],
            [{
                name: 'messages',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.integration.ai.openai.message',
                depends_on: {add_params: true}
            }],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                localeRoot: 'effect.integration.ai.openai.message',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                localeRoot: 'effect.integration.ai.openai.message',
                depends_on: {save_result: true}
            }],
        ],
    },
    {
        type: 'effect/integration/ai/filebrain/message',
        icon: 'folder-gear',
        fields: [
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'filebrain'},
                depends_on: {integration_by_var: [false, null]},
            }],
            [{
                name: 'integration_var',
                type: 'text',
                default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {integration_by_var: true},
            }],
            [{
                name: 'query',
                type: 'textarea',
                features: ['vars'],
                default: '{var:object.text}',
            }],
            [{
                name: 'session_id',
                type: 'text',
                features: ['vars'],
                placeholder: '{var:temp}'
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/integration/ai/chat/add',
        icon: 'message-plus',
        fields: [
            [{
                name: 'result_var',
                type: 'text',
                default: 'history',
                placeholder: 'history',
            }],
            [{
                name: 'text',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
                default: '{var:object.text}',
            }],
            [{
                name: 'role',
                type: 'menu',
                values: [
                    'user',
                    'assistant',
                    'system',
                ],
                default: 'user',
                localized: true,
            }],
            [{
                name: 'limit',
                type: 'text',
                placeholder: '0',
                default: '100',
            }],
        ],
    },

    {
        type: 'effect/integration/graphic/gramotadel/create',
        icon: 'file-certificate',
        fields: [
            [{
                name: 'integration_id',
                type: 'text',
                // default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {integration_by_var: true},
                localeRoot: commonLocaleRoot + '.synonym',
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'gramotadel'},
                depends_on: {integration_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'doc_id',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'params',
                type: 'fields',
                features: ['vars'],
                section: 'vars',
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'url',
                depends_on: {save_result: true}
            }],
        ]
    },

    // payments
    {
        type: 'effect/integration/payment/yoomoney/link',
        icon: 'credit-card',
        fields: [
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'yoomoney'},
                depends_on: {integration_by_var: [false, null]},
            }],
            [{
                name: 'integration_var',
                type: 'text',
                default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {integration_by_var: true},
            }],
            [
                {
                    name: 'order_id',
                    type: 'text',
                    default: '{var:effect.order.id}',
                    features: ['vars'],
                },
                {
                    name: 'price',
                    placeholder: true,
                    default: '{var:effect.order.price}',
                    type: 'text',
                    features: ['vars'],
                },
            ],
            [{
                name: 'title',
                placeholder: true,
                type: 'hidden',
                default: '{var:effect.order.title}',
                features: ['vars'],
            }],
            [{
                name: 'success_url',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                default: 'payment_url',
                placeholder: 'payment_url',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/integration/payment/prodamus/link',
        icon: 'file-invoice-dollar',
        fields: [
            [
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'prodamus'},
                    depends_on: {integration_by_var: false},
                },
            ],
            [
                {
                    name: 'integration_var',
                    type: 'text',
                    default: '{var:graph.integration.id}',
                    placeholder: '{var:graph.integration.id}',
                    depends_on: {integration_by_var: true},
                },
            ],
            [
                {
                    name: 'order_id',
                    type: 'text',
                    default: '{var:effect.order.id}',
                    features: ['vars'],
                },
                {
                    name: 'urlSuccess',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                },
            ],
            [
                {
                    name: 'order_sum',
                    type: 'text',
                    default: '{var:effect.order.price}',
                    features: ['vars'],
                },
                {
                    name: 'discount_value',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                },
            ],
            [{
                name: 'customer_extra',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                default: '{var:effect.order.title}',
            }],
            [{
                name: 'paid_content',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                description: '',
                name: 'products',
                type: 'products',
                data: 'product',
                depends_on: {products_by_var: false},
            }],
            [{
                name: 'products_by_var',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'products_var',
                type: 'textarea',
                default: '{var:effect.order.products}',
                depends_on: {products_by_var: true},
            }],
            [{
                name: 'pass_contacts',
                type: 'checkbox',
            }],
            [
                {
                    name: 'customer_phone',
                    type: 'text',
                    features: ['vars'],
                    default: '{contact:phone}',
                    depends_on: {pass_contacts: true}
                },
                {
                    name: 'customer_email',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    default: '{contact:email}',
                    depends_on: {pass_contacts: true}
                },
            ],
            [
                {
                    name: 'vk_user_id',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    default: '{contact:vk_id}',
                    depends_on: {pass_contacts: true}
                },
                {
                    name: 'tg_user_id',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    default: '{contact:tg_id}',
                    depends_on: {pass_contacts: true}
                },
            ],
            [{
                name: 'set_link_expired',
                type: 'checkbox',
            }],
            [
                {
                    label: '',
                    // description: '',
                    name: 'link_expired_value',
                    type: 'text',
                    features: ['vars'],
                    default: '7',
                    depends_on: {set_link_expired: true},
                },
                {
                    label: '',
                    // description: '',
                    placeholder: '',
                    name: 'link_expired_mode',
                    type: 'menu',
                    values: ['minutes', 'hours', 'days'],
                    default: 'days',
                    localized: true,
                    depends_on: {set_link_expired: true},
                },
            ],
            [{
                name: 'is_subscription',
                type: 'checkbox',
            }],
            [
                {
                    name: 'subscription',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    depends_on: {is_subscription: true}
                },
                {
                    name: 'subscription_date_start',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    depends_on: {is_subscription: true}
                },
            ],
            [
                {
                    name: 'subscription_demo_period',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    depends_on: {is_subscription: true}
                },
                {
                    name: 'subscription_limit_autopayments',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    depends_on: {is_subscription: true}
                },
            ],
            [{
                name: 'add_fields',
                type: 'checkbox',
            }],
            [{
                description: '',
                name: 'fields',
                type: 'params',
                localePath: 'graph.container.form.fields',
                depends_on: {add_fields: true}
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
            }],
            [{
                description: '',
                name: 'params',
                type: 'params',
                localeRoot: 'graph.container',
                depends_on: {add_params: true}
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                default: 'payment_url',
                placeholder: 'payment_url',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/integration/payment/prodamus/subscription/status',
        icon: 'money-check-dollar-pen',
        fields: [
            [
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'prodamus'},
                    localeRoot: 'effect.integration.payment.prodamus.link',
                    depends_on: {integration_by_var: [false, null]},
                },
            ],
            [
                {
                    name: 'integration_var',
                    type: 'text',
                    default: '{var:integration_id}',
                    placeholder: '{var:integration_id}',
                    localeRoot: 'effect.integration.payment.prodamus.link',
                    depends_on: {integration_by_var: true},
                },
            ],
            [{
                name: 'subscription',
                type: 'text',
                placeholder: true,
                localeRoot: 'effect.integration.payment.prodamus.link',
                features: ['vars'],
            }],
            [
                {
                    name: 'contact_type',
                    type: 'menu',
                    values: [
                        'platform',
                        'vk_id',
                        'tg_id',
                        'phone',
                        'email',
                        'payform',
                    ],
                    placeholder: '',
                    default: 'platform',
                },
                {
                    name: 'contact_value',
                    type: 'text',
                    features: ['vars'],
                    placeholder: '{user:platform.id}',
                    default: '{user:platform.id}',
                },
            ],
            [
                {
                    name: 'initiator',
                    type: 'menu',
                    values: [
                        'user',
                        'manager',
                    ],
                    placeholder: '',
                    description: '',
                    default: 'manager',
                    wrapperClassName: 'margin-none',
                },
                {
                    name: 'status',
                    type: 'menu',
                    values: [
                        'enabled',
                        'disabled',
                    ],
                    placeholder: '',
                    description: '',
                    default: 'disabled',
                    wrapperClassName: 'margin-none',
                },
            ],
            [{
                name: 'desc',
                type: 'description',
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
                localeRoot: 'effect.integration.payment.prodamus.link',
            }],
            [{
                description: '',
                name: 'params',
                type: 'params',
                localeRoot: 'graph.container',
                depends_on: {add_params: true}
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                localeRoot: 'effect.integration.payment.prodamus.link',
                default: false,
            }],
        ]
    },
    {
        type: 'effect/integration/payment/prodamus/subscription/discount',
        inherit: 'effect/integration/payment/prodamus/subscription/status',
        icon: 'money-check',
        replace: {
            initiator: {
                name: 'discount',
                type: 'text',
                features: ['vars'],
                placeholder: '0',
            },
            status: {
                name: 'count',
                type: 'text',
                features: ['vars'],
                placeholder: '0',
            },
            desc: {
                name: 'desc',
                type: 'unset',
            },
        }
    },
    {
        type: 'effect/integration/payment/prodamus/subscription/shift',
        inherit: 'effect/integration/payment/prodamus/subscription/status',
        icon: 'calendar-circle-exclamation',
        replace: {
            initiator: {
                name: 'value',
                type: 'text',
                features: ['vars'],
                placeholder: '0',
                default: '{date:{var:timestamp}|%F %H:%M}',
            },
            status: {
                name: 'status',
                type: 'unset',
            },
            desc: {
                name: 'desc',
                type: 'unset',
            },
        }
    },


    {
        type: 'effect/core/chat/action/run',
        icon: 'message-dots',
        fields: [
            [
                {
                    name: 'operation',
                    type: 'menu',
                    placeholder: '',
                    default: 'typing',
                    values: [
                        'typing',
                        'choose_sticker',
                        'upload_photo',
                        'record_video',
                        'upload_video',
                        'record_video_note',
                        'upload_video_note',
                        'upload_document',
                        'record_voice',
                        'upload_voice',
                        'find_location'
                    ]
                }
            ],

            [{
                name: 'pass_topic',
                platforms: ['tg'],
                type: 'checkbox',
                // localeRoot: 'effect.common',
            }],
            [{
                name: 'topic_id',
                type: 'text',
                features: ['vars'],
                default: '{var:object.topic.id}',
                localeRoot: 'effect.common',
                depends_on: {pass_topic: true}
            }],
        ]
    },
    {
        type: 'effect/core/chat/join/action',
        icon: 'house-person-return',
        fields: [
            [
                {
                    label: '',
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'accept',
                        'decline'
                    ],
                    default: 'accept'
                }
            ],
        ]
    },
    {
        type: 'effect/core/chat/member/remove',
        icon: 'person-from-portal',
        context: true,
        fields: []
    },
    {
        type: 'effect/core/chat/member/block',
        icon: 'user-lock',
        context: true,
        fields: [
            [
                {
                    name: 'revoke_messages',
                    type: 'checkbox',
                },
            ]
        ]
    },
    {
        type: 'effect/core/chat/member/unblock',
        icon: 'user-unlock',
        context: true,
    },

    {
        type: 'effect/core/chat/member/leave',
        icon: 'sign-out',
        context: true,
        fields: []
    },
    {
        type: 'effect/core/chat/message/delete',
        icon: 'message-slash',
        fields: [
            [
                {
                    name: 'object_id',
                    type: 'text',
                    features: ['vars'],
                    default: '{var:effect.message.id}',
                    placeholder: '{var:object.id}',
                }
            ],
        ]
    },
    {
        type: 'effect/core/chat/file/download',
        icon: 'file-plus',
        fields: [
            [{
                name: 'code',
                type: 'textarea',
                features: ['vars'],
                placeholder: '{var:code}',
                default: '{var:object.attachments[0].code}',
                // max_length: 1000,
            }],
            [{
                name: 'set_lifetime',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'lifetime',
                type: 'textarea',
                default: '10',
                features: ['vars'],
                depends_on: {set_lifetime: true},
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
            }],
        ]
    },
    {
        type: 'effect/core/chat/poll/send',
        icon: 'square-poll-horizontal',
        context: true,
        fields: [
            [{
                description: '',
                name: 'question',
                type: 'textarea',
                className: 'margin-none',
                features: ['emoji', 'vars', 'len', 'html'], // lines
                max_length: 300,
            }],
            [{
                type: 'description', // empty space (fix textarea margin)
                label: '',
                description: '',
            }],
            [{
                name: 'options',
                type: 'tags',
                features: ['adding'],
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'options_var',
                type: 'textarea',
                placeholder: '{var:options}',
                features: ['vars'],
                depends_on: {set_by_var: true},
            }],

            [
                {
                    name: 'type',
                    type: 'menu',
                    placeholder: '',
                    values: ['regular', 'quiz'],
                    default: 'quiz',
                    localized: true,
                },
                {
                    name: 'correct_option_id',
                    type: 'text',
                    depends_on: {type: 'quiz'},
                    localized: true,
                }
            ],
            [{
                // description: '',
                name: 'explanation',
                type: 'textarea', // editor, textarea
                className: 'margin-none',
                features: ['emoji', 'vars', 'html'], // lines
                depends_on: {type: 'quiz'},
                // max_length: 200,
            }],
            // [{
            //     name: 'explanation_desc',
            //     type: 'description', // empty space (fix textarea margin)
            //     depends_on: {type: 'quiz'},
            //     label: '',
            // }],

            [
                {
                    name: 'close_type',
                    type: 'menu',
                    placeholder: '',
                    values: ['unset', 'period', 'timestamp', 'closed'],
                    default: 'period',
                    localized: true,
                    width: 12,
                },
                {
                    name: 'open_period',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {close_type: 'period'},
                    width: 12,
                },
                {
                    name: 'close_date',
                    type: 'textarea',
                    features: ['vars'],
                    depends_on: {close_type: 'timestamp'},
                    width: 12,
                }
            ],

            [{
                name: 'allows_multiple_answers',
                type: 'checkbox',
                depends_on: {type: 'regular'},
            }],
            [{
                name: 'is_anonymous',
                default: false,
                type: 'checkbox',
                depends_on: {type: 'regular'},
            }],
            // [{
            //     name: 'is_closed',
            //     default: true,
            //     type: 'checkbox',
            // }],

            [{
                name: 'keyboard',
                type: 'keyboard',
                default: [],
                depends_on: {add_keyboard: true, remove_keyboard: false, keyboard_as_json: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'json_keyboard',
                type: 'textarea',
                placeholder: '[[{"label": "String","type": "text"}]]',
                default: '[[{"label": "String","type": "text"}]]',
                depends_on: {add_keyboard: true, remove_keyboard: false, keyboard_as_json: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'remove_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true, inline_keyboard: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'inline_keyboard',
                type: 'checkbox',
                description: '',
                default: true,
                depends_on: {add_keyboard: true, remove_keyboard: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'permanent_keyboard',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true, remove_keyboard: false, inline_keyboard: false},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'keyboard_as_json',
                type: 'checkbox',
                description: '',
                depends_on: {add_keyboard: true},
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'add_keyboard',
                type: 'checkbox',
                description: '',
            }],
            [{
                name: 'do_nothing_on_empty',
                default: true,
                type: 'checkbox',
                localeRoot: 'effect.core.chat.message.send',
            }],
            [{
                name: 'set_by_var',
                default: false,
                type: 'checkbox',
            }],
            [{
                name: 'reply',
                type: 'checkbox',
                depends_on: {forward: false},
                localeRoot: 'effect.core.chat.message.send',
                width: 14,
            }, {
                label: '',
                description: '',
                name: 'reply_id',
                type: 'text',
                size: 'small',
                default: '{var:object.id}',
                depends_on: {reply: true},
                localeRoot: 'effect.core.chat.message.send',
                width: 10,
            }],
            [{
                name: 'protect_content',
                default: false,
                type: 'checkbox',
                localeRoot: 'effect.core.chat.message.send',
                // platforms: ['tg']
            }],
        ]
    },
    {
        type: 'effect/core/chat/dialog/note',
        icon: 'clipboard-list-check',
        fields: [
            [{
                placeholder: '',
                name: 'message_type',
                type: 'menu',
                values: [
                    'system',
                    'message',
                    'note',
                ],
                default: 'system',
            }],
            [{
                name: 'message',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            }],
        ]
    },

    {
        type: 'effect/core/crm/list/add',
        icon: 'user-plus',
        fields: [
            [{
                label: null, //   effect.core.crm.list.add.code.label
                description: null, //  effect.core.crm.list.add.code.desc
                name: 'code',
                type: 'menu', // select + creating
                features: ['creating'],
                // features: ['vars'], // do not show in tags
                data: 'field',
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'add_again',
                type: 'switcher',
            }],
            // [
            //     {
            //         description: '',
            //         name: 'run_event',
            //         type: 'switcher',
            //     },
            // ],
            [{
                name: 'set_code_by_var',
                type: 'switcher',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/list/remove',
        icon: 'user-slash',
        fields: [
            [{
                label: null, //   effect.core.user.list.remove.name.title
                description: null, //   effect.core.user.list.remove.name.desc
                name: 'code',
                type: 'tags', // select + creating
                // features: ['creating'],
                // features: ['vars'], // do not show in tags
                data: 'field',
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_codes_by_var: false},
            }],
            [{
                name: 'codes_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_codes_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'set_codes_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/user/update',
        icon: 'user',
        fields: [
            [
                {
                    // description: '',
                    placeholder: true,
                    name: 'first_name',
                    type: 'text',
                },
                {
                    // description: '',
                    placeholder: true,
                    name: 'last_name',
                    type: 'text',
                },
            ],
            [
                {
                    // description: '',
                    placeholder: true,
                    name: 'photo_url',
                    type: 'text',
                },
                {
                    // description: '',
                    placeholder: '',
                    name: 'ignore',
                    type: 'menu',
                    values: [0, 1],
                    default: 0,
                },
            ],
            [{
                name: 'more',
                type: 'checkbox',
            }],
            [
                {
                    placeholder: true,
                    name: 'appeal',
                    type: 'text',
                    depends_on: {more: true},
                },
                {
                    name: 'title',
                    type: 'text',
                    depends_on: {more: true},
                },
            ],
            [
                {
                    name: 'manager_user_id',
                    type: 'text',
                    depends_on: {more: true},
                },
                {
                    name: 'source_id',
                    type: 'text',
                    placeholder: '{utm:}',
                    depends_on: {more: true},
                },
            ],
        ]
    },
    {
        type: 'effect/core/crm/user/platform',
        icon: 'portrait',
        fields: [
            [{
                name: 'update_user',
                type: 'switcher',
                default: true,
            }]
        ]
    },
    {
        type: 'effect/core/crm/avatar/update',
        icon: 'circle-user',
        // system: true,
    },
    {
        type: 'effect/core/crm/contact/add',
        icon: 'address-card',
        fields: [
            [
                {
                    description: '',
                    placeholder: '',
                    name: 'contact_type',
                    type: 'menu',
                    values: contact_types,
                    default: 'phone',
                    depends_on: {set_by_var: false},
                    width: 12,
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'contact_type',
                    type: 'textarea',
                    depends_on: {set_by_var: true},
                    width: 12,
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'value',
                    type: 'textarea',
                    width: 12,
                },
            ],
            [{
                name: 'public_name',
                type: 'text',
                depends_on: {contact_type: messanger_contact_types}
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
            }],
            [{
                name: 'add_integration',
                type: 'checkbox',
                depends_on: {contact_type: messanger_contact_types}
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': messengers},
                depends_on: {add_integration: true, contact_type: messanger_contact_types, set_by_var: false}
            }],
            [{
                name: 'integration_id',
                type: 'textarea',
                placeholder: '{var:graph.integration.id}',
                depends_on: {add_integration: true, contact_type: messanger_contact_types, set_by_var: true},
            }]
        ]
    },
    {
        type: 'effect/core/crm/user/swap',
        icon: 'right-left',
        fields: [
            [
                {
                    description: '',
                    placeholder: '',
                    name: 'left',
                    type: 'menu',
                    values: author_types,
                    default: 'user',
                },
                {
                    description: '',
                    placeholder: '',
                    name: 'right',
                    type: 'menu',
                    values: author_types,
                    default: 'author',
                },
            ],
        ]
    },
    {
        type: 'effect/core/crm/user/unite',
        icon: 'user-group',
        fields: [
            [
                {
                    name: 'left',
                    type: 'textarea',
                    description: '',
                    placeholder: '{user:id}',
                    default: '{user:id}',
                    wrapperClassName: 'margin-none',
                },
                {
                    name: 'right',
                    type: 'textarea',
                    description: '',
                    placeholder: '{var:result.id}',
                    default: '{var:result.id}',
                    wrapperClassName: 'margin-none',
                },
            ],
            [{
                name: 'desc',
                type: 'description',
            }],
            [{
                name: 'delete_right',
                type: 'checkbox',
            }]
        ]
    },
    {
        type: 'effect/core/crm/user/password',
        icon: 'key',
        fields: [
            [{
                name: 'password',
                type: 'text',
            }],
        ]
    },
    {
        type: 'effect/core/crm/contact/status',
        icon: 'person-sign',
        fields: [
            [{
                description: '',
                placeholder: '',
                name: 'contact_type',
                type: 'menu',
                values: contact_types,
                default: 'tg_id',
                depends_on: {set_by_var: false},
                localeRoot: 'effect.core.crm.contact.add',
            }],
            [{
                description: '',
                placeholder: true,
                name: 'contact_type',
                type: 'textarea',
                depends_on: {set_by_var: true},
                localeRoot: 'effect.core.crm.contact.add',
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': messengers},
                depends_on: {contact_type: messanger_contact_types, set_by_var: false},
                // localeRoot: 'effect.core.crm.contact.add',
                // description: '',
            }],
            [{
                name: 'integration_id',
                type: 'textarea',
                placeholder: '{var:graph.integration.id}',
                depends_on: {contact_type: messanger_contact_types, set_by_var: true},
                // localeRoot: 'effect.core.crm.contact.add',
                // description: '',
            }],
            [{
                name: 'values',
                type: 'tags',
                features: ['adding'],
                values: [
                    '{var:object.text}',
                    '{var:platform.user.id}',
                    '{user:contact.tg_id[0]}',
                    '{num:{var:object.text}|phone}',
                    '{num:{var:phone}|phone}',
                    '{var:platform.user.phone}',
                    '{var:platform.user.email}',
                    '{num:{var:object.text}}',
                    '{var:request.query.id}',
                    '{var:request.query.uid}',
                ],
                default: '{var:platform.user.id}',
                localized: false,
                depends_on: {set_by_var: false, by_value: true},
            }],
            [{
                name: 'values',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.core.crm.contact.add',
                depends_on: {set_by_var: true, by_value: true},
            }],
            [{
                name: 'is_allowed',
                type: 'menu',
                values: ['0', '1'],
                default: '1',
                placeholder: '',
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'is_allowed',
                type: 'textarea',
                placeholder: '{if:{var:status}|1|0}',
                depends_on: {set_by_var: true},
                localeRoot: 'effect.core.crm.contact.status.synonym',
            }],
            [{
                name: 'by_value',
                type: 'checkbox',
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/contact/delete',
        icon: 'user-xmark',
        fields: [
            [{
                description: '',
                placeholder: '',
                name: 'contact_type',
                type: 'menu',
                values: contact_types,
                default: 'phone',
                depends_on: {set_by_var: false},
                localeRoot: 'effect.core.crm.contact.add',
            }],
            [{
                description: '',
                placeholder: true,
                name: 'contact_type',
                type: 'textarea',
                depends_on: {set_by_var: true},
                localeRoot: 'effect.core.crm.contact.add',
            }],
            [{
                name: 'values',
                type: 'tags',
                features: ['adding'],
                values: [
                    '{var:object.text}',
                    '{num:{var:object.text}|phone}',
                    '{num:{var:phone}|phone}',
                    '{var:platform.user.phone}',
                    '{var:platform.user.email}',
                    '{num:{var:object.text}}',
                    '{var:request.query.id}',
                    '{var:request.query.uid}',
                ],
                localized: false,
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'values',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.core.crm.contact.add',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'add_integration',
                type: 'checkbox',
                depends_on: {contact_type: messanger_contact_types}
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': messengers},
                depends_on: {add_integration: true, contact_type: messanger_contact_types, set_by_var: false},
                localeRoot: 'effect.core.crm.contact.add',
                description: '',
            }],
            [{
                name: 'integration_id',
                type: 'textarea',
                placeholder: '{var:graph.integration.id}',
                depends_on: {add_integration: true, contact_type: messanger_contact_types, set_by_var: true},
                localeRoot: 'effect.core.crm.contact.add',
                description: '',
            }]
        ]
    },
    {
        type: 'effect/core/crm/user/replace',
        icon: 'person-through-window',
        fields: [
            [
                {
                    description: '',
                    placeholder: '',
                    name: 'contact_type',
                    type: 'menu',
                    values: [...contact_types, 'app'],
                    default: 'phone',
                    depends_on: {set_by_var: false},
                    localeRoot: 'effect.core.crm.contact.add',
                    width: 12,
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'contact_type',
                    type: 'textarea',
                    depends_on: {set_by_var: true},
                    localeRoot: 'effect.core.crm.contact.add',
                    width: 12,
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'value',
                    type: 'text',
                    localeRoot: 'effect.core.crm.contact.search',
                    width: 12,
                },
            ],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: 'effect.core.crm.contact.add',
            }],
            [{
                name: 'replace_current',
                type: 'hidden',
                default: true,
            }],
            [{
                name: 'create_contact_if_null',
                type: 'hidden',
                default: true,
                depends_on: {contact_type: messanger_contact_types}
            }],
        ]
    },
    {
        type: 'effect/core/crm/contact/search',
        icon: 'address-book',
        fields: [
            [
                {
                    description: '',
                    placeholder: '',
                    name: 'contact_type',
                    type: 'menu',
                    values: [...contact_types, 'app'],
                    default: 'phone',
                    depends_on: {set_by_var: false},
                    localeRoot: 'effect.core.crm.contact.add',
                    width: 12,
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'contact_type',
                    type: 'textarea',
                    depends_on: {set_by_var: true},
                    localeRoot: 'effect.core.crm.contact.add',
                    width: 12,
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'value',
                    type: 'text',
                    width: 12,
                },
            ],
            // [{
            //     name: 'contact_type_desc',
            //     type: 'description',
            //     depends_on: {set_by_var: true},
            // }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: 'effect.core.crm.contact.add',
            }],
            [{
                name: 'replace_current',
                type: 'checkbox',
                default: false,
            }],
            // [{
            //     name: 'integration_id',
            //     type: 'menu',
            //     data: 'integration',
            //     data_filters: {'integration.platform': messengers},
            //     // depends_on: {integration_by_var: false},
            // }],
            [{
                name: 'create_contact_if_null',
                type: 'checkbox',
                depends_on: {contact_type: messanger_contact_types}
            }],
            [{
                name: 'create_user_if_null',
                type: 'checkbox',
            }],
            [{
                name: 'contact_public_name',
                type: 'text',
                depends_on: {create_user_if_null: true}
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                localeRoot: commonLocaleRoot,
                depends_on: {save_result: true}
            }],
        ]
    },

    {
        type: 'effect/core/crm/field/search',
        icon: 'search',
        fields: [
            [
                {
                    label: null,
                    description: null,
                    name: 'code',
                    type: 'menu',
                    data: 'field',
                    data_filters: {system_field_type: 'crm'},
                },
                {
                    // description: '',
                    placeholder: true,
                    name: 'value',
                    type: 'text',
                },
            ],
            [{
                name: 'replace_current',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    // {
    //     type: 'effect/core/crm/field/set',
    //     icon: 'pen-field',
    //     fields: [
    //         [
    //             {
    //                 label: null, // effect.core.user.field.set.name.title
    //                 description: null,
    //                 placeholder: '',
    //                 name: 'data_type',
    //                 type: 'menu',
    //                 values: [
    //                     "text",
    //                     "float",
    //                     "int",
    //                     // "array",
    //                     // "json",
    //                     // "date",
    //                     // "time",
    //                     // "datetime"
    //                 ],
    //                 default: 'text',
    //             },
    //             {
    //                 label: null,  // effect.core.user.field.set.operation.title
    //                 description: null, // is empty
    //                 placeholder: '',
    //                 name: 'operation',
    //                 type: 'menu',
    //                 values: [
    //                     'set',
    //                     'add_text',
    //                     'add_lines',
    //                     'start_from',
    //                     'start_lines',
    //                     'cleanup',
    //                     'increase',
    //                     'decrease',
    //                     'multiply',
    //                     'divide',
    //                     'set_zero',
    //                     'delete',
    //                 ],
    //                 default: 'set',
    //             },
    //         ],
    //         [{
    //             label: null,
    //             description: null,
    //             name: 'code',
    //             type: 'menu', // select + creating
    //             features: ['creating'],
    //             // features: ['vars'], // do not show in tags
    //             data: 'field',
    //             data_filters: {system_field_type: 'crm', data_type_admin: 'text'},
    //         }],
    //         [
    //             {
    //                 name: 'value',
    //                 type: 'textarea',
    //                 features: ['emoji', 'vars', 'len'], // html, lines
    //                 max_length: 4000,
    //                 default: '',
    //             }
    //         ],
    //     ]
    // },
    {
        type: 'effect/core/crm/field/any/get',
        icon: 'file',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                data: 'field',
                localeRoot: fieldLocaleRoot,
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                localeRoot: fieldLocaleRoot,
                features: ['vars'],
                depends_on: {set_code_by_var: true},
            }],

            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'res',
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/number/set',
        icon: 'input-numeric',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu', // select + creating
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'float'},
                localeRoot: fieldLocaleRoot,
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [
                {
                    label: null,  // effect.core.user.field.set.operation.title
                    description: null, // is empty
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                        'delete',
                    ],
                    default: 'set',
                },
                {
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                    default: '',
                    depends_on: {operation: ['set', 'increase', 'decrease', 'multiply', 'divide']}
                }
            ],

            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/number/get',
        icon: 'file-binary',
        fields: [
            [
                {
                    label: null,
                    description: null,
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'crm', data_type_admin: 'float'},
                    localeRoot: fieldLocaleRoot,
                },
            ],
            [
                {
                    name: 'result_var',
                    type: 'text',
                    placeholder: 'res',
                    localeRoot: fieldLocaleRoot,
                }
            ],
        ]
    },
    {
        type: 'effect/core/crm/field/text/set',
        icon: 'input-text',
        fields: [
            [
                {
                    label: null,
                    description: null,
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'crm', data_type_admin: 'text'},
                    depends_on: {set_code_by_var: false},
                    localeRoot: fieldLocaleRoot,
                    width: 12,
                },
                {
                    name: 'code_var',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {set_code_by_var: true},
                    localeRoot: fieldLocaleRoot,
                    width: 12,
                },
                {
                    label: null,
                    description: null,
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                        'cleanup',
                        'delete',
                    ],
                    default: 'set',
                    width: 12,
                },
            ],
            [{
                name: 'value',
                type: 'textarea',
                features: ['emoji', 'vars', 'len'], // html, lines
                // max_length: 4000,
                default: '',
                depends_on: {
                    operation: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                    ]
                }
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]

    },
    {
        type: 'effect/core/crm/field/text/get',
        inherit: 'effect/core/crm/field/number/get',
        icon: 'file-lines',
        replace: {
            code: {
                name: 'code',
                type: 'menu', // select + creating
                features: ['creating'],
                localeRoot: fieldLocaleRoot,
                // features: ['vars'], // do not show in tags
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'text'},
            },
        }
    },

    {
        type: 'effect/core/crm/field/array/set',
        icon: 'brackets-square',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'array'},
                depends_on: {set_code_by_var: false},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'value',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
            // result var fields:
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/json/set',
        icon: 'brackets-curly',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'json'},
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'path',
                type: 'text',
                features: ['vars'],
                placeholder: 'path.to.var',
            }],
            [
                {
                    label: null, // effect.core.user.field.set.name.title
                    description: null,
                    placeholder: '',
                    name: 'data_type',
                    type: 'menu',
                    values: [
                        "text",
                        "float",
                        "json",
                    ],
                    default: 'text',
                    depends_on: {path: true},
                    width: 12,
                },
                {
                    label: null,  // effect.core.user.field.set.operation.title
                    description: null, // is empty
                    placeholder: '',
                    name: 'operation_number',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                    ],
                    default: 'set',
                    depends_on: {data_type: 'float', path: true},
                    width: 12,
                },
                {
                    label: null,
                    description: null,
                    placeholder: '',
                    name: 'operation_text',
                    type: 'menu',
                    values: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                        'cleanup',
                    ],
                    default: 'set',
                    depends_on: {data_type: 'text', path: true},
                    width: 12,
                },
                {
                    name: 'json_path',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {data_type: 'json', path: true},
                    placeholder: 'path.to.value',
                    width: 12,
                }
            ],
            [{
                name: 'value',
                type: 'textarea',
                features: ['vars'],
                depends_on: {path: true},
            }],
            [{
                name: 'json_value',
                type: 'textarea',
                features: ['vars'],
                depends_on: {path: false},
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
            // result var fields:
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/json/get',
        inherit: 'effect/core/crm/field/number/get',
        icon: 'file-code',
        replace: {
            code: {
                name: 'code',
                type: 'menu',
                features: ['creating'],
                localeRoot: fieldLocaleRoot,
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'json'},
            },
        }
    },

    {
        type: 'effect/core/crm/field/datetime/set',
        icon: 'calendar-arrow-up',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'datetime'},
                depends_on: {set_code_by_var: false},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'value',
                type: 'textarea',
                features: ['vars'],
                default: '{date:|%s}',
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/datetime/get',
        inherit: 'effect/core/crm/field/number/get',
        icon: 'calendar-arrow-down',
        replace: {
            code: {
                name: 'code',
                type: 'menu',
                features: ['creating'],
                localeRoot: fieldLocaleRoot,
                data: 'field',
                data_filters: {system_field_type: 'crm', data_type_admin: 'datetime'},
            },
        }
    },

    {
        type: 'effect/core/crm/field/bulk/delete',
        icon: 'bed-bunk',
        fields: [
            [{
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_code_by_var: false},
                localeRoot: 'effect.core.crm.list.add',
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/bulk/replace',
        icon: 'arrows-turn-to-dots',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                data: 'field',
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'old_value',
                type: 'textarea',
                placeholder: true,
                depends_on: {affect_all: false},
                features: ['vars'],
            }],
            [{
                name: 'new_value',
                type: 'textarea',
                placeholder: true,
                depends_on: {delete: false},
                features: ['vars'],
            }],
            [{
                name: 'affect_all',
                type: 'checkbox',
            }],
            [{
                name: 'delete',
                type: 'checkbox',
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/core/crm/list/count',
        icon: 'clipboard-user',
        fields: [
            [{
                name: 'code',
                type: 'tags',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_codes_by_var: false},
                localeRoot: 'effect.core.crm.list.add',
            }],
            [{
                name: 'codes_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_codes_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'set_codes_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                depends_on: {array_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true, array_var: false},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/delete',
        icon: 'delete-left',
        fields: [
            [{
                name: 'code',
                type: 'tags',
                data: 'field',
                data_filters: {system_field_type: 'crm'},
                depends_on: {set_codes_by_var: false},
            }],
            [{
                name: 'codes_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_codes_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                name: 'set_codes_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/core/crm/field/system/data',
        icon: 'wrench',
        fields: [
            [{
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                data: 'field',
                localeRoot: fieldLocaleRoot,
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                localeRoot: fieldLocaleRoot,
                features: ['vars'],
                depends_on: {set_code_by_var: true},
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/crm/field/create',
        icon: 'file-invoice',
        fields: [
            [{
                name: 'title',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'code',
                placeholder: 'system_name',
                type: 'text',
                features: ['vars'],
            }],
            [{
                name: 'system_field_type',
                type: 'menu',
                placeholder: '',
                values: field_values.admin_types,
                default: 'crm'
            }],
            [{
                name: 'data_type_admin',
                type: 'menu',
                placeholder: '',
                depends_on: {system_field_type: 'crm'},
                values: field_values.data_type,
                localeRoot: 'field',
                default: 'text'
            }],
            [{
                name: 'read_access',
                type: 'menu',
                placeholder: '',
                localeRoot: 'field',
                values: field_values.read_access,
                default: 'admin'
            }],
            [{
                name: 'default_value',
                type: 'textarea',
                features: ['vars'],
            }],
        ]
    },
    {
        type: 'effect/core/crm/utm/set',
        icon: 'user-tag',
        fields: [
            [{
                // source_id
                name: 'utm_id',
                type: 'text',
                default: '{utm:}',
                placeholder: '{var:utm.utm_id}',
                features: ['vars'],
            }],
            [
                {
                    name: 'field_id',
                    type: 'text',
                    placeholder: '{var:object.id}',
                    features: ['vars'],
                },
                {
                    name: 'page_id',
                    type: 'text',
                    placeholder: '{var:object.page.id}',
                    features: ['vars'],
                },
            ],
        ]
    },
    {
        type: 'effect/core/crm/utm/get',
        icon: 'tags',
        fields: [
            [{
                name: 'result_format',
                type: 'menu',
                placeholder: '',
                values: ['newest', 'latest', 'array', 'object'],
                default: 'newest',
                localized: true,
            }],
            [{
                name: 'result_var',
                type: 'text',
                features: ['vars'],
                placeholder: 'utms',
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    // {
    //     type: 'effect/core/crm/list/clear',
    //     icon: 'users-slash',
    //     fields: [
    //         [
    //             {
    //                 label: null, //   effect.core.user.list.remove_all.name.title
    //                 description: null, //   effect.core.user.list.remove_all.name.desc
    //                 name: 'code',
    //                 type: 'menu', // select + creating
    //                 features: ['creating'],
    //                 // features: ['vars'], // do not show in tags
    //                 data: 'field',
    //                 data_filters: {system_field_type: 'list', data_type_admin: 'int'},
    //             },
    //         ],
    //     ]
    // },

    {
        type: 'effect/core/crm/contact/project',
        icon: 'users-cog',
        // system: true,
        fields: [
            [{
                name: 'project_id_var',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            }],
            [{
                placeholder: '',
                name: 'user_role',
                type: 'hidden',
                values: author_types,
                default: 'chat',
            }]
        ]
    },

    {
        type: 'effect/core/crm/user/clear',
        icon: 'shower',
        fields: [[
            {
                type: "description",
                name: 'desc',
            }
        ]]
    },
    {
        type: 'effect/core/crm/user/delete',
        icon: 'ufo-beam',
    },
    {
        type: 'effect/core/game/currency/change',
        icon: 'coin-vertical',
        fields: [
            [{
                label: '',
                description: '',
                name: 'code',
                type: 'menu',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'currency', data_type_admin: 'int'},
                depends_on: {set_code_by_var: false},
            }],
            [{
                name: 'code_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_code_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [
                {
                    label: '',
                    description: '',
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'increase',
                        'decrease',
                        'set',
                        'multiply',
                        'divide',
                        'set_zero',
                        'delete',
                    ],
                    default: 'increase',
                },
                {
                    label: '',
                    description: '',
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                    placeholder: '0',
                    depends_on: {operation: ['set', 'increase', 'decrease', 'multiply', 'divide']}
                }
            ],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/game/currency/get',
        icon: 'coins',
        inherit: 'effect/core/crm/field/number/get',
        replace: {
            code: {
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'currency', data_type_admin: 'int'},
            },
        }
    },
    // {
    //     type: 'effect/core/game/money/change',
    //     icon: 'coin-vertical',
    //     inherit: 'effect/core/game/currency/change',
    //     replace: {
    //         code: {
    //             label: null,
    //             description: null,
    //             name: 'code',
    //             type: 'menu',
    //             features: ['creating'],
    //             data: 'field',
    //             data_filters: {system_field_type: 'money', data_type_admin: 'float'},
    //         },
    //     }
    // },
    // {
    //     type: 'effect/core/game/money/get',
    //     icon: 'coins',
    //     inherit: 'effect/core/crm/field/number/get',
    //     replace: {
    //         code: {
    //             label: null,
    //             description: null,
    //             name: 'code',
    //             type: 'menu',
    //             features: ['creating'],
    //             data: 'field',
    //             data_filters: {system_field_type: 'money', data_type_admin: 'float'},
    //         },
    //     }
    // },
    {
        type: 'effect/core/game/resource/change',
        inherit: 'effect/core/game/currency/change',
        icon: 'piggy-bank',
        replace: {
            code: {
                label: '',
                description: '',
                name: 'code',
                type: 'menu',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'resource', data_type_admin: 'int'},
                depends_on: {set_code_by_var: false},
            },
        }
    },
    {
        type: 'effect/core/game/resource/get',
        icon: 'pig',
        inherit: 'effect/core/crm/field/number/get',
        replace: {
            code: {
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'resource', data_type_admin: 'int'},
            },
        }
    },
    {
        type: 'effect/core/game/achievement/give',
        inherit: 'effect/core/crm/list/add',
        icon: 'medal',
        fields: [
            [{
                name: 'code',
                type: 'tags',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'achievement', data_type_admin: 'int'},
                depends_on: {set_codes_by_var: false},
            }],
            [{
                name: 'codes_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_codes_by_var: true},
                localeRoot: fieldLocaleRoot,
            }],
            [{
                description: '',
                name: 'add_again',
                type: 'switcher',
            }],
            [{
                name: 'set_codes_by_var',
                type: 'switcher',
                localeRoot: fieldLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/game/achievement/take',
        inherit: 'effect/core/crm/list/remove',
        icon: 'person-sign',
        replace: {
            code: {
                // description: '',
                name: 'code',
                type: 'tags',
                data: 'field',
                data_filters: {system_field_type: 'achievement', data_type_admin: 'int'},
                depends_on: {set_codes_by_var: false},
            }
        }
    },
    {
        type: 'effect/core/game/achievement/get',
        icon: 'bagel',
        inherit: 'effect/core/crm/field/number/get',
        replace: {
            code: {
                label: null,
                description: null,
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'achievement', data_type_admin: 'int'},
            },
        }
    },

    {
        type: 'effect/core/game/players/count',
        inherit: 'effect/core/crm/list/count',
        icon: 'gamepad-modern',
        replace: {
            code: {
                name: 'code',
                type: 'tags',
                data: 'field',
                data_filters: {system_field_type: ['resource', 'achievement', 'currency'], data_type_admin: 'int'},
                depends_on: {set_codes_by_var: false},
            }
        }
    },

    // {
    //     type: 'effect/core/media/send/photo',
    //     icon: 'image',
    //     fields: [
    //         [{
    //             name: 'media',
    //             type: 'photo',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/media/send/video',
    //     icon: 'film',
    //     fields: [
    //         [{
    //             name: 'media',
    //             type: 'video',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/media/send/media',
    //     icon: 'photo-film',
    //     fields: [
    //         [{
    //             name: 'media',
    //             type: 'media',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/media/send/doc',
    //     icon: 'file-export',
    //     fields: [
    //         [{
    //             name: 'file',
    //             type: 'file',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/media/send/audio',
    //     icon: 'file-music',
    //     fields: [
    //         [{
    //             name: 'file',
    //             type: 'file',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/media/send/voice',
    //     icon: 'microphone-lines',
    //     fields: [
    //         [{
    //             name: 'file',
    //             type: 'file',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/media/send/video_note',
    //     icon: 'file-video',
    //     fields: [
    //         [{
    //             name: 'media',
    //             type: 'media',
    //         }],
    //     ]
    // },

    {
        type: 'effect/core/wall/comment/send',
        icon: 'comment-check',
        fields: [
            [{
                name: 'object_id',
                type: 'hidden',
            }],
            [{
                name: 'post_id',
                type: 'text',
                default: '{var:object.post.id}',
                placeholder: '{var:object.post.id}',
            }],
            [{
                label: '',
                description: '',
                name: 'text',
                type: 'textarea',
                features: ['emoji', 'vars', 'len', 'html'], // lines
                max_length: 4000,
            }],
            [{
                type: 'description', // empty space (fix textarea margin)
                label: '',
                description: ' ',
            }],
            [{
                description: '',
                name: 'reply',
                type: 'checkbox',
                width: 14,
            }, {
                label: '',
                description: '',
                name: 'reply_id',
                type: 'text',
                default: '{var:object.id}',
                placeholder: '{var:object.id}',
                depends_on: {reply: true},
                width: 10,
            }],
            [{
                name: 'do_nothing_on_empty',
                default: true,
                type: 'checkbox',
            }],
            [{
                name: 'allow_link_preview',
                type: 'checkbox',
                platforms: ['tg'],
            }],
            [{
                description: '',
                placeholder: '',
                // tooltip: false,
                name: 'attach',
                type: 'checkbox',
                platforms: ['vk'],
            }],
            [{
                name: 'attachments',
                type: 'tags',
                features: ['adding'],
                depends_on: {attach: true},
            }],
            [{
                name: 'protect_content',
                default: false,
                type: 'checkbox',
                platforms: ['tg'],
            }],
        ]
    },
    {
        type: 'effect/core/wall/comment/edit',
        icon: 'comment-pen',
        inherit: 'effect/core/wall/comment/send',
        replace: {
            object_id: {
                name: 'object_id',
                type: 'text',
                default: '{var:effect.comment.id}',
                placeholder: '{var:effect.comment.id}',
            },
            post_id: {
                name: 'post_id',
                type: 'unset',
            },

            reply: {
                name: 'reply',
                type: 'unset',
            },
            reply_id: {
                name: 'reply_id',
                type: 'unset',
            },
        }
    },
    // {
    //     type: 'effect/core/wall/comment/delete',
    //     icon: 'comment-xmark', // 'trash-can-list',
    //     fields: [
    //         [{
    //             name: 'object_id',
    //             type: 'text',
    //             features: ['vars'],
    //             default: '{var:object.id}',
    //             placeholder: '{var:object.id}',
    //         }],
    //     ]
    // },


    // {
    //     type: 'effect/core/wall/comment/photo', // effect.core.chat.message.send.title
    //     description: '',
    //     icon: 'image',
    //     context: true,
    //     fields: [
    //         [{
    //             label: '',
    //             description: '',
    //             name: 'code',
    //             type: 'photo',
    //             limit: 1,
    //         }],
    //         [{
    //             name: 'post_id',
    //             type: 'text',
    //             default: '{var:object.post.id}',
    //             placeholder: '{var:object.post.id}',
    //         }],
    //         [{
    //             name: 'protect_content',
    //             type: 'checkbox',
    //             platforms: ['tg'],
    //         }],
    //         [{
    //             name: 'add_caption',
    //             type: 'checkbox',
    //         }],
    //         [{
    //             name: 'caption',
    //             type: 'textarea',
    //             features: ['vars'],
    //             depends_on: {add_caption: true}
    //         }],
    //         [{
    //             name: 'allow_link_preview',
    //             type: 'checkbox',
    //             depends_on: {add_caption: true}
    //         }],
    //     ]
    // },

    {
        type: 'effect/core/channel/join/action',
        icon: 'check-to-slot',
        fields: [
            [{
                label: '',
                placeholder: '',
                name: 'operation',
                type: 'menu',
                values: [
                    'accept',
                    'decline',
                ],
                default: 'accept',
            },],
        ]
    },
    // {
    //     type: 'effect/core/channel/admin/demote',
    //     icon: 'turn-down',
    // },
    {
        type: 'effect/core/channel/member/block',
        icon: 'user-lock',
    },
    {
        type: 'effect/core/channel/member/unblock',
        icon: 'user-unlock',
    },
    {
        type: 'effect/core/channel/member/remove',
        icon: 'person-dolly',
        context: true,
        fields: []
    },
    {
        type: 'effect/core/channel/member/leave',
        icon: 'sign-out-alt',
        context: true,
        fields: []
    },

    {
        type: 'effect/core/site/page/access',
        icon: 'display',
        fields: [
            [{
                name: 'site_id',
                type: 'menu',
                data: 'site',
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'page_id',
                type: 'text',
                placeholder: '{var:object.page.id}',
                features: ['vars'],
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'page_id',
                type: 'tags',
                data: 'pageShort',
                data_spec_query: {site_id: 'site_id'},
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'action',
                type: 'text',
                features: ['vars'],
                depends_on: {set_by_var: true},
                localeRoot: 'effect.core.site.page.access.synonym',
            }],
            [{
                name: 'action',
                type: 'menu',
                placeholder: '',
                values: ['1', '0'],
                default: '1',
                localized: true,
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/site/page/open/next',
        icon: 'display-arrow-down',
        fields: [
            [{
                name: 'page_id',
                type: 'text',
                placeholder: '{var:object.page.id}',
                default: '{var:object.page.id}',
                features: ['vars'],
            }],
            [{
                name: 'limiter',
                type: 'menu',
                values: ['lesson', 'module', 'course'],
                default: 'lesson',
            }],
            [{
                name: 'only_closed',
                type: 'switcher',
            }],
        ]
    },
    {
        type: 'effect/core/site/redirect/url',
        icon: 'diamond-turn-right',
        fields: [
            [{
                name: 'url',
                type: 'text',
                placeholder: '{var:url}',
                features: ['vars'],
            }],
        ]
    },
    {
        type: 'effect/core/site/utm/get',
        icon: 'user-tag',
        fields: [
            [{
                name: 'source_id',
                type: 'text',
                default: '{user:source_id}',
                placeholder: '{var:utm.id}',
                features: ['vars'],
            }],
            [{
                name: 'result_var',
                type: 'text',
                features: ['vars'],
                placeholder: 'utm',
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/site/utm/create',
        icon: 'list-timeline',
        fields: [
            [
                {
                    name: 'utm_source',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm_source',
                    localeRoot: 'effect.core.site.utm.url',
                },
                {
                    name: 'utm_medium',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm_medium',
                    localeRoot: 'effect.core.site.utm.url',
                }
            ],
            [
                {
                    name: 'utm_campaign',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm_campaign',
                    localeRoot: 'effect.core.site.utm.url',
                },
                {
                    name: 'utm_content',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm_content',
                    localeRoot: 'effect.core.site.utm.url',
                }
            ],
            [
                {
                    name: 'utm_term',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm_term',
                    localeRoot: 'effect.core.site.utm.url',
                },
                {
                    name: 'utm_group',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm_group',
                    localeRoot: 'effect.core.site.utm.url',
                }
            ],
            [
                {
                    name: 'tail',
                    type: 'textarea',
                    features: ['vars'],
                    placeholder: '{var:request.query}',
                }
            ],
            [
                {
                    name: 'store_hit',
                    type: 'switcher',
                }
            ],
            [
                {
                    name: 'result_var',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'utm.id',
                    // localeRoot: commonLocaleRoot,
                }
            ],
        ]
    },
    // {
    //     type: 'effect/core/site/utm/url',
    //     icon: 'location-crosshairs',
    //     fields: [
    //         [
    //             {
    //                 name: 'url',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'https://example.com/url',
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'utm_source',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'utm_source',
    //             },
    //             {
    //                 name: 'utm_medium',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'utm_medium',
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'utm_campaign',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'utm_campaign',
    //             },
    //             {
    //                 name: 'utm_content',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'utm_content',
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'utm_term',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'utm_term',
    //             },
    //             {
    //                 name: 'utm_group',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'utm_group',
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'result_var',
    //                 type: 'text',
    //                 features: ['vars'],
    //                 placeholder: 'path.to.var.with.link',
    //             }
    //         ],
    //     ]
    //
    // },
    // {
    //     type: 'effect/core/project/graph/run',
    //     icon: 'diagram-project',
    //     fields: [
    //         [{
    //             name: 'graph',
    //             type: 'menu',
    //             data: 'graph',
    //             data_filters: {is_on: true},
    //         }],
    //         [{
    //             name: 'node',
    //             type: 'menu',
    //             data: 'graph',
    //             data_field: 'nodes',
    //         }],
    //     ]
    // },
    {
        type: 'effect/core/site/url/create',
        icon: 'link',
        fields: [
            [{
                placeholder: 'https://example.com/success',
                name: 'url',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                placeholder: '{calc:60*24}',
                name: 'minutes',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                placeholder: 'https://example.com/error',
                name: 'error_url',
                type: 'textarea',
                features: ['vars'],
                depends_on: {'params.result.error': false},
            }],
            [{
                name: 'params.result.error',
                type: 'textarea',
                features: ['vars'],
                depends_on: {error_url: false},
            }],
            [
                {
                    name: 'integration_var',
                    type: 'textarea',
                    default: '{var:graph.integration.id}',
                    placeholder: '{var:graph.integration.id}',
                },
            ],
            [
                {
                    name: 'params.contact.type',
                    type: 'textarea',
                    default: '{var:platform.contact.type}',
                    placeholder: '{var:platform.contact.type}',
                    depends_on: {is_public: false},
                },
            ],
            [
                {
                    name: 'data.contact.id',
                    type: 'textarea',
                    default: '{user:platform.id}',
                    placeholder: '{user:platform.id}',
                    depends_on: {is_public: false},
                },
            ],
            [{
                name: 'is_public',
                type: 'checkbox',
            }],
            [{
                name: 'set_title',
                type: 'checkbox',
            }],
            [{
                name: 'title',
                type: 'textarea',
                depends_on: {set_title: true}
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
            }],
            [{
                name: 'data_values',
                type: 'params',
                description: '',
                depends_on: {add_params: true},
                localePath: 'graph.container.form.params',
            }],

            [{
                name: 'save_result',
                type: 'checkbox',
                tooltip: '',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'url',
                depends_on: {save_result: true},
            }],
        ]
    },

    {
        type: 'effect/integration/vk/story/upload', // effect.core.chat.message.send.title
        description: '',
        icon: 'file-image',
        context: true,
        fields: [
            [{
                label: '',
                description: '',
                name: 'photo_url',
                type: 'photo',
                depends_on: {by_url: false},
                aspect: 0.5625,
            }],
            [{
                name: 'by_url',
                type: 'checkbox',
            }],
            [{
                name: 'photo_url_var',
                type: 'textarea',
                features: ['vars'],
                depends_on: {by_url: true}
            }],
            [{
                name: 'add_link',
                type: 'checkbox',
            }],
            [{
                name: 'link_text',
                type: 'menu',
                placeholder: '',
                values: [
                    'learn_more',
                    'go_to',
                    'view',
                    'open',
                    'more',
                    'book',
                    'order',
                    'enroll',
                    'fill',
                    'signup',
                    'buy',
                    'to_store',
                    'ticket',
                    'vote',
                    'write',
                    'contact',
                    'watch',
                    'play',
                    'install',
                    'read',
                ],
                default: 'learn_more',
                depends_on: {add_link: true, set_by_var: false}
            }],
            [{
                name: 'link_text',
                type: 'textarea',
                placeholder: '',
                features: ['vars'],
                depends_on: {add_link: true, set_by_var: true}
            }],
            [{
                name: 'link_url',
                type: 'textarea',
                features: ['vars'],
                depends_on: {add_link: true}
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
                depends_on: {add_link: true}
            }],
            [{
                name: 'add_stickers_code',
                type: 'checkbox',
            }],
            [{
                name: 'stickers_code',
                type: 'textarea',
                features: ['vars'],
                depends_on: {add_stickers_code: true}
            }],
        ]
    },
    {
        type: 'effect/core/var/condition/result',
        system: false,
        icon: 'code-branch',
        fields: [
            [
                {
                    name: 'mode',
                    type: 'menu',
                    values: ['and', 'or'],
                    default: 'and',
                },
                {
                    name: 'conditions',
                    type: 'conditions', // TODO: create it later
                    values: [],
                },
            ],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'url',
            }],
        ]
    },
    {
        type: 'effect/core/var/text/set',
        icon: 'rectangle-ad',
        fields: [
            [
                {
                    // label: '',
                    // description: '',
                    name: 'var',
                    type: 'text',
                    features: ['vars', 'copy'],
                },
                {
                    // label: '',
                    // description: '',
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                        'cleanup',
                        'delete',
                    ],
                    default: 'set',
                },
            ],
            [
                {
                    // label: '',
                    // description: '',
                    name: 'value',
                    type: 'textarea',
                    features: ['vars'],
                    depends_on: {
                        operation: [
                            'set',
                            'add_text',
                            'add_lines',
                            'start_from',
                            'start_lines',
                        ]
                    }
                },
            ],
            [
                {
                    name: 'data_type',
                    type: 'hidden',
                    default: 'text',
                },
            ],
        ],
    },
    {
        type: 'effect/core/var/number/set',
        icon: 'square-7',
        fields: [
            [
                {
                    // label: '',
                    // description: '',
                    name: 'var',
                    type: 'textarea',
                    features: ['vars', 'copy'],
                },
                {
                    // label: '',
                    // description: '',
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                        // 'delete',
                    ],
                    default: 'set',
                },
            ],
            [

                {
                    // label: '',
                    // description: '',
                    name: 'value',
                    type: 'textarea',
                    features: ['vars'],
                    depends_on: {operation: ['set', 'increase', 'decrease', 'multiply', 'divide']},
                },
            ],
            [
                {
                    name: 'data_type',
                    type: 'hidden',
                    default: 'float',
                },
            ],
        ]
    },
    {
        type: 'effect/core/var/bool/set',
        icon: 'toggle-large-on',
        fields: [
            [
                {
                    name: 'var',
                    type: 'text',
                    features: ['vars', 'copy'],
                },
            ],
            [
                {
                    name: 'value',
                    type: 'textarea',
                    features: ['vars'],
                },
            ],
        ]
    },
    {
        type: 'effect/core/var/json/set',
        icon: 'brackets-curly',
        fields: [
            [
                {
                    name: 'var',
                    type: 'text',
                    features: ['vars', 'copy'],
                },
            ],
            [
                {
                    name: 'value',
                    type: 'textarea',
                    features: ['vars'],
                },
            ],
        ],
    },
    {
        type: 'effect/core/var/batch/set',
        icon: 'box-archive',
        fields: [
            [
                {
                    description: '',
                    name: 'params',
                    type: 'params',
                    localePath: 'graph.container.form.vars',
                },
            ],
        ]
    },
    {
        type: 'effect/core/var/context/delete',
        icon: 'eraser',
        fields: [
            [{
                name: 'var',
                type: 'text',
            }],
        ]
    },
    {
        type: 'effect/core/var/template/set',
        icon: 'book-font',
        fields: [
            [
                {
                    name: 'path',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'path.to.var',
                }
            ],
            [

                {
                    label: null, // effect.core.user.field.set.name.title
                    description: null,
                    placeholder: '',
                    name: 'data_type',
                    type: 'menu',
                    values: [
                        "text",
                        "float",
                        "json",
                    ],
                    default: 'text',
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                },
                {
                    label: null,  // effect.core.user.field.set.operation.title
                    description: null, // is empty
                    placeholder: '',
                    name: 'operation_number',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                    ],
                    default: 'set',
                    localeRoot: 'effect.core.crm.field.json.set',
                    depends_on: {data_type: 'float'},
                    width: 12,
                },
                {
                    label: null,
                    description: null,
                    placeholder: '',
                    name: 'operation_text',
                    type: 'menu',
                    values: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                        'cleanup',
                    ],
                    default: 'set',
                    localeRoot: 'effect.core.crm.field.json.set',
                    depends_on: {data_type: 'text'},
                    width: 12,
                },
                {
                    name: 'json_path',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {data_type: 'json'},
                    placeholder: 'path.to.value',
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                }
            ],
            [{
                name: 'value',
                type: 'textarea',
                features: ['vars'],
                default: '',
            }],
        ]
    },
    {
        type: 'effect/core/var/template/batch',
        icon: 'suitcase',
        fields: [
            [
                {
                    description: '',
                    name: 'params',
                    type: 'params',
                    localePath: 'graph.container.form.vars',
                },
            ],
        ]
    },
    {
        type: 'effect/core/var/global/set',
        icon: 'books',
        fields: [
            [
                {
                    label: null,
                    description: null,
                    name: 'code',
                    type: 'menu', // select + creating
                    data: 'glob',
                    features: ['creating'],
                    depends_on: {set_code_by_var: false},
                    width: 24,
                },
                {
                    name: 'code_var',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {set_code_by_var: true},
                    width: 24,
                }
            ],
            [
                {
                    label: null, // effect.core.user.field.set.name.title
                    description: null,
                    placeholder: '',
                    name: 'storage',
                    type: 'menu',
                    values: [
                        "text",
                        "float",
                        "json",
                    ],
                    default: 'text',
                    width: 12,
                },
                {
                    label: null,  // effect.core.user.field.set.operation.title
                    description: null, // is empty
                    placeholder: '',
                    name: 'operation_number',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                    ],
                    default: 'set',
                    depends_on: {storage: 'float'},
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                },
                {
                    label: null,
                    description: null,
                    placeholder: '',
                    name: 'operation_text',
                    type: 'menu',
                    values: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                        'cleanup',
                    ],
                    default: 'set',
                    depends_on: {storage: 'text'},
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                },
                {
                    name: 'json_path',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {storage: 'json'},
                    placeholder: 'path.to.value',
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                }
            ],
            [{
                name: 'value',
                type: 'textarea',
                features: ['vars'],
                default: '',
            }],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
            }],
        ]
    },
    {
        type: 'effect/core/var/global/get',
        icon: 'person-booth',
        fields: [
            [
                {
                    label: null,
                    description: null,
                    name: 'code',
                    type: 'menu',
                    data: 'glob',
                    features: ['creating'],
                    depends_on: {set_code_by_var: false},
                    localeRoot: 'effect.core.var.global.set',
                    width: 24,
                },
                {
                    name: 'code_var',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {set_code_by_var: true},
                    localeRoot: 'effect.core.var.global.set',
                    width: 24,
                }
            ],
            [{
                name: 'set_code_by_var',
                type: 'checkbox',
                localeRoot: 'effect.core.var.global.set',
            }],
            [{
                placeholder: '',
                name: 'storage',
                type: 'menu',
                values: [
                    "text",
                    "float",
                    "json",
                    "var"
                ],
                default: 'text',
            }],
            [{
                name: 'json_path',
                type: 'text',
                features: ['vars'],
                depends_on: {storage: 'json'},
                placeholder: 'path.to.value',
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'res',
            }],
        ]
    },
    {
        type: 'effect/core/var/template/field/get',
        icon: 'book-circle-arrow-right',
        fields: [
            [{
                name: 'template_ids',
                type: 'tags',
                features: ['adding'],
                data: 'template',
                placeholder: '{var:array}',
            }],
        ]
    },
    {
        type: 'effect/core/var/template/field',
        icon: 'book',
        fields: [
            [
                {
                    name: 'path',
                    type: 'text',
                    features: ['vars'],
                    placeholder: 'field_name',
                }
            ],
            [

                {
                    label: null, // effect.core.user.field.set.name.title
                    description: null,
                    placeholder: '',
                    name: 'data_type',
                    type: 'menu',
                    values: [
                        "text",
                        "float",
                        "json",
                    ],
                    default: 'text',
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                },
                {
                    label: null,  // effect.core.user.field.set.operation.title
                    description: null, // is empty
                    placeholder: '',
                    name: 'operation_number',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                    ],
                    default: 'set',
                    depends_on: {data_type: 'float'},
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                },
                {
                    label: null,
                    description: null,
                    placeholder: '',
                    name: 'operation_text',
                    type: 'menu',
                    values: [
                        'set',
                        'add_text',
                        'add_lines',
                        'start_from',
                        'start_lines',
                        'cleanup',
                    ],
                    default: 'set',
                    depends_on: {data_type: 'text'},
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                },
                {
                    name: 'json_path',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {data_type: 'json'},
                    placeholder: 'path.to.value',
                    localeRoot: 'effect.core.crm.field.json.set',
                    width: 12,
                }
            ],
            [{
                name: 'value',
                type: 'textarea',
                features: ['vars'],
                default: '',
            }],
        ]
    },
    {
        type: 'effect/core/var/jump/to',
        icon: 'transporter',
        fields: [
            [{
                name: 'localIds',
                type: 'tags',
                features: ['adding'],
                data: 'nodes',
            }],
            [{
                name: 'type',
                type: 'hidden',
                default: 'jump',
            }],
            [{
                name: 'ignore_next',
                type: 'switcher',
                default: false,
            }],
        ]
    },
    {
        type: 'effect/core/var/jump/graph',
        icon: 'person-to-portal',
        fields: [
            [{
                name: 'graph_id',
                type: 'menu',
                data: 'graph',
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'graph_id',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.core.var.jump.graph.synonym',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'local_id',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'set_by_var',
                type: 'switcher',
                description: '',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'ignore_next',
                type: 'switcher',
                default: false,
                localeRoot: 'effect.core.var.jump.to',
            }],
        ]
    },
    {
        type: 'effect/core/var/context/chat',
        icon: 'comments-question-check',
        fields: [
            [{
                name: 'value',
                type: 'menu',
                data: 'user',
                data_filters: {profile_type: 'chat'},
                data_query: {profile_types: ['chat', 'channel'], page_size: 0},
                depends_on: {from_var: false, by_user_id: false},
            }],
            [{
                name: 'value',
                type: 'textarea',
                description: '',
                placeholder: '{user:id}',
                features: ['vars'],
                depends_on: {by_user_id: true},
            }],
            [{
                name: 'var',
                type: 'textarea',
                default: '{var:platform.user.id}',
                depends_on: {from_var: true, by_user_id: false}
            }],
            [{
                name: 'from_var',
                type: 'checkbox',
                default: false,
                depends_on: {by_user_id: false}
            }],
            [{
                name: 'by_user_id',
                type: 'checkbox',
                default: false,
                depends_on: {from_var: false}
            }],
        ]
    },
    {
        type: 'effect/core/var/context/channel',
        icon: 'check-to-slot',
        fields: [
            [{
                name: 'value',
                type: 'menu',
                data: 'user',
                data_filters: {profile_type: 'channel'},
                data_query: {profile_types: ['chat', 'channel'], page_size: 0},
                depends_on: {from_var: false, by_user_id: false},
            }],
            [{
                name: 'value',
                type: 'textarea',
                description: '',
                placeholder: '{user:id}',
                features: ['vars'],
                depends_on: {by_user_id: true},
            }],
            [{
                name: 'var',
                type: 'textarea',
                default: '{var:platform.user.id}',
                depends_on: {from_var: true, by_user_id: false},
                localeRoot: 'effect.core.var.context.chat',
            }],
            [{
                name: 'from_var',
                type: 'checkbox',
                default: false,
                depends_on: {by_user_id: false},
                localeRoot: 'effect.core.var.context.chat',
            }],
            [{
                name: 'by_user_id',
                type: 'checkbox',
                default: false,
                depends_on: {from_var: false},
                localeRoot: 'effect.core.var.context.chat',
            }],
        ]
    },
    {
        type: 'effect/core/var/context/integration',
        icon: 'plug-circle-bolt',
        // inherit: 'effect/core/var/context/chat',
        // replace: {
        //     value: {
        //         name: 'value',
        //         type: 'menu',
        //         data: 'integration',
        //     },
        //     var: {
        //         name: 'var',
        //         type: 'text',
        //         default: '{var:graph.integration.id}',
        //         depends_on: {from_var: true}
        //     }
        // }

        fields: [
            [{
                name: 'value',
                type: 'menu',
                data: 'integration',
                data_query: {ordering: '-updated_at'},
                depends_on: {from_var: false},
            }],
            [{
                name: 'var',
                type: 'text',
                placeholder: '{var:graph.integration.id}',
                default: '{var:graph.integration.id}',
                depends_on: {from_var: true}
            }],
            [{
                name: 'from_var',
                type: 'checkbox',
                default: false,
            }],
        ]
    },
    {
        type: 'effect/core/var/state/delete',
        icon: 'trash-list',
        fields: [
            [{
                name: 'process_id',
                type: 'tags',
                default: ['{var:graph.item.id}'],
                data: 'graph',
                features: ['adding'],
                depends_on: {is_current: false},
            }],
            [{
                name: 'is_current',
                type: 'switcher',
                default: true,
            }],
        ]
    },
    {
        type: 'effect/core/var/file/save',
        icon: 'file-arrow-up',
        fields: [
            [{
                name: 'var_name',
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [{
                name: 'lifetime',
                type: 'textarea',
                default: '10',
                placeholder: '10',
                features: ['vars'],
                depends_on: {forever: false},
            }],
            [{
                name: 'file_ext',
                type: 'text',
                placeholder: 'txt',
                default: 'txt',
                depends_on: {set_filename: false},
                max_length: 8,
            }],
            [
                {
                    name: 'file_name',
                    type: 'text',
                    placeholder: 'filename',
                    depends_on: {set_filename: true},
                    max_length: 50,
                },
                {
                    name: 'file_ext',
                    type: 'text',
                    placeholder: 'txt',
                    default: 'txt',
                    depends_on: {set_filename: true},
                    max_length: 8,
                },
            ],
            [{
                name: 'set_filename',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'forever',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    // {
    //     type: 'effect/core/project/node/run',
    //     icon: 'block',
    //     fields: [
    //         [{
    //             name: 'graph',
    //             type: 'menu',
    //             data: 'graph',
    //             data_filters: {is_on: true},
    //             default: 'default'
    //         }],
    //         [{
    //             name: 'node',
    //             type: 'menu',
    //             data: 'graph',
    //             data_field: 'nodes',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/graph/condition/stop',
    //     icon: 'circle-stop',
    // },

    {
        type: 'effect/core/sales/order/create',
        icon: 'basket-shopping',
        fields: [
            [{
                name: 'products',
                type: 'products',
                data: 'productShort',
            },],
            [
                {
                    // description: '',
                    placeholder: '',
                    name: 'status',
                    type: 'menu',
                    values: [
                        'created',
                        'active',
                        'finished',
                        'canceled',
                        'refunded',
                    ],
                    default: 'created'
                },
                {
                    name: 'amount',
                    type: 'text',
                    placeholder: true,
                }
            ],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'integration_var',
                type: 'text',
                default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {integration_by_var: true},
            }],
            [{
                name: 'products_by_var',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'products_var',
                type: 'textarea',
                depends_on: {products_by_var: true}
            }],
            [{
                name: 'set_title',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'title',
                type: 'text',
                placeholder: true,
                depends_on: {set_title: true}
            }],
            [{
                name: 'set_code',
                type: 'checkbox',
            }],
            [{
                name: 'code',
                type: 'text',
                placeholder: true,
                depends_on: {set_code: true}
            }],
            [{
                name: 'ignore_discount',
                type: 'checkbox',
            }],
            [{
                name: 'set_source',
                type: 'checkbox',
            }],
            [{
                name: 'source_id',
                type: 'text',
                default: '{var:utm.utm_id}',
                placeholder: '{utm:}',
                depends_on: {set_source: true}
            }],
            [{
                name: 'set_params',
                type: 'checkbox',
            }],
            [{
                name: 'params',
                type: 'params',
                description: '',
                depends_on: {set_params: true},
                localeRoot: 'graph.container',
            }],
            // [{
            //     name: 'run_event',
            //     type: 'checkbox',
            // },],
        ]
    },
    {
        type: 'effect/core/sales/order/status',
        // icon: 'basket-shopping-simple',
        icon: 'money-check-pen',
        fields: [
            [{
                name: 'order_id',
                type: 'textarea',
                placeholder: '{var:effect.order.id}',
                default: '{var:effect.order.id}',
            }],
            [{
                // description: '',
                name: 'status',
                type: 'textarea',
                depends_on: {set_by_var: true}
            }],
            [{
                placeholder: '',
                name: 'status',
                type: 'menu',
                values: [
                    'created',
                    'active',
                    'finished',
                    'canceled',
                    'refunded',
                ],
                default: 'finished',
                localeRoot: 'effect.core.sales.order.create',
                depends_on: {set_by_var: false}
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/core/sales/order/search',
        icon: 'magnifying-glass-dollar',
        fields: [
            [{
                name: 'order_id',
                type: 'textarea',
                placeholder: '{var:order_id}',
                depends_on: {by_products: false},
                // default: '{var:effect.order.id}',
            }],
            [{
                name: 'product_ids',
                type: 'tags',
                data: 'productShort',
                depends_on: {by_products: true},
                features: ['adding', 'creating']
            }],
            [{
                // description: '',
                name: 'statuses',
                type: 'tags',
                values: [
                    'created',
                    'active',
                    'finished',
                    'canceled',
                    'refunded',
                ],
                localized: true,
                depends_on: {by_products: true},
            }],
            [{
                name: 'by_products',
                type: 'checkbox',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    // {
    //     type: 'effect/core/sales/order/edit',
    //     icon: 'money-check-dollar-pen',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/sales/purchase/open',
    //     icon: 'money-check-dollar',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },

    {
        type: 'effect/core/sales/product/create',
        inherit: 'effect/core/sales/product/update',
        icon: 'bag-shopping',
        replace: {
            title: {
                name: 'title',
                type: 'text',
                placeholder: true,
                // description: '',
            },
            product_id: {
                name: 'product_id',
                type: 'unset',
            },
            is_on: {
                name: 'is_on',
                type: 'text',
                default: 'true',
            },
            price: {
                name: 'price',
                type: 'text',
                placeholder: '0',
                description: '',
            },
        }
    },
    {
        type: 'effect/core/sales/product/update',
        icon: 'bags-shopping',
        fields: [
            [{
                name: 'product_id',
                type: 'text',
                placeholder: '{var:product.id}',
                default: '{var:effect.product.id}',
                // description: '',
            }],
            [{
                name: 'title',
                type: 'text',
                // description: '',
            }],
            [
                {
                    name: 'price',
                    type: 'text',
                    description: '',
                },
                {
                    name: 'old_price',
                    type: 'text',
                    description: '',
                },
            ],
            [
                {
                    name: 'author_user_id',
                    type: 'text',
                },
                {
                    name: 'manager_user_id',
                    type: 'text',
                },
            ],
            [{
                name: 'is_on',
                type: 'text',
            }],
            [{
                name: 'photo_url',
                type: 'textarea',
            }],
            [{
                name: 'category_ids',
                type: 'tags',
                data: 'productCategory',
                features: ['adding', 'creating']
            }],
            [{
                name: 'description',
                type: 'textarea',
            }],
            [{
                name: 'original_desc',
                type: 'checkbox',
            }],
            [{
                name: 'set_sku',
                type: 'checkbox',
            }],
            [{
                name: 'sku',
                type: 'text',
                placeholder: true,
                description: '',
                depends_on: {set_sku: true}
            }],
        ]
    },

    {
        type: 'effect/core/sales/product/search',
        icon: 'nfc-magnifying-glass',
        fields: [
            [{
                name: 'product_id',
                type: 'textarea',
                placeholder: '{var:product_id}',
                depends_on: {by: 'id'},
                default: '{var:object.order.products_ids[0]}',
                // default: '{var:effect.order.products[0].id}',
            }],
            [{
                name: 'sku',
                type: 'textarea',
                placeholder: '{var:sku}',
                depends_on: {by: 'sku'},
            }],
            [{
                name: 'title',
                type: 'textarea',
                placeholder: '{var:title}',
                depends_on: {by: 'title'},
            }],
            [{
                name: 'by',
                type: 'menu',
                default: 'id',
                placeholder: '',
                values: ['id', 'sku', 'title'],
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/core/timer/delay/run', // effect/core/timer/schedule
        icon: 'timer',
        fields: [
            [{
                name: 'mode',
                description: '',
                placeholder: '',
                type: 'menu',
                default: 'delay',
                localized: true,
                values: [
                    'delay',
                    'tomorrow',
                    'calendar',
                    'seconds',
                    'field',
                    'var',
                ]
            }],
            [
                {
                    name: 'delay.days',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {mode: 'delay'},
                    wrapperClassName: 'margin-none',
                },
                {
                    name: 'delay.hours',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {mode: 'delay'},
                    wrapperClassName: 'margin-none',
                },
                {
                    name: 'delay.minutes',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {mode: 'delay'},
                    wrapperClassName: 'margin-none',
                },
            ],
            [{
                name: 'delay.desc',
                depends_on: {mode: 'delay'},
                type: 'description',
            }],
            [
                {
                    name: 'tomorrow.days',
                    type: 'text',
                    default: '1',
                    placeholder: '0',
                    depends_on: {mode: 'tomorrow'},
                    width: 12,
                },
                {
                    name: 'tomorrow.time',
                    type: 'time',
                    depends_on: {mode: 'tomorrow', tomorrow_by_var: false},
                    width: 12,
                },
                {
                    name: 'tomorrow.time_var',
                    type: 'text',
                    depends_on: {mode: 'tomorrow', tomorrow_by_var: true},
                    width: 12,
                },
            ],
            [{
                name: 'tomorrow_by_var',
                type: 'checkbox',
                depends_on: {mode: 'tomorrow'},
                wrapperClassName: 'margin-none',
            }],
            [
                {
                    name: 'calendar.date',
                    type: 'date',
                    label: '',
                    description: '',
                    depends_on: {mode: 'calendar'},
                    wrapperClassName: 'margin-none',
                },
                {
                    name: 'calendar.time',
                    type: 'time',
                    label: '',
                    description: '',
                    depends_on: {mode: 'calendar'},
                    wrapperClassName: 'margin-none',
                },
            ],
            [{
                name: 'calendar.timezone',
                type: 'description',
                depends_on: {mode: 'calendar'},
            }],
            [{
                name: 'seconds.value',
                type: 'text',
                // label: '',
                // description: '',
                placeholder: '0',
                depends_on: {mode: 'seconds'}
            }],
            [{
                name: 'field.code',
                type: 'menu',
                placeholder: null,
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'crm', data_type_admin: 'datetime'},
                depends_on: {mode: 'field'}
            }],
            [{
                name: 'var.value',
                type: 'textarea',
                placeholder: '{var:datetime}',
                features: ['vars'],
                depends_on: {mode: 'var'}
            }],
            [{
                name: 'offset.is_on',
                type: 'checkbox',
                depends_on: {mode: ['field', 'var']},
                wrapperClassName: 'form-section-toggle',
            }],
            [
                {
                    name: 'offset.vector',
                    type: 'menu',
                    description: '',
                    placeholder: '',
                    values: ['increase', 'decrease'],
                    default: 'increase', // TODO: not working :(
                    depends_on: {mode: ['field', 'var'], 'offset.is_on': true}
                },
                {
                    name: 'offset.days',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {mode: ['field', 'var'], 'offset.is_on': true}
                },
                {
                    name: 'offset.hours',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {mode: ['field', 'var'], 'offset.is_on': true}
                },
                {
                    name: 'offset.minutes',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {mode: ['field', 'var'], 'offset.is_on': true}
                },
            ],
            [{
                name: 'schedule.is_on',
                type: 'checkbox',
                depends_on: {mode: ['delay', 'calendar', 'field', 'var']},
                wrapperClassName: 'form-section-toggle',
            }],
            [
                {
                    name: 'schedule.since',
                    type: 'time',
                    label: null,
                    placeholder: '00:00:00',
                    description: '',
                    depends_on: {mode: ['delay', 'calendar', 'field', 'var'], 'schedule.is_on': true}
                },
                {
                    name: 'schedule.till',
                    type: 'time',
                    label: null,
                    placeholder: '23:59:59',
                    description: '',
                    depends_on: {mode: ['delay', 'calendar', 'field', 'var'], 'schedule.is_on': true}
                },
            ],
            [{
                name: 'schedule.days',
                type: 'tags',
                values: [1, 2, 3, 4, 5, 6, 0],
                localized: true,
                depends_on: {mode: ['delay', 'calendar', 'field', 'var'], 'schedule.is_on': true}
            }],
            [{
                name: 'set_title',
                type: 'checkbox',
                wrapperClassName: 'form-section-toggle',
            }],
            [{
                name: 'title',
                type: 'textarea',
                placeholder: true,
                depends_on: {set_title: true}
            }],
        ]
    },
    {
        type: 'effect/core/timer/state/delete',
        icon: 'trash-clock',
        fields: [
            [{
                name: 'filter_type',
                type: 'menu',
                values: ['id', 'title', 'event'],
                // placeholder: '',
                default: 'id',
            }],
            [{
                name: 'entity_ids',
                type: 'tags',
                data: 'nodes',
                data_filters: {type: ['timer', 'action']},
                data_mask: {container: {type: popularEffectsTypes.delay_run}},
                features: ['adding'],
                depends_on: {filter_type: 'id'},
            }],
            [{
                name: 'event_ids',
                type: 'tags',
                data: 'nodes',
                data_filters: {type: ['event'], 'iu.hidden': false},
                data_mask: {item: 'start'},
                features: ['adding'],
                depends_on: {filter_type: 'event'},
            }],
            [{
                name: 'title',
                type: 'tags',
                features: ['adding'],
                data: 'nodes',
                data_filters: {type: ['timer', 'action']},
                data_mask: {container: {type: popularEffectsTypes.delay_run, spec: 'title'}},
                depends_on: {by_regex: false, filter_type: 'title'},
            }],
            [{
                name: 'regex',
                type: 'textarea',
                placeholder: 'Timer #123',
                features: ['vars'],
                depends_on: {by_regex: true, filter_type: 'title'},
            }],
            [{
                name: 'by_regex',
                type: 'checkbox',
                default: false,
                depends_on: {filter_type: 'title'},
            }],
            [{
                name: 'delete_events',
                type: 'checkbox',
                default: false,
                depends_on: {filter_type: true},
            }],
            [{
                name: 'events_blocks',
                type: 'tags',
                data: 'nodes',
                data_filters: {type: ['event', 'message'], 'iu.hidden': false},
                data_mask: {item: 'button'},
                features: ['adding'],
                depends_on: {delete_events: true, filter_type: ['id', 'title']},
            }],
            [{
                name: 'is_current',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'process_ids',
                type: 'tags',
                default: ['{var:graph.item.id}'],
                data: 'graph',
                features: ['adding'],
                depends_on: {is_current: false},
            }],
        ]
    },
    {
        type: 'effect/core/timer/event/delete',
        icon: 'trash-can-undo',
        fields: [
            [{
                name: 'filter_type',
                type: 'menu',
                values: ['id', 'event'],
                // placeholder: '',
                default: 'event',
                localeRoot: "effect.core.timer.state.delete",
            }],
            [{
                name: 'events_blocks',
                type: 'tags',
                data: 'nodes',
                data_filters: {type: ['event', 'message'], 'iu.hidden': false},
                data_mask: {item: 'button'},
                features: ['adding'],
                depends_on: {filter_type: 'id'},
                localeRoot: "effect.core.timer.state.delete",
            }],
            [{
                name: 'event_ids',
                type: 'tags',
                data: 'nodes',
                data_filters: {type: ['event'], 'iu.hidden': false},
                data_mask: {item: 'start'},
                features: ['adding'],
                depends_on: {filter_type: 'event'},
                localeRoot: "effect.core.timer.state.delete",
            }],
            [{
                name: 'is_current',
                type: 'checkbox',
                default: true,
                localeRoot: "effect.core.timer.state.delete",
            }],
            [{
                name: 'process_ids',
                type: 'tags',
                default: ['{var:graph.item.id}'],
                data: 'graph',
                features: ['adding'],
                depends_on: {is_current: false},
                localeRoot: "effect.core.timer.state.delete",
            }],
        ]
    },
    {
        type: 'effect/core/timer/branching/schedule',
        icon: 'split',
        fields: [
            [{
                name: 'filters.conditions.0.type',
                type: 'hidden',
                default: 'list/member',
            }],
            [{
                name: 'filters.conditions.0.spec.codes',
                type: 'textarea',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'filters.conditions.0.spec.codes',
                type: 'tags',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'filters.conditions.1.type',
                type: 'hidden',
                default: 'list/member',
            }],
            [{
                name: 'filters.conditions.1.negative',
                type: 'hidden',
                default: true,
            }],
            [{
                name: 'filters.conditions.1.spec.codes',
                type: 'textarea',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'filters.conditions.1.spec.codes',
                type: 'tags',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'filters.conditions.2.type',
                type: 'hidden',
                default: 'user/type',
            }],
            [{
                name: 'filters.conditions.2.spec.types',
                type: 'textarea',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'filters.conditions.2.spec.types',
                type: 'tags',
                values: profile_types,
                localized: true,
                default: ['user'],
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'filters.mode',
                type: 'hidden',
                default: 'and',
            }],
            [{
                name: 'integration_ids',
                type: 'textarea',
                placeholder: '[{var:graph.integration.id}]',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'integration_ids',
                type: 'tags',
                data: 'integration',
                placeholder: true,
                data_filters: {'integration.platform': messengers},
                depends_on: {set_by_var: false}
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'set_timestamp',
                type: 'checkbox',
            }],
            [{
                name: 'timestamp',
                type: 'textarea',
                placeholder: '{calc:{date:|%s} + 60}',
                default: '{calc:{date:|%s} + 60}',
                depends_on: {set_timestamp: true}
            }],
            [{
                name: 'set_title',
                type: 'checkbox',
                localeRoot: 'effect.core.timer.delay.run',
            }],
            [{
                name: 'title',
                type: 'textarea',
                placeholder: true,
                depends_on: {set_title: true},
                localeRoot: 'effect.core.timer.delay.run',
            }],
            [{
                name: 'ignore_permissions',
                type: 'checkbox',
            }],
        ]
    },
    {
        type: 'effect/core/timer/branching/tariff',
        icon: 'money-bill-wave',
        system: true,
        fields: [
            [{
                name: 'mode',
                type: 'menu',
                placeholder: '',
                values: ['message', 'graph', 'page', 'template'],
                default: ['message'],
                localized: true,
            }],
        ]
    },

    {
        type: 'effect/integration/crm/getcourse/order',
        icon: 'basket-shopping-simple',
        fields: [
            [{
                name: 'integration_id',
                type: 'text',
                // default: '{var:graph.integration.id}',
                placeholder: '{var:graph.integration.id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot + '.synonym',
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'gc'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [
                {
                    name: 'user.first_name',
                    type: 'text',
                    default: '{user:first_name}',
                    description: '',
                    placeholder: true,
                },
                {
                    name: 'user.last_name',
                    type: 'text',
                    default: '{user:last_name}',
                    description: '',
                    placeholder: true,
                },
            ],
            [
                {
                    name: 'email',
                    type: 'text',
                    description: '',
                    placeholder: '{contact:email}',
                    default: '{contact:email}',
                },
                {
                    name: 'user.phone',
                    type: 'text',
                    description: '',
                    placeholder: '{contact:phone}',
                    default: '{contact:phone}',
                },
            ],
            [
                {
                    name: 'user.vk_user_id',
                    type: 'text',
                    placeholder: '{user:platform.id}',
                },
                {
                    name: 'user.vk_group_id',
                    type: 'text',
                    placeholder: '{var:platform.integration.id}',
                },
            ],
            [
                {
                    name: 'user.city',
                    type: 'text',
                    placeholder: true,
                    description: '',
                    depends_on: {'user.add_fields': true},
                },
                {
                    name: 'user.country',
                    type: 'text',
                    placeholder: true,
                    description: '',
                    depends_on: {'user.add_fields': true},
                },
            ],
            [{
                name: 'user.group_name',
                type: 'tags',
                features: ['adding'],
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'user.group_name_json',
                type: 'textarea',
                features: ['vars'],
                depends_on: {set_by_var: true},
            }],


            [
                {
                    name: 'deal.product_title',
                    type: 'text',
                    default: '{var:effect.order.title}',
                    placeholder: true,
                },
                {
                    name: 'deal.offer_code',
                    type: 'text',
                },
            ],
            [
                {
                    name: 'deal.deal_cost',
                    type: 'text',
                    default: '{var:effect.order.price}',
                    placeholder: true,
                },
                {
                    name: 'deal.manager_email',
                    type: 'text',
                    placeholder: '{author:contacts.email[0]}',
                },
            ],

            [{
                name: 'avoid_clones',
                type: 'checkbox',
            }],
            [{
                name: 'gc_user_id',
                type: 'textarea',
                // default: "{user:contacts [?type=='gc_id' && bot_id==`{var:graph.integration.id}`] => [0].id}",
                depends_on: {avoid_clones: true},
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'deal.add_params',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'deal_params',
                type: 'params',
                description: '',
                depends_on: {'deal.add_params': true},
                localePath: 'graph.container.form.params',
            }],
            [{
                name: 'deal.add_fields',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'deal_fields',
                localeRoot: 'fields',
                type: 'params',
                description: '',
                depends_on: {'deal.add_fields': true},
                localePath: 'graph.container.form.fields',
            }],
            [{
                name: 'user.add_fields',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'user_fields',
                localeRoot: 'fields',
                type: 'params',
                description: '',
                depends_on: {'user.add_fields': true},
                localePath: 'graph.container.form.fields',
            }],
        ]
    },
    {
        type: 'effect/integration/crm/getcourse/user',
        inherit: 'effect/integration/crm/getcourse/order',
        icon: 'id-card',
        replace: {
            'deal.add_fields': {
                name: 'deal.add_fields',
                type: 'unset',
            },
            deal_fields: {
                name: 'deal_fields',
                type: 'unset',
            },
            'deal.add_params': {
                name: 'deal.add_fields',
                type: 'unset',
            },
            deal_params: {
                name: 'deal_params',
                type: 'unset',
            },
            'deal.product_title': {
                name: 'deal.product_title',
                type: 'unset',
            },
            'deal.offer_code': {
                name: 'deal.offer_code',
                type: 'unset',
            },
            'deal.deal_cost': {
                name: 'deal.deal_cost',
                type: 'unset',
            },
            'deal.manager_email': {
                name: 'deal.manager_email',
                type: 'unset',
            },
        }
    },
    // {
    //     type: 'effect/integration/crm/getcourse/group',
    //     icon: 'users-viewfinder',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },

    {
        type: 'effect/integration/crm/sm/order',
        icon: 'school-circle-check',
        fields: [
            [{
                name: 'integration_var',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'sm'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'product_id',
                type: 'text',
                placeholder: true,
                features: ['vars'],
                depends_on: {set_by_var: true},
                localeRoot: 'effect.integration.crm.sm.order.synonym',
            }],
            [{
                name: 'product_id',
                type: 'menu',
                placeholder: true,
                default: null,
                // features: ['creating'],
                data: 'smProduct',
                data_spec_query: {integration_id: 'integration_id'},
                depends_on: {set_by_var: false},
            }],

            [
                {
                    name: 'summ',
                    type: 'text',
                    description: '',
                    width: 12,
                },
                {
                    name: 'status',
                    type: 'text',
                    description: '',
                    placeholder: '0',
                    depends_on: {set_by_var: true},
                    width: 12,
                },
                {
                    name: 'status',
                    type: 'menu',
                    description: '',
                    placeholder: '',
                    values: ['0', '1', '2'],
                    default: '0',
                    localized: true,
                    depends_on: {set_by_var: false},
                    width: 12,
                },
            ],
            [{
                name: 'external_number',
                type: 'text',
                placeholder: '{var:order_id}',
                default: '{var:effect.order.id}',
                features: ['vars'],
            }],

            [{
                name: 'show_client_fields',
                type: 'checkbox',
            }],
            [
                {
                    name: 'client_name',
                    placeholder: true,
                    default: '{user:first_name}',
                    type: 'text',
                    depends_on: {show_client_fields: true},
                },
                {
                    name: 'surname',
                    placeholder: true,
                    default: '{user:last_name}',
                    type: 'text',
                    depends_on: {show_client_fields: true},
                },
            ],
            [
                {
                    name: 'client_email',
                    placeholder: true,
                    default: '{contact:email}',
                    type: 'text',
                    depends_on: {show_client_fields: true},
                },
                {
                    name: 'client_phone',
                    placeholder: true,
                    default: '{contact:phone}',
                    type: 'text',
                    depends_on: {show_client_fields: true},
                },
            ],
            [
                {
                    name: 'tg_id',
                    type: 'text',
                    placeholder: true,
                    default: '{contact:tg_id}',
                    localeRoot: 'effect.integration.crm.sm.user',
                    depends_on: {show_client_fields: true},
                },
                {
                    name: 'vk_id',
                    type: 'text',
                    placeholder: true,
                    default: '{contact:vk_id}',
                    localeRoot: 'effect.integration.crm.sm.user',
                    depends_on: {show_client_fields: true},
                },
            ],

            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                default: 'payment_url',
                placeholder: 'payment_url',
                depends_on: {save_result: true}
            }],
            [{
                description: '',
                name: 'fields',
                type: 'fields',
                section: 'params',
            }],
        ]
    },

    {
        type: 'effect/integration/crm/sm/user',
        icon: 'school',
        fields: [
            [{
                name: 'integration_var',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'sm'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [
                {
                    description: '',
                    placeholder: true,
                    name: 'name',
                    default: '{user:first_name}',
                    type: 'text',
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'surname',
                    default: '{user:last_name}',
                    type: 'text',
                },
            ],
            [
                {
                    name: 'email',
                    placeholder: true,
                    default: '{contact:email}',
                    type: 'text',
                },
                {
                    name: 'phone',
                    placeholder: true,
                    default: '{contact:phone}',
                    type: 'text',
                },
            ],
            [
                {
                    name: 'tg_id',
                    placeholder: true,
                    default: '{contact:tg_id}',
                    type: 'text',
                },
                {
                    name: 'vk_id',
                    placeholder: true,
                    default: '{contact:vk_id}',
                    type: 'text',
                },
            ],
            [{
                name: 'update_on_exists',
                type: 'checkbox',
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                description: '',
                name: 'fields',
                type: 'fields',
                section: 'params',
            }],
        ]
    },
    {
        type: 'effect/integration/crm/sm/auth',
        icon: 'right-to-bracket',
        fields: [
            [{
                name: 'integration_var',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'sm'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'email',
                type: 'textarea',
                default: '{contact:email}',
                placeholder: '{var:sm_user_id}',
            }],
            [{
                name: 'path',
                type: 'textarea',
                default: '/training',
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'login_url',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    // {
    //     type: 'effect/core/timer/delay/reschedule',
    //     icon: 'reply-clock',
    //     inherit: 'effect/core/timer/delay/run',
    //     fields: [
    //         [{
    //             name: 'object_id',
    //             type: 'text',
    //             features: ['vars']
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/core/timer/delay/cancel',
    //     icon: 'alarm-exclamation',
    //     fields: [
    //         [{
    //             name: 'graph',
    //             type: 'menu',
    //             data: 'graph',
    //             data_filters: {is_on: true},
    //             default: 'default' // current graph
    //         }],
    //         [{
    //             name: 'object_id',
    //             type: 'text',
    //             features: ['vars']
    //         }],
    //     ]
    // },

    // {
    //     type: 'effect/integration/delivery/senler/group_add',
    //     icon: 'people-group',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/delivery/senler/group_remove',
    //     icon: 'person-circle-minus',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/delivery/senler/bot_add',
    //     icon: 'square-user',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/delivery/senler/bot_remove',
    //     icon: 'person-rays',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/delivery/smsc/send',
    //     icon: 'message-sms',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/delivery/smsc/code',
    //     icon: 'message-code',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },

    // {
    //     type: 'effect/integration/crm/amo/contact',
    //     icon: 'id-badge',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/crm/amo/deal',
    //     icon: 'badge-dollar',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/crm/amo/note',
    //     icon: 'note',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/table/google/find',
    //     icon: 'file-magnifying-glass',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/table/google/store',
    //     icon: 'table-list',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/payment/prodamus/product',
    //     icon: 'cart-shopping',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/payment/prodamus/club',
    //     icon: 'card-club',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/vk/photo/delete',
    //     icon: 'image-slash',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/vk/board/delete_comment',
    //     icon: 'comment-slash',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/vk/retarget/add',
    //     icon: 'arrows-down-to-people',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // },
    // {
    //     type: 'effect/integration/vk/retarget/delete',
    //     icon: 'users-rays',
    //     fields: [
    //         [{
    //             name: '',
    //             type: 'text',
    //         }],
    //     ]
    // }

    // math
    {
        type: 'effect/program/math/calc',
        icon: 'watch-calculator',
        fields: [
            [{
                name: 'text',
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/math/round',
        icon: 'value-absolute',
        fields: [
            [
                {
                    name: 'number',
                    type: 'text',
                    features: ['vars'],
                },
                {
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                },
            ],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/math/pow',
        icon: 'superscript',
        inherit: 'effect/program/math/round',
        replace: {
            value: {
                name: 'value',
                type: 'text',
                features: ['vars'],
            }
        }
    },
    {
        type: 'effect/program/math/abs',
        icon: 'value-absolute',
        fields: [
            [{
                name: 'number',
                type: 'text',
                features: ['vars'],
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/math/sqrt',
        icon: 'square-root-variable',
        inherit: 'effect/program/math/abs',
    },
    {
        type: 'effect/program/math/floor',
        icon: 'arrow-down-to-line',
        inherit: 'effect/program/math/abs',
    },
    {
        type: 'effect/program/math/ceil',
        icon: 'arrow-up-to-line',
        inherit: 'effect/program/math/abs',
    },
    {
        type: 'effect/program/math/digital/sum',
        icon: 'sigma',
        inherit: 'effect/program/math/abs',
    },
    {
        type: 'effect/program/math/digital/root',
        icon: 'circle-7',
        inherit: 'effect/program/math/abs',
    },
    {
        type: 'effect/program/math/numerology/root',
        icon: 'arrow-up-1-9',
        inherit: 'effect/program/math/round',
        replace: {
            value: {
                name: 'value',
                type: 'text',
                features: ['vars'],
                default: '9',
            }
        }
    },
    {
        type: 'effect/program/math/numerology/letters',
        icon: 'arrow-up-a-z',
        fields: [
            [{
                name: 'text',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'algorithm',
                type: 'textarea',
                default: '{"A": 1, "B": 2, "C": 3, "D": 4, "E": 5, "F": 6, "G": 7, "H": 8, "I": 9, "J": 1, "K": 2, "L": 3, "M": 4, "N": 5, "O": 6, "P": 7, "Q": 8, "R": 9, "S": 1, "T": 2, "U": 3, "V": 4, "W": 5, "X": 6, "Y": 7, "Z": 8}',
                features: ['vars'],
            }],
            [{
                name: 'clean_spaces',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'clean_other',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'cs',
                type: 'checkbox',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    // text
    {
        type: 'effect/program/text/len',
        icon: 'clipboard-question', // 'message-question',
        fields: [
            [{
                name: 'text',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/text/len/lines',
        icon: 'line-height',
        inherit: 'effect/program/text/len',
    },
    {
        type: 'effect/program/text/strip',
        icon: 'text-size',
        inherit: 'effect/program/text/len',
    },
    {
        type: 'effect/program/text/case',
        icon: 'font-case',
        inherit: 'effect/program/text/len',
        fields: [
            [
                {
                    name: 'text',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                },
                {
                    name: 'mode',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'upper',
                        'lover',
                        'capitalize',
                        'sentences',
                        'words',
                    ],
                    default: 'upper',
                }
            ],
            [
                {
                    name: 'save_result',
                    type: 'checkbox',
                    description: '',
                    default: false,
                },
                {
                    name: 'result_var',
                    label: '',
                    description: '',
                    type: 'text',
                    depends_on: {save_result: true}
                }
            ],
        ]
    },

    {
        type: 'effect/program/text/replace',
        icon: 'marker',
        fields: [
            [{
                name: 'text',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [
                {
                    name: 'search',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                },
                {
                    name: 'value',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                },
            ],
            [{
                name: 'cs',
                description: '',
                type: 'switcher',
                default: false,
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'switcher',
                default: false,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
            [{
                name: 'match_as_result',
                type: 'unset',
            }],
        ]
    },
    {
        type: 'effect/program/text/search/regexp',
        icon: 'file-magnifying-glass',
        inherit: 'effect/program/text/replace',
        replace: {
            value: {
                name: 'value',
                type: 'unset',
                default: '{var:object.text}'
            },
            search: {
                name: 'search',
                placeholder: true,
                type: 'text',
                features: ['vars'],
            },
            match_as_result: {
                name: 'match_as_result',
                type: 'checkbox',
                default: true,
                depends_on: {save_result: true},
            }
        }
    },
    {
        type: 'effect/program/text/replace/regexp',
        icon: 'pen-ruler',
        fields: [
            [{
                name: 'text',
                placeholder: '{var:object.text}',
                description: '',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.text.replace',
            }],
            [{
                name: 'search',
                description: '',
                placeholder: "Some (\\d+|regexp) Expr([a-z]+)\\b",
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'value',
                description: '',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.text.replace',
            },
            ],
            [{
                name: 'cs',
                description: '',
                type: 'switcher',
                localeRoot: 'effect.program.text.replace',
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'switcher',
                localeRoot: 'effect.program.text.replace',
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: 'effect.program.text.replace',
            }],
        ]
    },

    {
        type: 'effect/program/text/slice',
        icon: 'file-word',
        fields: [
            [{
                name: 'text',
                placeholder: '{var:text}',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.text.len',
            }],
            [
                {
                    name: 'start',
                    placeholder: '0',
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                },
                {
                    name: 'end',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                    depends_on: {by_count: false},
                },
                {
                    name: 'count',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                    depends_on: {by_count: true},
                },
            ],
            [{
                name: 'by_count',
                description: '',
                type: 'switcher',
                default: true,
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'switcher',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                // label: '',
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/program/text/split',
        icon: 'file-dashed-line',
        fields: [
            [{
                name: 'text',
                placeholder: '{var:text}',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.text.len',
            }],
            [{
                name: 'count',
                placeholder: '0',
                type: 'text',
                features: ['vars'],
            }],
            [{
                name: 'smart_mode',
                description: '',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                // label: '',
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/text/extract/email',
        icon: 'at',
        inherit: 'effect/program/text/len',
    },
    {
        type: 'effect/program/text/extract/phone',
        icon: 'phone-office',
        inherit: 'effect/program/text/len',
    },
    {
        type: 'effect/program/text/password',
        icon: 'key-skeleton',
        fields: [
            [{
                name: 'length',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                default: '10',
            }],
            [{
                name: 'only_digits',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'to_lover',
                type: 'checkbox',
                default: false,
                depends_on: {only_digits: false}
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/text/convert',
        icon: 'message-exclamation',
        fields: [
            [{
                name: 'path',
                placeholder: 'path.to.value',
                type: 'textarea',
                features: ['vars'],
            }],
        ]
    },
    {
        type: 'effect/program/text/urlencode',
        icon: 'ampersand',
        fields: [
            [{
                name: 'text',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [{
                name: 'mode',
                type: 'menu',
                values: [
                    'encode',
                    'decode',
                ],
                default: 'encode',
                placeholder: '',
            }],
            [{
                name: 'decode_plus',
                type: 'checkbox',
                default: false,
                depends_on: {mode: 'decode'}
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/text/base64',
        icon: 'baseball-bat-ball',
        inherit: 'effect/program/text/urlencode',
        replace: {
            decode_plus: {
                name: 'decode_plus',
                type: 'unset',
            },
            mode: {
                name: 'mode',
                type: 'menu',
                values: [
                    'encode',
                    'decode',
                ],
                default: 'encode',
                placeholder: '',
                description: '',
                localeRoot: 'effect.program.text.urlencode',
            }
        }
    },
    {
        type: 'effect/program/text/hash',
        icon: 'hashtag-lock',
        fields: [
            [{
                name: 'text',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [{
                name: 'mode',
                type: 'menu',
                values: [
                    'simple',
                    'md5',
                    'sha1',
                    'sha256',
                    'sha512',
                ],
                default: 'simple',
                placeholder: '',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    // arrays
    {
        type: 'effect/program/array/create',
        icon: 'layer-group',
        fields: [
            [{
                name: 'text',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                // max_length: 1000,
            }],
            [
                {
                    name: 'divider_type',
                    placeholder: '',
                    type: 'menu',
                    values: ['line_break', 'paragraph', 'comma', 'semicolon', 'dot', 'custom'],
                    default: 'line_break'
                }
            ],
            [
                {
                    name: 'custom_divider',
                    type: 'text',
                    features: ['vars'],
                    depends_on: {divider_type: 'custom'}
                },
            ],
            [{
                name: 'strip',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/array/convert',
        inherit: 'effect/program/array/create',
        icon: 'indent',
        replace: {
            text: {
                name: 'array',
                type: 'textarea',
                features: ['vars'],
            },
            result_var: {
                name: 'result_var',
                type: 'text',
                placeholder: 'text',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            },
            strip: {
                name: 'strip',
                type: 'unset',
            }
        },
    },
    {
        type: 'effect/program/array/from/object',
        icon: 'objects-column',
        fields: [
            [
                {
                    name: 'obj',
                    placeholder: '{var:object}',
                    type: 'textarea',
                    features: ['vars'],
                    depends_on: {from_orig: false},
                    width: 12,
                },
                {
                    name: 'path',
                    placeholder: 'path.to.object',
                    type: 'textarea',
                    features: ['vars'],
                    depends_on: {from_orig: true},
                    width: 12,
                },
                {
                    name: 'source',
                    placeholder: '',
                    type: 'menu',
                    values: ['keys', 'values', 'items'],
                    default: 'values',
                    localized: true,
                    width: 12,
                }
            ],
            [{
                name: 'from_orig',
                type: 'checkbox',
                default: false,
                localeRoot: 'effect.program.array.search',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                localeRoot: commonLocaleRoot,
                depends_on: {save_result: true}
            }],
        ]
    },
    {
        type: 'effect/program/array/handle',
        icon: 'grid-dividers',
        fields: [
            [
                {
                    name: 'array',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                    width: 12,
                },
                {
                    name: 'array_var',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                    width: 12,
                },
            ],
            [{
                name: 'format',
                placeholder: '{name}: {value}',
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                name: 'number_param',
                type: 'text',
                default: 'num',
                features: ['vars'],
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
                depends_on: {to_orig: false},
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true, to_orig: false},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/item',
        icon: 'hand',
        fields: [
            [
                {
                    name: 'array',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                },
                {
                    name: 'index',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                },
            ],
            [{
                name: 'save_result',
                description: '',
                type: 'switcher',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/search',
        icon: 'filter-list',
        fields: [
            [
                {
                    name: 'array',
                    placeholder: '{var:array}',
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                    depends_on: {from_orig: false},
                    width: 12,
                },
                {
                    name: 'array_var',
                    placeholder: 'variables.array',
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                    depends_on: {from_orig: true},
                    width: 12,
                },
                {
                    name: 'path',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    width: 12,
                },
            ],
            [
                {
                    description: '',
                    placeholder: '',
                    wrapperClassName: 'margin-none',
                    name: 'data_type',
                    type: 'menu',
                    values: [
                        "text",
                        "float",
                        "bool",
                    ],
                    default: 'text',
                    width: 9,
                },
                {
                    // label: '',
                    description: '',
                    placeholder: '',
                    wrapperClassName: 'margin-none',
                    name: 'method_number',
                    type: 'menu',
                    values: [ // ==, <, >, <=, >=, !=
                        'equal',
                        'more_equal',
                        'less_equal',
                        'more',
                        'less',
                        'not_equal'
                    ],
                    default: 'equal',
                    depends_on: {data_type: 'float'},
                    width: 15,
                },
                {
                    // label: '',
                    description: '',
                    placeholder: '',
                    wrapperClassName: 'margin-none',
                    name: 'method_text',
                    type: 'menu',
                    values: [
                        'equal',
                        'contains',
                        'starts',
                        'ends',
                        'regexp',
                        'regexp_inverted',
                        'phone',
                        'email',
                        'number',
                        'int',
                    ],
                    default: 'equal',
                    depends_on: {data_type: 'text'},
                    width: 15,
                },
                {
                    description: '',
                    wrapperClassName: 'margin-none',
                    placeholder: 'false',
                    name: 'bool_value',
                    type: 'text',
                    depends_on: {data_type: 'bool'},
                    width: 15,
                }
            ],
            [{
                name: 'data_type_desc',
                type: 'description',
            }],
            [{
                name: 'values',
                type: 'tags',
                features: ['adding'],
                depends_on: {data_type: 'text', by_json: false},
            }],
            [{
                name: 'json_values',
                placeholder: '{var:array}',
                type: 'textarea',
                features: ['vars'],
                depends_on: {data_type: 'text', by_json: true},
            }],
            [{
                name: 'value',
                placeholder: '{var:number}',
                type: 'text',
                features: ['vars'],
                depends_on: {data_type: 'float'},
            }],
            [{
                placeholder: '',
                name: 'result_mode',
                type: 'menu',
                values: [
                    'values',
                    'indexes',
                    'value',
                    'index',
                    'count',
                    'report',
                ],
                default: 'values',
            }],
            [{
                name: 'cs',
                tooltip: false,
                type: 'checkbox',
                depends_on: {data_type: 'text', method_text: ['equal', 'contains', 'regexp', 'starts', 'ends']},
                default: false,
            }],
            [{
                name: 'by_json',
                type: 'checkbox',
                tooltip: false,
                default: false,
                depends_on: {data_type: 'text', method_text: ['equal', 'contains', 'regexp', 'starts', 'ends']},
                localeRoot: 'condition.core.var.text.match',
            }],
            [{
                name: 'negative',
                type: 'checkbox',
            }],
            [{
                name: 'from_orig',
                type: 'checkbox',
                default: true,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/extend',
        icon: 'layer-plus',
        fields: [
            [
                {
                    name: 'array',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                    depends_on: {to_orig: false},
                    width: 12,
                },
                {
                    name: 'array_var',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                    depends_on: {to_orig: true},
                    width: 12,
                },
                {
                    name: 'new_array',
                    type: 'textarea',
                    features: ['vars'],
                    width: 12,
                },
            ],
            [{
                name: 'to_orig',
                type: 'checkbox',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
                depends_on: {to_orig: false},
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true, to_orig: false},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/remove',
        icon: 'layer-minus',
        fields: [
            [{
                name: 'array',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                depends_on: {to_orig: false},
                localeRoot: 'effect.program.array.unique',
                width: 12,
            }, {
                name: 'array_var',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.unique',
                depends_on: {to_orig: true},
                width: 12,
            }, {
                name: 'value',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                width: 12,
            }],
            [{
                name: 'to_number',
                type: 'checkbox',
                depends_on: {by_index: false},
            }],
            [{
                name: 'by_index',
                type: 'checkbox',
                depends_on: {to_number: false},
            }],
            [{
                name: 'to_orig',
                type: 'checkbox',
                localeRoot: 'effect.program.array.intersect',
            }],
        ]
    },

    {
        type: 'effect/program/array/intersect',
        inherit: 'effect/program/array/extend',
        icon: 'object-intersect',
        replace: {
            array: {
                name: 'array',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                depends_on: {to_orig: false},
                width: 24,
            },
            array_var: {
                name: 'array_var',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.unique',
                depends_on: {to_orig: true},
                width: 24,
            },
            new_array: {
                name: 'second_array',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                width: 24,
            },
            to_orig: {
                name: 'to_orig',
                type: 'checkbox',
            },
        }
    },
    {
        type: 'effect/program/array/divergence',
        inherit: 'effect/program/array/extend',
        icon: 'object-exclude',
        replace: {
            array: {
                name: 'array',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                depends_on: {to_orig: false},
                localeRoot: 'effect.program.array.intersect',
                width: 24,
            },
            array_var: {
                name: 'array_var',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.unique',
                depends_on: {to_orig: true},
                width: 24,
            },
            new_array: {
                name: 'second_array',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.intersect',
                width: 24,
            },
            to_orig: {
                name: 'to_orig',
                type: 'checkbox',
                localeRoot: 'effect.program.array.intersect',
            },
        }
    },
    {
        type: 'effect/program/array/concatenate',
        inherit: 'effect/program/array/unique',
        icon: 'object-union',
        replace: {
            array: {
                name: 'array',
                placeholder: '[[1,2,3],[4,5,6]]',
                type: 'textarea',
                features: ['vars'],
            },
            array_var: {
                name: 'array_var',
                type: 'unset',
            },
            save_result: {
                name: 'save_result',
                type: 'switcher',
                default: false,
            },
        }
    },
    {
        type: 'effect/program/array/slice',
        icon: 'cake-slice',
        fields: [
            [{
                name: 'array',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.unique',
            }],
            [
                {
                    name: 'start',
                    placeholder: '0',
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                },
                {
                    name: 'end',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                    depends_on: {by_count: false},
                },
                {
                    name: 'count',
                    placeholder: true,
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                    depends_on: {by_count: true},
                },
            ],
            [{
                name: 'by_count',
                description: '',
                type: 'switcher',
                default: false,
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'switcher',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                // label: '',
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/unique',
        icon: 'alicorn',
        fields: [
            [
                {
                    name: 'array',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                },
                {
                    name: 'array_var',
                    placeholder: 'array.var',
                    type: 'textarea',
                    features: ['vars'],
                },
            ],
            [{
                name: 'save_result',
                type: 'checkbox',
                tooltip: '',
                depends_on: {array_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true, array_var: false},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/count',
        inherit: 'effect/program/array/unique',
        icon: 'square-question',
        replace: {
            save_result: {
                name: 'save_result',
                type: 'checkbox',
                tooltip: '',
                localeRoot: commonLocaleRoot,
            },
            result_var: {
                name: 'result_var',
                type: 'text',
                placeholder: 'count',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }
        }
    },
    {
        type: 'effect/program/array/shuffle',
        inherit: 'effect/program/array/unique',
        icon: 'shuffle',
    },
    {
        type: 'effect/program/array/sort',
        icon: 'arrow-down-square-triangle',
        fields: [
            [
                {
                    name: 'array',
                    placeholder: true,
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.unique',
                },
                {
                    name: 'array_var',
                    placeholder: 'array.var',
                    type: 'textarea',
                    features: ['vars'],
                },
            ],
            [{
                name: 'path',
                type: 'textarea',
                placeholder: 'inner.path.to.value',
                depends_on: {objects: true},
            }],
            [{
                name: 'objects',
                type: 'checkbox',
            }],
            [{
                name: 'reverse',
                tooltip: '',
                default: true,
                type: 'checkbox',
            }],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                depends_on: {array_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true, array_var: false},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/program/array/chunks',
        icon: 'game-board-simple',
        fields: [
            [{
                name: 'array',
                placeholder: '{var:array.var}',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.unique',
            }, {
                name: 'array_var',
                placeholder: 'array.var',
                type: 'textarea',
                localeRoot: 'effect.program.array.unique',
                features: ['vars'],
            }],
            [
                {
                    name: 'max_columns',
                    placeholder: '0',
                    default: '5',
                    type: 'text',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.buttons',
                    width: 12,
                },
                {
                    name: 'max_rows',
                    type: 'text',
                    features: ['vars'],
                    localeRoot: 'effect.program.array.buttons',
                    width: 12,
                },
            ],
            [{
                name: 'to_orig',
                type: 'checkbox',
                localeRoot: 'effect.program.array.intersect',
                depends_on: {save_result: false},
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'checkbox',
                default: false,
                depends_on: {to_orig: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                // label: '',
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true, to_orig: false},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/array/buttons',
        icon: 'grid-horizontal',
        fields: [
            [{
                name: 'array',
                placeholder: '{var:array.var}',
                type: 'textarea',
                features: ['vars'],
                localeRoot: 'effect.program.array.unique',
            }, {
                name: 'array_var',
                placeholder: 'array.var',
                type: 'textarea',
                localeRoot: 'effect.program.array.unique',
                features: ['vars'],
            }],
            [
                {
                    name: 'max_columns',
                    placeholder: '4',
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                },
                {
                    name: 'max_rows',
                    type: 'text',
                    features: ['vars'],
                    width: 12,
                },
            ],
            [{
                name: 'from_objects',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'object_path',
                placeholder: 'path.to.text.value',
                type: 'textarea',
                features: ['vars'],
                depends_on: {from_objects: true},
            }],
            [{
                name: 'save_result',
                description: '',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                // label: '',
                name: 'result_var',
                type: 'text',
                placeholder: 'array',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    // time
    {
        type: 'effect/program/time/shift',
        icon: 'calendar-plus',
        fields: [
            [{
                name: 'value',
                type: 'textarea',
                // placeholder: '{var:datetime}',
                features: ['vars'],
            }],
            [
                {
                    name: 'direction',
                    type: 'textarea',
                    placeholder: '+',
                    default: '+',
                    features: ['vars'],
                },
                {
                    name: 'days',
                    type: 'textarea',
                    placeholder: '0',
                    features: ['vars'],
                },
            ],
            [{
                name: 'expand_all',
                type: 'checkbox',
            }],
            [
                {
                    name: 'hours',
                    type: 'textarea',
                    placeholder: '0',
                    description: '',
                    features: ['vars'],
                    depends_on: {expand_all: true},
                },
                {
                    name: 'minutes',
                    type: 'textarea',
                    placeholder: '0',
                    description: '',
                    features: ['vars'],
                    depends_on: {expand_all: true},
                },
            ],
            [
                {
                    name: 'months',
                    type: 'textarea',
                    placeholder: '0',
                    description: '',
                    features: ['vars'],
                    depends_on: {expand_all: true},
                },
                {
                    name: 'years',
                    type: 'textarea',
                    placeholder: '0',
                    description: '',
                    features: ['vars'],
                    depends_on: {expand_all: true},
                },
            ],
            [{
                name: 'save_result',
                tooltip: '',
                type: 'checkbox',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'datetime',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    // api
    {
        type: 'effect/program/api/request/send',
        icon: 'square-arrow-up-right',
        fields: [
            [{
                name: 'url',
                placeholder: true,
                type: 'textarea',
                features: ['vars'],
            }],
            [{
                description: '',
                placeholder: '', // turn off menu clear control
                name: 'method',
                type: 'menu',
                values: [
                    'GET',
                    'GET-PHP',
                    'POST-JSON',
                    'POST',
                    'POST-PHP',
                    'PUT',
                    'PATCH',
                    'DELETE',
                ],
                default: 'POST-JSON',
            }, {
                description: '',
                placeholder: '', // turn off menu clear control
                name: 'response_method',
                type: 'menu',
                values: [
                    'text',
                    'json',
                    'binary',
                    'code',
                    'url',
                ],
                default: 'text',
            }],
            [{
                name: 'add_headers',
                type: 'checkbox',
            }],
            [{
                description: '',
                name: 'headers',
                type: 'params',
                depends_on: {add_headers: true},
            }],
            [{
                name: 'add_parameters',
                type: 'checkbox',
            }],
            [{
                description: '',
                name: 'parameters',
                type: 'params',
                // localeRoot: 'graph.container',
                localePath: 'graph.container.form.params',
                depends_on: {add_parameters: true}
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
            }],
            [{
                name: 'params',
                type: 'code',
                depends_on: {add_params: true}
            }],
            [{
                name: 'deep_merge',
                type: 'checkbox',
                depends_on: {add_params: true},
            }],
            [{
                name: 'set_request_delay',
                type: 'checkbox',
            }],
            [{
                name: 'request_delay_ms',
                type: 'text',
                depends_on: {set_request_delay: true}
            }],
            [{
                name: 'follow_redirects',
                type: 'checkbox',
                depends_on: {method: ['GET', 'POST', 'POST-PHP', 'GET-PHP']},
            }],
            [{
                name: 'vpn',
                type: 'checkbox',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true}
            }],
        ]
    },

    {
        type: 'effect/program/api/file/download',
        icon: 'download',
        fields: [
            [{
                name: 'url',
                type: 'textarea',
                features: ['vars'],
                placeholder: '{var:file_url}',
                default: '{var:result.data[0].url}',
                // max_length: 1000,
            }],
            [{
                name: 'set_lifetime',
                type: 'checkbox',
                default: false,
                localeRoot: 'effect.core.chat.file.download',
            }],
            [{
                name: 'lifetime',
                type: 'textarea',
                default: '10',
                features: ['vars'],
                depends_on: {set_lifetime: true},
                localeRoot: 'effect.core.chat.file.download',
            }],
            [{
                name: 'add_headers',
                type: 'checkbox',
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                description: '',
                name: 'headers',
                type: 'params',
                depends_on: {add_headers: true},
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'follow_redirects',
                type: 'checkbox',
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'vpn',
                type: 'checkbox',
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'result',
                depends_on: {save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/api/code/run',
        icon: 'code',
        // system: true,
        fields: [
            [{
                type: 'ai',
                name: 'generate',
                label: 'Generate',
                related: 'logic',
                btnType: 'text',
                description: '',
                className: 'btn-bordered padding-none-horizontal',
                prompt: 'Act ONLY as native JavaScript code generator. You not support DOM functions. Any additional libraries not supported to - only pure JS. Any necessary explanations write as a code comments! You working inside function body. You have access to lot of context vars in the `vars` prop object',
                wrapperClassName: 'margin-bottom-xs',
                depends_on: {replace_context: false},
            }],
            [{
                name: 'logic',
                type: 'code',
                label: '',
                placeholder: 'var result = true;\nreturn result',
                default: 'var result = True;\nreturn result',
                features: ['vars'],
                depends_on: {replace_context: false},
            }],
            [{
                name: 'logic_context',
                type: 'code',
                placeholder: 'vars["res"] = true',
                default: 'vars["res"] = true',
                features: ['vars'],
                depends_on: {replace_context: true},
            }],

            [{
                name: 'add_functions',
                type: 'checkbox',
            }],
            [{
                name: 'functions',
                placeholder: 'function myFunc() { return true; }',
                type: 'code',
                depends_on: {add_functions: true}
            }],
            [{
                name: 'replace_context',
                type: 'checkbox',
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                depends_on: {replace_context: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {replace_context: false, save_result: true},
                localeRoot: commonLocaleRoot,
            }],
        ]
    },

    {
        type: 'effect/program/api/request/platform',
        icon: 'webhook',
        fields: [
            [{
                name: 'context.integration',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {integration_by_var: true},
                localeRoot: 'effect.program.api.request.platform.synonym',
            }],
            [{
                name: 'context.integration',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': platforms},
                depends_on: {integration_by_var: false},
            }],
            [{
                name: 'method',
                placeholder: true,
                type: 'text',
                features: ['vars'],
                width: 24,
            }],
            [{
                description: '',
                name: 'parameters',
                type: 'params',
                localePath: 'graph.container.form.params',
            }],
            [{
                name: 'add_params',
                type: 'checkbox',
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'params',
                type: 'textarea',
                depends_on: {add_params: true},
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'set_request_method',
                type: 'checkbox',
            }],
            [{
                name: 'request_method',
                type: 'menu',
                values: [
                    'GET',
                    'GET-PHP',
                    'POST-JSON',
                    'POST',
                    'POST-PHP',
                    'PUT',
                    'PATCH',
                    'DELETE',
                ],
                depends_on: {set_request_method: true},
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: 'effect.program.api.request.send',
            }],
        ]
    },
    {
        type: 'effect/integration/tg/api/request',
        inherit: 'effect/program/api/request/platform',
        icon: 'paper-plane',
        // brandIcon: 'telegram',
        replace: {
            method: {
                name: 'method',
                placeholder: true,
                type: 'text',
                features: ['vars'],
                width: 24,
            }
        }
    },
    {
        type: 'effect/integration/vk/api/request',
        inherit: 'effect/program/api/request/platform',
        icon: 'message-code',
        // brandIcon: 'vk',
        replace: {
            method: {
                name: 'method',
                placeholder: true,
                type: 'text',
                features: ['vars'],
                width: 24,
            }
        }
    },
    {
        type: 'effect/integration/vk/api/ads',
        inherit: 'effect/program/api/request/platform',
        icon: 'message-code',
        // system: true,
        // brandIcon: 'vk',
        replace: {
            method: {
                name: 'method',
                placeholder: true,
                type: 'text',
                features: ['vars'],
            },
            request_method: {
                placeholder: '', // turn off menu clear control
                name: 'request_method',
                type: 'menu',
                values: [
                    'GET',
                    'POST-JSON',
                    'PUT',
                    'PATCH',
                    'DELETE',
                ],
                default: 'POST-JSON',
            },
            integration_by_var: {
                name: 'integration_by_var',
                type: 'hidden',
                default: true,
            }
        }
    },
    {
        type: 'effect/integration/vk/photo/ads',
        icon: 'images',
        fields: [
            [{
                name: 'integration_var',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {integration_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'vku'},
                depends_on: {integration_by_var: false},
            }],
            [{
                name: 'ad_format',
                placeholder: true,
                type: 'text',
                depends_on: {by_url: true},
            }],
            [{
                name: 'ad_format',
                type: 'menu',
                default: '11',
                placeholder: '',
                values: ['1', '2', '4', '7', '11'],
                depends_on: {by_url: false},
            }],
            [{
                name: 'photo_url',
                type: 'photo',
                label: '',
                description: '',
                depends_on: {by_url: false},
                limit: 1,
            }],
            [{
                name: 'by_url',
                type: 'checkbox',
                localeRoot: 'effect.integration.vk.story.upload',
            }],
            [{
                name: 'photo_url_var',
                type: 'textarea',
                features: ['vars'],
                depends_on: {by_url: true},
                localeRoot: 'effect.integration.vk.story.upload',
            }],
            [{
                name: 'integration_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'save_result',
                type: 'checkbox',
                default: false,
                localeRoot: 'effect.program.api.request.send',
            }],
            [{
                name: 'result_var',
                label: '',
                type: 'text',
                placeholder: 'res',
                depends_on: {save_result: true},
                localeRoot: 'effect.program.api.request.send',
            }],
        ]
    },
    {
        type: 'effect/integration/delivery/senler/subscribe',
        icon: 'people-group',
        fields: [
            [{
                name: 'context.integration',
                type: 'text',
                placeholder: '{var:integration_id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot + '.synonym',
            }],
            [{
                name: 'context.integration',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'senler'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'subscription_id',
                type: 'text',
                placeholder: true,
                features: ['vars'],
                depends_on: {set_by_var: true},
                localeRoot: 'effect.integration.delivery.senler.subscribe.synonym',
            }],
            [{
                name: 'subscription_id',
                type: 'menu',
                placeholder: true,
                default: null,
                features: ['creating'],
                data: 'senlerGroup',
                data_spec_query: {integration_id: 'context.integration'},
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'vk_user_id',
                type: 'text',
                features: ['vars'],
                default: '{user:platform.id}',
                placeholder: '{contact:vk_id}',
            }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'utms',
                type: 'fields',
                section: 'params',
            }],
        ]
    },
    {
        type: 'effect/integration/delivery/senler/unsubscribe',
        inherit: 'effect/integration/delivery/senler/subscribe',
        icon: 'person-circle-minus',
        replace: {
            utms: {
                name: 'utms',
                type: 'unset',
            }
        }
    },

    {
        type: 'effect/core/var/project/info',
        icon: 'square-info',
        fields: [
            [
                {
                    name: 'result_var',
                    type: 'text',
                    placeholder: 'res',
                    default: 'project',
                    localeRoot: commonLocaleRoot,
                }
            ]
        ]
    },

    {
        type: 'effect/program/app/project/list',
        icon: 'square-list',
        system: true,
        fields: [
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'res',
                default: 'projects',
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'is_shared',
                type: 'switcher',
            }],
        ]
    },

    {
        type: 'effect/program/app/template/install',
        icon: 'book-heart',
        fields: [
            [{
                name: 'template_id_var',
                type: 'unset',
                placeholder: true,
                features: ['vars'],
            }],
            [{
                name: 'template_alias',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            }],
            [{
                name: 'project_id_var',
                type: 'textarea',
                system: true,
                placeholder: '{var:graph.project.id}',
                features: ['vars'],
            }],
            [{
                name: 'title',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            }],
            [{
                name: 'periods',
                type: 'text',
                features: ['vars'],
            }],

            [{
                name: 'add_fields',
                type: 'checkbox',
                localeRoot: 'graph.container',
            }],
            [{
                name: 'fields',
                type: 'params',
                section: 'params',
                description: '',
                localeRoot: 'graph.container',
                depends_on: {add_fields: true},
            }],

            [{
                name: 'add_params',
                type: 'checkbox',
                system: true,
                localeRoot: 'graph.container',
            }],
            [{
                name: 'params',
                type: 'params',
                description: '',
                system: true,
                localeRoot: 'graph.container',
                depends_on: {add_params: true}
            }],
        ]
    },
    {
        type: 'effect/program/app/template/edit',
        inherit: 'effect/program/app/template/install',
        icon: 'book-spells',
        replace: {
            template_alias: {
                name: 'template_alias',
                type: 'unset',
            },
            template_id_var: {
                name: 'template_id_var',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            },
            project_id_var: {
                name: 'project_id_var',
                type: 'textarea',
                system: true,
                placeholder: '{var:graph.project.id}',
                features: ['vars'],
            }
        },
    },

    {
        type: 'effect/program/app/integration/install',
        icon: 'plug',
        fields: [
            [{
                name: 'project_id_var',
                type: 'textarea',
                system: true,
                placeholder: true,
                features: ['vars'],
                // description: '',
                localeRoot: 'effect.program.app.project.get',
            }],
            [{
                name: 'platform',
                type: 'textarea',
                // description: '',
                placeholder: '{var:platform.short_name}',
                features: ['vars'],
            }],
            [{
                name: 'token',
                type: 'textarea',
                // description: '',
                // placeholder: true,
                features: ['vars'],
            }],
            [{
                name: 'unique_hash',
                type: 'text',
                placeholder: true,
                features: ['vars'],
            }],
        ]
    },
    {
        type: 'effect/program/app/integration/connect',
        icon: 'outlet',
        fields: [
            [{
                name: 'integration_id_var',
                type: 'textarea',
                // description: '',
                placeholder: true,
                features: ['vars'],
            }],
            [{
                name: 'project_id_var',
                type: 'textarea',
                placeholder: true,
                // system: true,
                // description: '',
                features: ['vars'],
            }],
            [{
                name: 'title',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            }],
            [{
                name: 'connect_callback',
                type: 'switcher',
                default: false,
            }],
            [{
                name: 'is_on',
                type: 'unset',
            }],
            [{
                name: 'delete',
                type: 'unset',
            }],
        ]
    },
    {
        type: 'effect/program/app/integration/change',
        inherit: 'effect/program/app/integration/connect',
        icon: 'pen-square',
        // system: true,
        replace: {
            title: {
                name: 'title',
                type: 'textarea',
                features: ['vars'],
            },
            is_on: {
                name: 'is_on',
                type: 'textarea',
                placeholder: true,
                features: ['vars'],
            },
            delete: {
                name: 'delete',
                type: 'switcher',
            },
            connect_callback: {
                name: 'connect_callback',
                type: 'unset',
            }
        },
    },

    {
        type: 'effect/program/app/project/get',
        icon: 'dragon',
        system: true,
        fields: [
            [{
                name: 'project_id_var',
                type: 'text',
                placeholder: '{var:graph.project.id}',
                default: '{var:project_id}',
            }],
            [{
                name: 'result_var',
                type: 'text',
                placeholder: 'res',
                default: 'project',
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'effect/program/app/project/number',
        icon: 'hundred-points',
        system: true,
        fields: [
            [
                {
                    label: null,
                    description: '',
                    placeholder: true,
                    name: 'project_id_var',
                    type: 'text',
                    default: '{var:project_id}',
                },
            ],
            [
                {
                    label: null,
                    description: '',
                    placeholder: '',
                    name: 'var',
                    type: 'menu',
                    values: [
                        'permanent_messages',
                        'daily_messages',
                        'daily_count',
                        'daily_updated_at',
                        'tariff_expire_at',
                        'balance',
                        'ai_balance',
                        'tariff_pages',
                        'tariff_space',
                    ],
                    default: 'daily_messages',
                },
            ],
            [
                {
                    label: null,
                    description: '',
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'set',
                        'increase',
                        'decrease',
                        'multiply',
                        'divide',
                        'set_zero',
                    ],
                    default: 'set',
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                    default: '',
                    depends_on: {operation: ['set', 'increase', 'decrease', 'multiply', 'divide']}
                }
            ],
        ]
    },
    {
        type: 'effect/program/app/project/access',
        icon: 'dog',
        system: true,
        fields: [
            [
                {
                    label: null,
                    description: '',
                    placeholder: true,
                    name: 'project_id_var',
                    type: 'text',
                    default: '{var:project_id}',
                },
                {
                    label: null,
                    description: '',
                    placeholder: '',
                    name: 'operation',
                    type: 'menu',
                    values: [
                        'resume',
                        'delete',
                    ],
                    default: 'resume',
                },
            ],
            [{
                label: null,
                description: null,
                placeholder: true,
                name: 'days',
                type: 'text',
                depends_on: {operation: 'resume'}
            }],
            [{
                label: null,
                description: null,
                placeholder: true,
                name: 'values',
                type: 'tags',
                values: [
                    'graph',
                    'page',
                    'crm',
                ],
                localized: true,
                default: ['graph'],
            }],
            [{
                placeholder: true,
                name: 'values_str',
                type: 'textarea',
            }],
        ]
    },
    {
        type: 'effect/program/app/project/renew',
        icon: 'cash-register',
        system: true,
        fields: [
            [
                {
                    label: null,
                    description: '',
                    placeholder: true,
                    name: 'project_id_var',
                    type: 'text',
                    default: '{var:project_id}',
                    localeRoot: 'effect.program.app.project.get',
                },
            ],
            [{
                name: 'get_admin_from_project',
                type: 'switcher',
            }]
        ]
    },
    {
        type: 'effect/program/app/project/create',
        icon: 'plus-octagon',
        system: true,
        fields: [
            [{
                name: 'title',
                type: 'textarea',
                placeholder: true,
                // description: '',
                features: ['vars'],
            }],
            [
                {
                    name: 'tariff',
                    type: 'textarea',
                    placeholder: 'bonus',
                    // description: '',
                    features: ['vars'],
                    default: '{var:tariff|bonus}',
                },
                {
                    name: 'tariff_days_amount',
                    type: 'textarea',
                    placeholder: '7',
                    features: ['vars'],
                }
            ],
            [{
                name: 'photo_url',
                type: 'textarea',
                placeholder: true,
                // description: '',
                features: ['vars'],
                default: '{var:photo_url|}',
            }],
            [{
                name: 'get_admin_from_project',
                type: 'switcher',
                default: true,
            }]
        ]
    },
    {
        type: 'effect/program/app/project/employee',
        icon: 'user-chef',
        system: true,
        fields: [
            [{
                name: 'project_id_var',
                type: 'textarea',
                placeholder: true,
                description: '',
                features: ['vars'],
                default: '{var:project_id}',
            }],
            [{
                name: 'email',
                type: 'textarea',
                placeholder: true,
                description: '',
                features: ['vars'],
                default: '{contact:email}',
            }],
        ]
    },
    {
        type: 'effect/program/app/project/contact',
        icon: 'bell-exclamation',
        system: true,
        fields: [
            [{
                name: 'project_id_var',
                type: 'textarea',
                placeholder: true,
                description: '',
                features: ['vars'],
                default: '{var:project_id}',
            }],
            [{
                name: 'contact_value',
                type: 'textarea',
                placeholder: true,
                description: '',
                features: ['vars'],
                default: '{user:platform.id}',
            }],
        ]
    },
    {
        type: 'effect/program/app/admin/create',
        icon: 'user-cowboy',
        system: true,
        fields: [
            [
                {
                    label: null,
                    description: '',
                    placeholder: true,
                    name: 'username',
                    type: 'text',
                    default: '',
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'email',
                    type: 'text',
                    default: ''
                }
            ],
            [
                {
                    description: '',
                    placeholder: true,
                    name: 'password',
                    type: 'text',
                    default: '{var:password}'
                },
                {
                    description: '',
                    placeholder: true,
                    name: 'user_id',
                    type: 'text',
                    default: '{var:users.user.id}',
                }
            ],
        ]
    },

    {
        type: 'effect/program/app/stat',
        icon: 'chart-user',
        system: true,
    },
    {
        type: 'effect/core/var/note/item',
        icon: 'clipboard',
        fields: [
            [{
                label: '',
                description: '',
                name: 'value',
                type: 'textarea',
            }],
        ]
    },
]