import React, {useContext} from 'react'
import classes from '../../../../../Styles/ListStyles.module.scss'
import Fa from "../../../../../../../../../../components/Icon/Fa";
import FlowContext from "../../../../../../../../FlowContext";
import {useTranslation} from 'react-i18next';
import {dotenv} from "../../../../../../../../../../config/config";
import {useParams} from "react-router-dom";
import {isSystemProject} from "../../../../../../../../../../library/functions";


const List = ({containers}) => {
    const {addNodeEffectCondition} = useContext(FlowContext);

    // path params
    const params = useParams()
    const project_id = Number(params.project_id);

    const addNewContainer = (type) => {
        addNodeEffectCondition(type);
    }

    const {t} = useTranslation();
    const renderActionItem = (title, icon, index, type) => {
        return (
            <div className={classes.row_list} key={`blocklist_${index}`} onClick={()=>{addNewContainer(type)}}>
                <div className={classes.row_icon}><Fa icon={icon} options={'lg'}/></div>
                <div className={classes.row_title}>{title}</div>
            </div>
        )
    }

    const getTitle = (title, type) => {
        if(title === undefined || title === null) return t(`${type.split('/').join('.')}.title`);
        if(title) return title;
        return null;
    }
    
    const renderContainerList = () => {
        const rows = [];
        containers.forEach((container, index) => {
            if (container.system && !isSystemProject(project_id)) return

            const title = getTitle(container.title, container.type)
            rows.push(renderActionItem(title, container.icon, index, container.type));
        })
        return rows;
    }

    return (
        <div className={`containers-list-wrapper ${classes.wrapper_lists}`}>
            {renderContainerList()}
        </div>
    )
}

export default List