import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {deepGet, inArray, objectLength, ucFirst} from "../../library/functions";
import cryptoRandomString from "crypto-random-string";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";
import {createObjectFromIntegrations} from "../Integrations/IntegrationEdit";
import {contact_types} from "../../schemas/frontend/effects";

const WebhookEdit = () => {
    const {t} = useTranslation()
    const section = 'webhook'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, webhook, integration} = useSelector(store => store)
    const item = webhook.item
    const project_item = project.item;
    const integration_items = createObjectFromIntegrations(t, integration.list);

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            label: t('common.form.title.label'),
            placeholder: t('common.form.title.placeholder'),
            desc: t('common.form.title.desc'),
            name: "title",
            type: "text",
            required: true,
        },
        {
            label: t('common.form.name.label'),
            placeholder: t('common.form.name.placeholder'),
            desc: t('common.form.name.desc'),
            name: "name",
            type: "text",
            required: true,
        },
        {
            label: t('common.form.is_on.label'),
            desc: '',
            name: "is_on",
            type: "switcher",
        },
        {
            name: 'webhook_url',
            type: "text",
            placeholder: true,
            readonly: true,
        },
        {
            name: "params.result.ok",
            placeholder: true,
            type: "text",
        },
        {
            name: "params.result.url",
            placeholder: 'https://example.com/path...',
            type: "text",
        },
        {
            name: "handler",
            type: "heading",
        },
        {
            name: "params.contact.type",
            placeholder: '',
            type: "menu",
            values: [
                'unset',
                'app',
                ...contact_types,
            ],
            // default: 'unset',
            localized: true,
        },
        {
            name: "params.contact.store",
            placeholder: '',
            type: "menu",
            values: [
                'query',
                'form',
                'json',
            ],
            // default: 'json',
            localized: true,
        },
        {
            name: "params.contact.path",
            placeholder: 'object.user.id',
            type: "tags",
            values: [
                'id',
                'uid',
                'user.id',
                'object.id',
                'object.user.id',
                'tg_uid',
                'email',
                'phone',
                'customer_phone',
            ],
            features: ['adding'],
        },
        {
            name: "params.integration.id",
            type: "menu",
            data: 'integrations',
            values: integration_items,
        },

        {
            name: "additional",
            type: "heading",
        },
        {
            name: "params.instant_mode",
            type: "switcher",
        },
        {
            name: "params.response.type",
            type: "menu",
            placeholder: '',
            values: [
                'text/plain',
                'application/json',
                'application/xml',
                'text/html',
            ],
            localized: true,
        },
        {
            label: t('common.form.description.label'),
            placeholder: t('common.form.description.placeholder'),
            desc: t('common.form.description.desc'),
            name: "description",
            type: "textarea",
        },
    ]

    // itis form state
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 15}),
        title: '',
        is_on: true,
        description: '',
        params: {
            result: {
                ok: 'ok',
            },
            contact: {
                type: 'unset',
                store: 'json',
                path: [],
            },
            response: {
                type: 'text/plain',
            }
        },
        webhook_url: item.webhook_url ?? '',
    })

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !integration.list.length) {
            dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_item.id}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, objectLength(item), project_item.id])

    // set webhooks values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            form.setFieldsValue(item)
            // TODO: Finish it:
            // let paths = deepGet(item, 'params.contact.path');
            // if (!paths) form.setFieldsValue({params: {contact: {path: []}}})
            // console.log(paths)
        }
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_item.id}/${section}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        formCreateOrUpdate('Webhook', values, formFields, id, project_item, admin, dispatch, true)
        backToList()
    }

    let formFieldsResult = [...formFields]
    if (!item.id) formFieldsResult = formFields.filter((object) => !inArray(object.name, ['webhook_url']))

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={section} // for labels
                    form={form}
                    formFields={formFieldsResult}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default WebhookEdit