import React, {useEffect, useState} from 'react'
import classes from './LeftPanel.module.scss';
import projectMenuClasses from './ProjectListMenu/ProjectList.module.scss'
import LeftMenu from "./LeftMenu/LeftMenu";
import {Hamburger} from "../../../../components/Styled";
import {Avatar, Button, Tooltip} from 'antd';
import {ExportOutlined} from "@ant-design/icons";
import {dotenv, images, routes, screens} from "../../../../config/config";
import {Scrollbar} from "react-scrollbars-custom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {l} from "../../../../library/locale";
import {NavigateBtn, NavigateLink} from "../../../../components/Navigate/Navigate";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import {Collapse} from "react-collapse/lib/Collapse";
import {patchProjectItem} from "../../../../redux/reducers/ProjectReducer";
import {getUtcDateTime, inArray} from "../../../../library/functions";
import {sectionsWithFolders} from "../../../../components/MenuAccordion/MenuAccordion";
import {orderProjectList} from "../../../Projects/ProjectList";

const LeftPanel = ({adaptive = true}) => {

    const dispatch = useDispatch()
    const {admin, project, userField, site} = useSelector(store => store)

    const params = useParams()
    const navigate = useNavigate()
    const project_id = Number(params.project_id)
    const projectList = orderProjectList([...project.list, ...project.shared], userField);
    const isLocalSection = !!project_id // && project.item.id && project_id === project.item.id && projectList.length;

    let section = null;
    let pathnameArray = window.location.pathname.split('/');
    if (pathnameArray.length > 3) {
        section = pathnameArray[3];
    }

    const [isProjectMenuOpened, setProjectMenuStatus] = useState(false);
    const toggleProjectMenuStatus = () => {
        setProjectMenuStatus(!isProjectMenuOpened);
    };

    const [isExpanded, setIsExpanded] = useState(window.innerWidth >= screens.lg)
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (dimensions.width >= screens.lg && adaptive) {
            const sidebarCollapsed = localStorage.getItem('sidebar-collapsed')
            setIsExpanded(!sidebarCollapsed)
        } else {
            setIsExpanded(false)
        }
    }, [dimensions, adaptive, window.location.pathname + window.location.search])

    // redirect when project_id error // TODO finish it
    // if (project_id===undefined || project_id==='undefined') navigate(routes.project_list)

    // set project item if opened by direct url
    useEffect(() => {
        // avoid non authorized run and non project pages

        // console.log('isLocalSection', isLocalSection)
        if (isLocalSection && admin.authorized && admin.token && !projectList.length) {
            // console.info('getProjectList')
            dispatch({type: 'getProjectList', admin});
            dispatch({type: 'getSharedProjectList', admin});

            dispatch({
                type: 'getUserField',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_name: 'projects_list',
                }
            })
        }

        if (admin.authorized && projectList.length && project_id && project.item.id !== project_id) {
            // dispatch(setProjectItemById(project_id));
            dispatch({type: 'getProjectItem', admin, id: project_id});
        }
    }, [admin.authorized, projectList.length, project_id])


    useEffect(() => {
        if (admin.authorized && project.item.id && project.item.id === project_id) {
            if (!site.list || !site.list.length) {
                dispatch({
                    type: 'getSiteList', admin, filters: {
                        project_id: project.item.id,
                        ordering: 'id',
                        page_size: 10,
                    }
                })
            }
        }
    }, [admin.authorized, project.item.id])


    useEffect(() => {
        if (project.item.id === null) {
            dispatch({type: 'getProjectItem', admin, id: project_id});
        }
        else if (project.item.id === 0) {
            const firstProject = project.list[0]
            dispatch(patchProjectItem(firstProject));
            pathnameArray[2] = firstProject.id;
            navigate(pathnameArray.join('/'))
        }
    }, [project.item.id])

    // useEffect(() => {
    //     if (isLocalSection && admin.authorized && admin.token && projectList === undefined) {
    //         dispatch({
    //             type: 'getUserField',
    //             admin,
    //             filters: {
    //                 project_id: dotenv.main_project,
    //                 user_id: admin.user.id,
    //                 field_name: 'projects_list',
    //             }
    //         })
    //     }
    // }, [admin, userField.project_list])

    const handlerToggle = () => {
        if (isExpanded) {
            setIsExpanded(false)
            localStorage.setItem('sidebar-collapsed', true)
            return;
        }
        setIsExpanded(true)
        localStorage.removeItem('sidebar-collapsed')
    }

    const renderProjectsMenu = (list) => {
        if (!list.length) return null;

        const projectListItems = list.filter(element => element.id !== project_id);
        if (!projectListItems.length) return null;

        const projectsMenu = projectListItems.map((item, index) => {
            let projectSectionLink = `${routes.project_list}/${item.id}${section ? '/' + section : ''}`;
            if (inArray(section, sectionsWithFolders)) projectSectionLink += '?folder=auto';

            return <NavigateLink
                key={`left_project_list_menu_${index}`}
                className={projectMenuClasses.list_item}
                link={projectSectionLink}
            >
                <img className={projectMenuClasses.list_avatar}
                     src={item.photo_url ? item.photo_url : images.avatar.project}
                     alt='logoColor'/>
                <span className={projectMenuClasses.list_title}>{item.title}</span>
            </NavigateLink>
        });

        return <Collapse isOpened={isProjectMenuOpened} style={{width: '100%'}}>
            <div className={projectMenuClasses.list_wrapper}>
                {projectsMenu}
            </div>
        </Collapse>
    };

    return (
        <div
            id='wrapper_left_panel'
            className={`${classes.wrapper_left_panel} ${adaptive ? '' : classes.always_closed} user-select-none`}
            data-expanded={isExpanded ? 'true' : 'false'}
        >
            <div className={classes.header_logo}>
                <NavigateLink
                    link={isLocalSection ? routes.project_list : dotenv.start_page}
                    className={classes.header_image_logo}
                >
                    <img src={images.logo_small} alt='logoColor'/>
                </NavigateLink>
                <div id='header_close_button' className={classes.header_close_button} onClick={handlerToggle}>
                    <Hamburger id='arrow_close_left_menu' stroke={4}/>
                </div>
            </div>

            {isLocalSection ? <div
                className={`${projectMenuClasses.info_project} cursor-pointer`}
                onClick={projectList.length > 1 ? toggleProjectMenuStatus : null}
            >
                <div className={projectMenuClasses.info_project_inner}>
                    <img className={projectMenuClasses.info_project_avatar}
                         src={project.item.photo_url ? project.item.photo_url : images.avatar.project}
                         alt='logoColor'/>
                    <span className={projectMenuClasses.info_project_title}>{project.item.title}</span>
                    {projectList.length > 1 ? <Button type="text"
                                                      className={projectMenuClasses.info_project_arrow}>
                        {isProjectMenuOpened ? (<AiOutlineUp/>) : (<AiOutlineDown/>)}
                    </Button> : null}
                </div>
            </div> : null}

            <div className={classes.content_left_panel}>
                <Scrollbar>
                    <div className={classes.wrapper_left_menu}>
                        <div className={classes.left_menu}>
                            {isLocalSection ? renderProjectsMenu(projectList) : null}
                            <LeftMenu admin={admin} dispatch={dispatch}/>
                        </div>
                        <div className={`${classes.left_menu} ${classes.left_bottom_menu}`}>
                            <LeftMenu bottom={true} admin={admin} dispatch={dispatch} />
                        </div>
                    </div>
                </Scrollbar>
            </div>
            <div className={classes.footer_left_menu}>
                <div className={classes.wrapper_current_user}>
                    <Tooltip title={l('account.go_to_profile')} placement="topLeft">
                        <NavigateLink link={routes.admin.account} className={classes.current_user_info}>
                            <div className={classes.current_user_icon}>
                                {/*<Badge count={14} overflowCount={100}>*/}
                                <Avatar
                                    shape='circle'
                                    size='large'
                                    src={admin.user.photo_url ? admin.user.photo_url : images.avatar.user}/>
                                {/*</Badge>*/}
                            </div>
                            <div
                                className={classes.current_user_name}>{admin.user.first_name} {admin.user.last_name}</div>
                        </NavigateLink>
                    </Tooltip>
                    <Tooltip title={l('account.logout')} placement="topRight">
                        <NavigateBtn
                            type="text"
                            size="large"
                            link={routes.logout}
                            icon={<ExportOutlined/>}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default LeftPanel