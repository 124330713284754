import React, {useEffect, useState} from 'react'
import {
    SetFieldInfo,
    CheckboxButton,
    CheckboxField,
    MenuField,
    SwitchField,
    TagsField,
    TextField,
    TextAreaField,
    DateTimeField,
    DateField,
    TimeField,
    ParamsField,
    ProductsField,
    EditorField,
    FieldsField,
    AIAssistantField,
    HeadingField,
    getName,
    IconField,
    TwColorField,
    ScheduleField,
} from "../../../../../../components/Form/Field/FormFields";
import {FormItemWrapper} from "../../../../../../components/Form/FormItemWrapper";
import {PhotoField} from "../../../../../../components/Form/Field/PhotoField";
import {
    convertTextToHtmlEditable,
    deepGet,
    deepSet,
    isObjectsEqual,
} from "../../../../../../library/functions";
import Keyboard from "../Message/Keyboard/Keyboard";
import {removeElementsFromArray} from "../../../../library/flowFunctions";
import {Button, Form} from "antd";
import {moment_formats} from "../../../../../../config/config";
import CodeEditor from "../../../../../../components/Form/Editor/CodeEditor";
import {useSelector} from "react-redux";
import FieldsMaker from "../../../../../../components/Form/Field/FieldsMaker";
import {SimpleModal} from "../../../../../../components/Modal/SimpleModal";
import {UploadField} from "../../../../../../components/Form/Field/UploadField";
import {NavTreeField} from "../../../../../../components/Form/Field/NavTreeField";
import {SectorsField} from "../../../../../../components/Form/Field/SectorsField";

export const EffectElement = (
    {
        t,
        index,
        spec,
        localId,
        admin,
        project,
        form,
        field,
        effectType,
        runSave,
        section= 'common',
        localeRoot = null,
        localePath = null,
        container = 'effects-list-wrapper',
    }
) => {
    const effectTypeLocale = field.localeRoot || localeRoot || effectType.split('/').join('.');
    const formField = (Component) => {
        return (
            <Component
                t={t}
                index={index}
                localId={localId}
                form={form}
                field={field}
                admin={admin}
                project={project}
                runSave={runSave}
                localePath={localePath}
                effectTypeLocale={effectTypeLocale}
                container={container}
                section={section}
                spec={spec}
            />
        )
    }

    switch (field.type) {
        case 'conditions':
            return formField(RenderText); // TODO change it
        case 'text':
            return formField(RenderText);
        case 'photo':
            return formField(RenderPhoto); // single photo with url in value
        case 'upload':
            return formField(RenderUpload);
        case 'hidden':
            return formField(RenderHidden);
        case 'textarea':
            return formField(RenderTextArea);
        case 'datetime':
            return formField(RenderDateTime);
        case 'date':
            return formField(RenderDate);
        case 'time':
            return formField(RenderTime);
        case 'icon':
            return formField(RenderIconMenu);
        case 'tw_color':
            return formField(RenderColorsMenu);
        case 'menu':
            return formField(RenderFieldMenu);
        case 'tags':
            return formField(RenderFieldTags);
        case 'checkbox':
            return formField(RenderCheckbox);
        case 'switcher':
            return formField(RenderSwitcher);
        case 'editor':
            return formField(RenderEditor);
        case 'code':
            return formField(RenderCodeEditor);
        case 'button':
            return formField(RenderOptionButton);
        case 'keyboard':
            return formField(RenderKeyboard);
        case 'description':
            return formField(RenderDescription);
        case 'heading':
            return formField(RenderHeading);
        case 'params':
            return formField(RenderParams);
        case 'fields':
            return formField(RenderFields);
        case 'fields_maker':
            return formField(RenderFieldsMaker);
        case 'products':
            return formField(RenderProducts);
        case 'schedule':
            return formField(RenderSchedule);
        case 'nav':
            return formField(RenderNav);
        case 'sectors':
            return formField(RenderSectors);
        case 'ai':
            return formField(RenderAiAssistant);
        default:
            return null;
    }
}

const handle_data_spec_query = (field, spec) => {
    let data_filters = {}
    if (field.data_filters) data_filters = {...field.data_filters}
    if (field.data_spec_query) {
        // for (const param of field.data_spec_query) {
        //     data_filters[param] = spec[param]
        // }
        for (const [query_param, spec_param] of Object.entries(field.data_spec_query)) {
            // console.log('handle_data_spec_query', query_param, spec_param, deepGet(spec, spec_param))
            const spec_value = deepGet(spec, spec_param)
            if (spec_value !== undefined) data_filters = deepSet(data_filters, query_param, spec_value)
            // console.log('data_filters', data_filters)
        }
    }

    return data_filters
}

export const RenderDescription = ({t, field, index, effectTypeLocale, runSave}) => {

    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        />
    )
}

export const RenderHeading = ({t, field, index, effectTypeLocale, runSave}) => {

    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? 'margin-bottom-ps'}
        >
            <HeadingField
                t={t}
                section={effectTypeLocale}
                name={field.name}
                label={field.label}
                level={4}
            />
        </FormItemWrapper>
    )
}

export const RenderText = ({t, field, index, effectTypeLocale, runSave, onChange = null}) => {
    const onBlur = (value) => {
        // console.log('RenderText onBlur', value)
        if (runSave) runSave();
        if (onChange) onChange(value);
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <TextField
                t={t}
                section={effectTypeLocale}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                size={field.size}
                onBlur={onBlur}
            />
        </FormItemWrapper>
    )
}

export const RenderParams = ({t, field, form, spec, index, effectTypeLocale, runSave, container}) => {
    const onBlur = () => {
        runSave();
    }
    const onDelete = (index) => {
        const new_value = removeElementsFromArray([index], spec[field.name])
        // console.log('RenderParams onDelete', new_value)
        form.setFieldsValue({[field.related]: new_value});
        runSave();
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <ParamsField
                t={t}
                section={effectTypeLocale}
                localeRoot={field.localeRoot}
                localePath={field.localePath}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                size={field.size}
                onBlur={onBlur}
                onDelete={onDelete}
                container={container}
            />
        </FormItemWrapper>
    )
}

export const RenderFields = ({t, field, form, spec, index, effectTypeLocale, runSave, section}) => {
    const onBlur = () => {
        runSave();
    }
    const onDelete = (index) => {
        const new_value = removeElementsFromArray([index], spec[field.name])
        // console.log('RenderFields onDelete', new_value)
        form.setFieldsValue({[field.related]: new_value});
        // runSave({[field.name]: new_value});
        runSave();
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <FieldsField
                t={t}
                name={field.name}
                section={field.section || section}
                // label={field.label}
                // placeholder={field.placeholder}
                limit={field.limit}
                onBlur={onBlur}
                onDelete={onDelete}
            />
        </FormItemWrapper>
    )
}

export const RenderProducts = ({t, field, form, spec, index, effectTypeLocale, runSave, container}) => {
    const data_filters = handle_data_spec_query(field, spec);

    const onBlur = () => {
        runSave();
    }
    const onDelete = (index) => {
        const new_value = removeElementsFromArray([index], spec[field.name])
        // console.log('RenderParams onDelete', new_value);
        form.setFieldsValue({[field.related]: new_value});
        runSave();
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <ProductsField
                t={t}
                section={effectTypeLocale}
                container={container}
                name={field.name}
                values={field.values}
                data={field.data}
                data_filters={data_filters}
                onDelete={onDelete}
                onBlur={onBlur}
            />
        </FormItemWrapper>
    )
}

export const RenderSchedule = ({t, field, form, spec, index, effectTypeLocale, runSave, container, section}) => {
    const onBlur = () => {
        runSave();
    }
    const onDelete = (index) => {
        // const new_value = removeElementsFromArray([index], deepGet(spec, field.name));
        // // console.log('RenderParams onDelete', new_value);
        // form.setFieldValue(field.related, new_value);
        runSave();
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <ScheduleField
                t={t}
                label={field.label}
                name={field.name}
                section={field.section || section}
                // label={field.label}
                // placeholder={field.placeholder}
                limit={field.limit || 10}
                onBlur={onBlur}
                onDelete={onDelete}
                container={container}
            />
        </FormItemWrapper>
    )
}

export const RenderPhoto = ({t, admin, project, section, field, index, effectTypeLocale, runSave}) => {
    const fieldName = getName(field.name);
    // console.log('value', deepGet(spec, field.name, null))

    // const onBlur = (value) => {
    //     console.log('value', value)
    //     form.setFieldValue(fieldName, value);
    //
    //     // let formValues = form.getFieldsValue();
    //     // objDeepExtend(formValues, value);
    //     // form.setFieldsValue(formValues);
    //     // runSave(value);
    // }

    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <Form.Item
                // label={SetFieldInfo(t, section, name, label, 'label')}
                name={fieldName}
            >
                <PhotoField
                    t={t}
                    admin={admin}
                    project_id={project.item.id}
                    name={field.name}
                    section={effectTypeLocale}
                    aspect={field.aspect ?? 0}
                    limit={1}

                    // initialValue={form.getFieldValue(fieldName) ?? null}
                    // initialValue={deepGet(spec, field.name) ?? []}

                    // onChange={onBlur}
                    // onFileUpload={onBlur}
                    onFileUpload={runSave}

                    siteSection={field.section || section || 'flow'}
                    imageType="photo"
                />
            </Form.Item>
        </FormItemWrapper>
    )
}

export const RenderUpload = ({t, admin, project, section, form, field, spec, index, effectTypeLocale, runSave}) =>  {
    // console.log('project', project)
    // console.log('spec', spec)

    const onBlur = () => {
        runSave();
    }

    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <UploadField
                t={t}
                form={form}
                admin={admin}
                name={field.name}
                formValues={spec}
                project_id={project.item.id}
                section={field.section || section || 'flow'}

                onBlur={onBlur}
                accept={field.accept}

                localeRoot={effectTypeLocale}
                placeholder={field.placeholder}
                label={field.label}
                limit={field.limit || 1}
            />
        </FormItemWrapper>
    )
}

export const RenderNav = ({t, section, form, field, spec, index, effectTypeLocale, runSave}) =>  {
    // console.log('project', project)
    // console.log('spec', spec)

    const onBlur = (value) => {
        // console.log('onBlur', value)
        runSave(deepSet({}, field.name, value));
    }

    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <NavTreeField
                t={t}

                form={form}
                formValues={spec}

                name={field.name}
                section={field.section || section || 'flow'}

                onBlur={onBlur}
                accept={field.accept}

                label={field.label}
                placeholder={field.placeholder}
                localeRoot={effectTypeLocale}
            />
        </FormItemWrapper>
    )
}

export const RenderSectors = ({t, section, form, field, spec, index, effectTypeLocale, runSave}) =>  {
    // console.log('project', project)
    // console.log('spec', spec)

    const onBlur = (value) => {
        // console.log('onBlur', value)
        runSave(deepSet({}, field.name, value));
    }

    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <SectorsField
                t={t}
                onBlur={onBlur}

                form={form}
                formValues={spec}

                name={field.name}
                section={field.section || section || 'flow'}

                label={field.label}
                placeholder={field.placeholder}
                localeRoot={effectTypeLocale}
            />
        </FormItemWrapper>
    )
}

export const RenderHidden = ({t, field, index, effectTypeLocale}) => {
    return (
        <FormItemWrapper
            key={index}
            className="hide"
            desc=""
        >
            <TextField
                t={t}
                section={effectTypeLocale}
                name={field.name}
                label={field.name}
                size={field.size}
            />
        </FormItemWrapper>
    )
}

export const RenderTextArea = ({t, field, index, spec, effectTypeLocale, runSave}) => {
    // const [text, setText] = useState(spec[field.name]);
    //
    // const onChange = (text) => setText(text);
    //
    // const onBlur = () => {
    //     const newSpec = {...spec, [field.name]: text};
    //     runSave(newSpec);
    // }

    const onBlur = (event) => {
        // const value = event.target.value;
        runSave();
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <TextAreaField
                t={t}
                section={effectTypeLocale}
                name={field.name}
                label={field.label}
                onBlur={onBlur}
                max_length={field.max_length}
                placeholder={field.placeholder}
                // onChange={onChange}
            />
        </FormItemWrapper>
    )
}

export const RenderEditor = ({t, index, form, field, section, spec, effectTypeLocale, runSave}) => {
    // const fieldName = getName(field.name);
    // let specValue = deepGet(spec, fieldName, '');
    // // specValue = specValue.replace(/\n/g, "</div><div>");
    // // const [initialValue, setInitialValue] = useState(specValue);
    // const [specState, setSpecState] = useState(spec);

    const onBlur = () => {
        runSave();
    }

    return <FormItemWrapper
        key={index}
        desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        className={field.wrapperClassName ?? null}
    >
        <EditorField
            t={t}
            form={form}
            // formValues={specState}
            name={field.name}
            section={field.section || section || 'flow'}
            localeRoot={effectTypeLocale}
            imgFormat={field.imgFormat ?? 'default'}
            label={field.label}
            extended={field.extended ?? false}
            onBlur={onBlur}
            // onChange={onChange}
        />

        {/*<Form.Item
            name={fieldName}
            label={SetFieldInfo(t, effectTypeLocale, field.name, field.label, 'label')}
            className={field.wrapperClassName ?? null}
        >
            <EditorMCE
                t={t}
                form={form}
                fieldName={fieldName}
                initialValue={initialValue}
                onBlur={onBlur}
                // onChange={onChange}
                placeholder={SetFieldInfo(t, effectTypeLocale, field.name, field.placeholder, 'placeholder')}
                section={effectTypeLocale}
                extended={field.extended ?? false}
                imgFormat={field.imgFormat ?? 'default'}
            />
        </Form.Item>*/}
    </FormItemWrapper>
}

export const RenderAiAssistant = ({t, form, field, index, spec, effectTypeLocale, runSave}) => {
    let specValue = deepGet(spec, field.related, '');
    const {admin, project} = useSelector(store => store);

    const onBlur = (value) => {
        if (specValue) specValue += '\n\n';
        else specValue = '';
        // console.log('value', field.related, value);
        form.setFieldValue(field.related, specValue + value);
        // runSave();
        runSave({[field.related]: specValue + value});
    }

    return <FormItemWrapper
        key={index}
        desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        className={field.wrapperClassName ?? null}
    >
        <AIAssistantField
            t={t}
            form={form}
            admin={admin}
            name={field.name}
            project_id={project.item.id}
            fieldName={field.name}
            formValues={spec}
            onFinish={onBlur}
            section={effectTypeLocale}
            className={field.className ?? null}
            btnType={field.btnType ?? null}
            fieldLabel={field.fieldLabel ?? null}
            fieldDesc={field.fieldDesc ?? null}
            prompt={field.prompt ?? null}
        />
    </FormItemWrapper>
}

export const RenderCodeEditor = ({t, index, form, field, spec, effectTypeLocale, runSave}) => {
    let specValue = deepGet(spec, field.name, '');
    const [initialValue, setInitialValue] = useState(specValue);

    useEffect(() => {
        setInitialValue(specValue);
    }, [spec])

    const onBlur = () => {
        runSave();
    }

    return <FormItemWrapper
        key={index}
        desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        className={field.wrapperClassName ?? null}
    >
        <Form.Item
            name={field.name}
            label={SetFieldInfo(t, effectTypeLocale, field.name, field.label, 'label')}
            className={field.wrapperClassName ?? null}
        >
            <CodeEditor
                form={form}
                fieldName={field.name}
                initialValue={initialValue}
                onBlur={onBlur}
                placeholder={SetFieldInfo(t, effectTypeLocale, field.name, field.placeholder, 'placeholder')}
                language={deepGet(spec, 'language', 'javascript')}
            />
        </Form.Item>
    </FormItemWrapper>
}

export const RenderFieldsMaker = ({t, index, localId, form, field, spec, effectTypeLocale, runSave, section}) => {
    const formId = 'fields-maker-' + field.name + '-' + localId;
    const fieldName = getName(field.name);
    const specValue = deepGet(spec, field.name, '');
    const fieldLabel = SetFieldInfo(t, effectTypeLocale, field.name, field.label, 'label');
    // console.log('spec', spec.fields, specValue)

    const [fieldsForm] = Form.useForm();
    const [isVisibleModal, setVisibleModal] = useState(false);

    const onBlur = () => {
        const values = Object.values(fieldsForm.getFieldsValue());
        // console.log('values', values)
        const newValue = deepSet({}, fieldName, values);
        form.setFieldsValue(newValue);
        runSave();
    }

    return <FormItemWrapper
        key={index}
        desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        className={field.wrapperClassName ?? null}
    >
        <Form.Item
            name={fieldName}
            label={fieldLabel}
            className={field.wrapperClassName ?? null}
        >
            <Button type="primary" onClick={() => setVisibleModal(true)} className="btn-bordered-off">
                {t(effectTypeLocale + '.form.' + field.name + '.placeholder')}
            </Button>
        </Form.Item>

        <SimpleModal
            title={fieldLabel}
            isVisible={isVisibleModal}
            setVisible={setVisibleModal}
            actionLabel="apply"
            onOk={onBlur}
            maskClosable={false}
            width={1190}
            className="compact-modal"
        >
            <div id={formId}>
                <Form
                    form={fieldsForm}
                    className="margin-top-xp"
                    layout="vertical"
                >
                    <FieldsMaker
                        t={t}
                        id={field.name}
                        form={fieldsForm}
                        propName="fields"
                        item={{id: field.name, fields: specValue}}
                        section={field.section || section || 'fields'}
                        containerSelector={formId}
                    />
                </Form>
            </div>
        </SimpleModal>
    </FormItemWrapper>
}

export const RenderEditorOld = ({t, form, field, spec, effectTypeLocale, runSave}) => {
    // const [formValues, setFormValues] = useState({});
    // useEffect(() => {
    //     setFormValues(spec);
    // }, [spec]);
    // const [textEditor, setTextEditor] = useState('');

    // const onChangeText = (text) => setTextEditor(text);

    // const onBlur = () => {
    //     const newSpec = {...spec, [field.name]: textEditor};
    //     runSave(newSpec);
    // }

    // return <EditorMCE
    //     t={t}
    //     form={form}
    //     fieldName={field.name}
    //     // onBlur={onBlur}
    //     // onChange={onChangeText}
    //     initialValue={convertTextToHtmlEditable(spec.text)}
    // />

    return <EditorField
        t={t}
        form={form}
        name={field.name}
        formValues={spec}
        initialValue={convertTextToHtmlEditable(spec.text)}
        // onBlur={runSave}
        section={effectTypeLocale}
        label=""
    />

    //    export const EditorField = (
    //     {
    //         t,
    //         form,
    //         formValues = null,
    //         name = 'field',
    //         section = 'common',
    //         label = null,
    //         extended = false,
    //         onBlur = () => {
    //         },
    //         onChange = () => {
    //         },
    //     }
    // )
}

export const RenderIconMenu = ({t, field, index, effectTypeLocale, runSave, container}) => {
    const onChange = (value) => {
        // console.log('RenderIconMenu', value)
        // runSave({[field.name]: value});
        runSave();
    }
    return (
        // effect/core/chat/action/run
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <IconField
                t={t}
                name={field.name}
                onChange={onChange}
                set={field.set ?? null}
                container={container}
                section={effectTypeLocale}
            />
        </FormItemWrapper>
    )
}

export const RenderColorsMenu = ({t, field, index, effectTypeLocale, runSave, container}) => {
    const onChange = (value) => {
        // console.log('RenderIconMenu', value)
        // runSave({[field.name]: value});
        runSave();
    }
    return (
        // effect/core/chat/action/run
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <TwColorField
                t={t}
                name={field.name}
                onChange={onChange}
                container={container}
                placeholder={field.placeholder}
                label={field.label}
                section={effectTypeLocale}
            />
        </FormItemWrapper>
    )
}

export const RenderFieldMenu = ({t, field, index, effectTypeLocale, runSave, spec, container}) => {
    const data_filters = handle_data_spec_query(field, spec)
    const onChange = (value) => {
        // console.log('RenderFieldMenu', value)
        // runSave({[field.name]: value});
        runSave();
    }
    const localized = field.localized === undefined ? true : !!field.localized;
    return (
        // effect/core/chat/action/run
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <MenuField
                t={t}
                name={field.name}
                values={field.values}
                localized={localized}
                label={field.label}
                placeholder={field.placeholder}
                onChange={onChange}
                // onBlur={onChange}
                features={field.features}
                data={field.data}
                data_filters={data_filters}
                section={effectTypeLocale}
                container={container}
            />
        </FormItemWrapper>
    )
}

export const RenderFieldTags = ({t, form, field, index, effectTypeLocale, runSave, spec, container}) => {
    const data_filters = handle_data_spec_query(field, spec)

    const onChange = (value) => {
        // runSave({[field.name]: value})
        runSave();
    }
    return (
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <TagsField
                t={t}
                form={form}
                name={field.name}
                values={field.values}
                localized={!!field.localized}
                label={field.label}
                placeholder={field.placeholder}
                features={field.features}
                data={field.data}
                data_filters={data_filters}
                onChange={onChange}
                onBlur={onChange}
                section={effectTypeLocale}
                container={container}
            />
        </FormItemWrapper>
    )
}

export const RenderDateTime = ({t, field, index, effectTypeLocale, runSave}) => {
    const onBlur = () => {
        runSave();
    }

    return (
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <DateTimeField
                t={t}
                // format={field.format ?? moment_formats.datetime}
                section={effectTypeLocale}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                onBlur={onBlur}
                onChange={onBlur}
            />
        </FormItemWrapper>
    )
}
export const RenderDate = ({t, field, index, effectTypeLocale, runSave}) => {
    const onBlur = () => {
        runSave();
    }

    return (
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <DateField
                t={t}
                section={effectTypeLocale}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                onBlur={onBlur}
                onChange={onBlur}
            />
        </FormItemWrapper>
    )
}

export const RenderTime = ({t, field, index, effectTypeLocale, runSave}) => {
    const onBlur = () => {
        runSave();
    }

    return (
        <FormItemWrapper
            key={index}
            className={field.wrapperClassName ?? null}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
        >
            <TimeField
                t={t}
                format={field.format ?? moment_formats.time_extend}
                section={effectTypeLocale}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                onBlur={onBlur}
                onChange={onBlur}
            />
        </FormItemWrapper>
    )
}

export const RenderKeyboard = ({field, form, spec, localId, runSave}) => {
    const fieldName = getName(field.name);
    const initValue = deepGet(spec, fieldName, []) ?? [];
    // const [cards, setCards] = useState(initValue)

    const onChange = (value) => {
        if (!isObjectsEqual(initValue, value)) {
            runSave(deepSet({}, fieldName, value));
            // form.setFieldsValue({[field.related]: value});
            // runSave();
        }
    }

    return <Keyboard
        // cards={cards} setCards={setCards}
        localId={localId}
        initValue={initValue}
        onChange={onChange}
    />
    // return <DragTextButtons localId={localId} buttons={spec.buttons}/>
}

export const RenderOptionButton = ({field, form, spec, index, runSave}) => {
    const onChange = (e) => {
        // runSave({[field.name]: e.target.checked})
        const fieldName = getName(field.name);
        const newValue = deepSet({}, fieldName, e.target.checked);
        form.setFieldsValue(newValue);
        runSave(newValue);
    }

    return (
        <FormItemWrapper key={index} style={field.forTextarea ? {marginTop: -18} : null}>
            <CheckboxButton name={field.name} onChange={onChange} value={spec[field.name]}/>
        </FormItemWrapper>
    )
}


export const RenderCheckbox = ({t, field, form, index, effectTypeLocale, runSave}) => {
    const onChange = (e) => {
        // runSave({[field.name]: e.target.checked})
        const fieldName = getName(field.name);
        const newValue = deepSet({}, fieldName, e.target.checked);
        form.setFieldsValue(newValue);
        runSave(newValue);
    }

    return (
        <FormItemWrapper
            className={field.wrapperClassName ?? null}
            key={index}
            // desc={FieldInfoShort(effectTypeLocale, field.name, field.description, 'desc')} // replaced to tooltip
        >
            <CheckboxField
                t={t}
                onClick={onChange}
                section={effectTypeLocale}
                name={field.name}
                platforms={field.platforms}
                tooltip={field.tooltip}
            />
        </FormItemWrapper>
    )
}

export const RenderSwitcher = ({t, field, form, index, effectTypeLocale, runSave}) => {
    const onChange = (value) => {
        // runSave({[field.name]: value})
        const fieldName = getName(field.name);
        const newValue = deepSet({}, fieldName, value);
        form.setFieldsValue(newValue);
        runSave(newValue);
    }
    return (
        <FormItemWrapper
            key={index}
            desc={SetFieldInfo(t, effectTypeLocale, field.name, field.description, 'desc')}
            className={field.wrapperClassName ?? null}
        >
            <SwitchField t={t} onChange={onChange} section={effectTypeLocale} name={field.name}/>
        </FormItemWrapper>
    )
}