import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addIntegrationToList,
    fillIntegrationList,
    patchIntegrationInList,
    removeIntegrationFromList,
    setIntegrationItem,
} from "../reducers/IntegrationReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.integration;

function* getIntegrationList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillIntegrationList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getIntegrationItem(arg) {
    try {
        const response = yield request(
            arg.admin.token,
            base_path + '/' + arg.id,
            'get',
            {project_id: arg.project_id}
        );

        if (response.data.ok) yield put(setIntegrationItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createIntegration(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            // yield put(setIntegrationItem(response.data.result))
            yield put(addIntegrationToList(response.data.result)) // hide folder modal
            notice.success(l('platform.result.connected'))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateIntegration(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            // yield put(setIntegrationItem(response.data.result))
            yield put(patchIntegrationInList(response.data.result))
            notice.success(l('platform.result.connected'))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreIntegration(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeIntegrationFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteIntegration(arg) {
    try {
        const response = yield request(
            arg.admin.token,
            base_path + '/' + arg.id+'?project_id='+arg.project_id,
            'delete');

        if (response.data.ok) yield put(removeIntegrationFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* integrationSaga() {
    yield takeEvery('getIntegrationList', getIntegrationList);
    yield takeEvery('getIntegrationItem', getIntegrationItem);
    yield takeEvery('createIntegration',  createIntegration);
    yield takeEvery('updateIntegration',  updateIntegration);
    yield takeEvery('restoreIntegration', restoreIntegration);
    yield takeEvery('deleteIntegration',  deleteIntegration);
}

