import {author_types, profile_types, contact_types, commonLocaleRoot} from "./effects";

export const conditions_categories = {
    core: {
        event: [],
        var: [],
        wall: [],
        chat: [],
        graph: [],
        time: [],
        crm: [],
        sales: [],
        game: [],
    },
    integration: {
        tg: [],
        vk: [],
        crm: [],
        sales: [],
        site: [],
        table: [],
        delivery: [],
        other: [],
    }
}

const textValuedMethods = ['equal', 'contains', 'starts', 'ends', 'regexp', 'regexp_inverted'];
export const popularConditionTypes = {
    object_text: 'condition/core/event/object/text',
    object_attachment: 'condition/core/event/object/attachment',
}

export const conditions = [
    // event
    {
        type: 'condition/core/event/message/text',
        icon: 'message-pen',
        inherit: popularConditionTypes.object_text,
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },
    {
        type: 'condition/core/event/object/text',
        icon: 'calendar-lines-pen',
        fields: [
            [
                {
                    // label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [
                        'equal',
                        'contains',
                        'starts',
                        'ends',
                        'regexp',
                        'regexp_inverted',
                        'phone',
                        'email',
                        'number',
                        'int',
                    ],
                    default: 'equal',
                },
            ],
            [{
                // label: '',
                // description: '', // lang
                name: 'values',
                type: 'tags',
                features: ['adding'],
                values: ['/start', '/test', '/privacy', '/policy', '/help', '/menu', 'undefined', '/100', '1', '0'],
                depends_on: {method: textValuedMethods, by_json: false},
            }],
            [{
                label: '',
                description: null, // lang
                name: 'json_values',
                type: 'textarea',
                features: ['vars'],
                depends_on: {method: textValuedMethods, by_json: true},
                localeRoot: 'condition.core.var.text.match',
            }],
            [{
                description: '',
                name: 'cs',
                type: 'switcher',
                default: false,
                depends_on: {method: textValuedMethods},
            }],
            [{
                description: '',
                name: 'by_json',
                type: 'switcher',
                default: false,
                depends_on: {method: textValuedMethods},
                localeRoot: 'condition.core.var.text.match',
            }],
        ]
    },

    {
        type: 'condition/core/event/list/code',
        icon: 'book-sparkles',
        fields: [
            [
                {
                    name: 'values',
                    type: 'tags',
                    data: 'field',
                    features: ['creating'],
                    data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                    data_value_field: 'name',
                },
            ],
        ]
    },
    {
        type: 'condition/core/event/payload/text',
        icon: 'message-code',
        inherit: 'condition/core/event/object/text',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },
    // {
    //     type: 'condition/core/event/initiator',
    //     icon: 'alicorn',
    //     fields: [
    //         [{
    //             label: '',
    //             description: '',
    //             name: 'source',
    //             type: 'menu',
    //             values: [
    //                 // 'department',
    //                 'list',
    //                 'user',
    //             ],
    //             default: 'list',
    //             width: 12,
    //         },{
    //             label: '',
    //             description: '',
    //             name: 'department_values',
    //             type: 'tags',
    //             data: 'department',
    //             features: ['creating'], // было бы круто, но пока не горит
    //             depends_on: {source: ['department']},
    //             width: 12,
    //         },{
    //             label: '',
    //             description: '',
    //             name: 'list_values',
    //             type: 'tags',
    //             data: 'field',
    //             data_filters: {system_field_type: 'list', data_type_admin: 'int'},
    //             features: ['creating'], // было бы круто, но пока не горит
    //             depends_on: {source: ['list']},
    //             width: 12,
    //         },{
    //             label: '',
    //             description: '',
    //             name: 'user_values',
    //             type: 'tags',
    //             data: 'user',
    //             depends_on: {source: ['user']},
    //             width: 12,
    //         },],
    //     ]
    // },

    {
        type: 'condition/core/event/integration/item',
        icon: 'plug',
        fields: [
            [
                {
                    name: 'values',
                    type: 'tags',
                    data: 'integration',
                },
            ],
        ]
    },
    {
        type: 'condition/core/event/integration/type',
        icon: 'plug-circle-check',
        fields: [
            [
                {
                    name: 'values',
                    type: 'tags',
                    data: 'integration',
                    data_value_field: 'integration.platform'
                },
            ],
        ]
    },
    {
        type: 'condition/core/event/profile/chat',
        icon: 'screen-users',
        fields: [
            [
                {
                    name: 'values',
                    type: 'tags',
                    data: 'user',
                    data_filters: {profile_type: 'chat'},
                    data_query: {profile_types: ['chat', 'channel'], page_size: 0},
                },
            ],
        ]
    },
    {
        type: 'condition/core/event/profile/channel',
        icon: 'rss',
        fields: [
            [
                {
                    name: 'values',
                    type: 'tags',
                    data: 'user',
                    data_filters: {profile_type: 'channel'},
                    data_query: {profile_types: ['chat', 'channel'], page_size: 0},
                },
            ],
        ]
    },
    {
        type: 'condition/core/event/webhook/item',
        icon: 'code-pull-request',
        fields: [
            [
                {
                    name: 'values',
                    type: 'tags',
                    data: 'webhook',
                    features: ['creating'],
                    data_value_field: 'name',
                },
            ],
        ]
    },
    {
        type: 'condition/core/event/object/attachment',
        icon: 'paperclip',
        fields: [[
            {
                label: '',
                name: 'values',
                type: 'tags',
                values: [
                    'any',
                    'product',
                    'photo',
                    'story',
                    'story_mention',
                    'animation',
                    'audio',
                    'video',
                    'video_note',
                    'document',
                    'voice',
                    'link',
                    'sticker',
                    'contact',
                    'user',
                    'chat',
                    'poll',
                    'post',
                    'comment',
                    'dice',
                    'game',
                    'location',
                    'venue',
                    'market_album',
                ],
                default: ['any'],
                localized: true,
            },
        ]]
    },
    {
        type: 'condition/core/event/object/type',
        icon: 'square-chevron-down',
        fields: [[
            {
                label: '',
                name: 'values',
                type: 'tags',
                values: [
                    'any',
                    'message',
                    'comment',
                    'post',
                    'share',
                    'photo',
                    'audio',
                    'video',
                    'photo_comment',
                    'video_comment',
                    'topic_comment',
                    'member',
                    'admin',
                    'channel',
                    'notice',
                    'contact',
                    'poll',
                    'answer',
                    'dice',
                    'game',
                    'location',
                    'venue',
                ],
                default: ['any'],
                localized: true,
            },
        ]]
    },

    // common
    {
        type: 'condition/core/var/text/match',
        icon: 'file-code',
        fields: [
            [
                {
                    // label: '',
                    // description: '',
                    // placeholder: '',
                    name: 'var',
                    type: 'text',
                    features: ['vars'],
                },
            ],
            [
                {
                    // label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [
                        'equal',
                        'contains',
                        'starts',
                        'ends',
                        'regexp',
                        'regexp_inverted',
                        'phone',
                        'email',
                        'number',
                        'int',
                    ],
                    default: 'equal',
                },
            ],
            [{
                // label: '',
                description: null, // lang
                name: 'values',
                type: 'tags',
                features: ['adding'],
                values: ['/start', '/test', 'undefined', 'null', 'test', '/100', '1', '0'],
                depends_on: {method: textValuedMethods, by_json: false}
            }],
            [{
                // label: '',
                description: null, // lang
                name: 'json_values',
                type: 'textarea',
                features: ['vars'],
                depends_on: {method: textValuedMethods, by_json: true}
            }],
            [{
                description: '',
                name: 'cs',
                type: 'switcher',
                default: false,
                depends_on: {method: textValuedMethods}
            }],
            [{
                description: '',
                name: 'by_json',
                type: 'switcher',
                default: false,
                depends_on: {method: textValuedMethods}
            }],
        ]
    },
    {
        type: 'condition/core/var/number/compare',
        icon: 'file-binary',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    name: 'var',
                    type: 'text',
                    features: ['vars'],
                    width: 10,
                },
                {
                    label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [ // ==, <, >, <=, >=, !=
                        'equal',
                        'more_equal',
                        'less_equal',
                        'more',
                        'less',
                        'not_equal'
                    ],
                    default: 'equal',
                    width: 4,
                },
                {
                    label: '',
                    description: '',
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                    width: 10,
                }
            ],
            [
                {
                    name: 'to_number',
                    type: 'checkbox',
                    default: true,
                }
            ],
        ]
    },
    {
        type: 'condition/core/var/number/search',
        icon: 'folder-magnifying-glass',
        fields: [
            [
                {
                    // description: '', // is empty
                    name: 'var',
                    type: 'text',
                    features: ['vars'],
                    width: 10,
                },
                {
                    // description: '', // is empty
                    name: 'values',
                    type: 'tags',
                    width: 14,
                    features: ['adding'],
                    values: ['0', '1', '2', '3', '5', '10', '100', '1000'],
                }
            ],
        ]
    },
    {
        type: 'condition/core/var/array/search',
        icon: 'print-magnifying-glass',
        fields: [
            [
                {
                    // description: '', // is empty
                    name: 'var',
                    type: 'text',
                    features: ['vars'],
                },
            ],
            [
                {
                    // description: '', // is empty
                    name: 'values',
                    type: 'textarea',
                    features: ['vars'],
                }
            ],

            [
                {
                    description: '',
                    name: 'as_numbers',
                    type: 'checkbox',
                    default: false,
                    depends_on: {cs: false}
                }
            ],
            [
                {
                    description: '',
                    name: 'cs',
                    type: 'checkbox',
                    default: false,
                    depends_on: {as_numbers: false}
                }
            ],
        ]
    },
    {
        type: 'condition/core/var/bool/check',
        icon: 'toggle-large-on',
        fields: [
            [
                {
                    // description: '', // is empty
                    name: 'var',
                    type: 'text',
                    features: ['vars'],
                },
            ],
        ]
    },
    {
        type: 'condition/core/graph/result/ok',
        icon: 'clipboard-check',
    },

    // Wall
    {
        type: 'condition/core/wall/post/id',
        icon: 'memo-circle-info',
        inherit: 'condition/core/var/number/search',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },
    {
        type: 'condition/core/wall/comment/text',
        icon: 'comment-text',
        inherit: 'condition/core/event/object/text',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },
    {
        type: 'condition/core/wall/post/text',
        icon: 'file-signature',
        inherit: 'condition/core/event/object/text',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },
    {
        type: 'condition/core/chat/member',
        icon: 'users-viewfinder',
        fields: [
            [
                {
                    name: 'chat_id',
                    type: 'menu',
                    data: 'user',
                    data_filters: {profile_type: ['chat', 'channel']},
                    data_query: {profile_types: ['chat', 'channel'], page_size: 0},
                    depends_on: {set_by_var: false},
                    width: 24,
                },
                {
                    name: 'chat_id',
                    placeholder: '{t:chat_user_id}',
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'condition.core.chat.member.synonym',
                    depends_on: {set_by_var: true},
                    width: 24,
                }
            ],
            [
                {
                    name: 'types',
                    type: 'tags',
                    values: ['member', 'admin', 'owner'],
                    localized: true,
                    depends_on: {set_by_var: false},
                    width: 24,
                },
                {
                    name: 'types',
                    placeholder: '{t:array}',
                    type: 'textarea',
                    features: ['vars'],
                    localeRoot: 'condition.core.chat.member.synonym',
                    depends_on: {set_by_var: true},
                    width: 24,
                }
            ],

            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'condition/core/chat/message/allowed',
        icon: 'message-check',
        fields: []
    },
    {
        type: 'condition/core/graph/state/active',
        icon: 'person-running',
        fields: [
            [{
                name: 'graph_ids',
                type: 'tags',
                data: 'graph',
                features: ['adding'],
                depends_on: {is_current: false},
            }],
            [{
                name: 'is_current',
                type: 'checkbox',
                default: false,
            }],
        ]
    },
    {
        type: 'condition/core/graph/history/item',
        icon: 'person-circle-check',
        inherit: 'condition/core/graph/state/active',
        system: true,
    },

    {
        type: 'condition/core/graph/history/block',
        icon: 'person-carry-box',
        fields: [
            [{
                name: 'graph_id',
                type: 'menu',
                data: 'graph',
                depends_on: {is_current: false, by_var: false},
            }],
            [{
                name: 'graph_id_var',
                type: 'text',
                features: ['vars'],
                depends_on: {is_current: false, by_var: true},
            }],
            [{
                name: 'local_ids',
                type: 'tags',
                data: 'graphNode',
                data_spec_query: {graph_id: 'graph_id'},
                features: ['adding'],
                depends_on: {by_var: false},
            }],
            [{
                name: 'local_ids_var',
                type: 'textarea',
                features: ['vars'],
                depends_on: {is_current: false, by_var: true},
            }],
            [{
                name: 'is_current',
                type: 'checkbox',
                default: false,
                depends_on: {by_var: false},
            }],
            [{
                name: 'by_var',
                type: 'checkbox',
                default: false,
                depends_on: {is_current: false},
            }],
        ]
    },

    // CRM
    {
        type: 'condition/core/crm/list/subscribe',
        icon: 'book-user',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                }
            ]
        ]
    },
    {
        type: 'condition/core/crm/field/text',
        icon: 'input-text',
        fields: [
            [
                {
                    // label: '',
                    // description: '',
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'crm', data_type_admin: 'text'},
                },
            ],
            [
                {
                    // label: '',
                    // description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [
                        'equal',
                        'contains',
                        'starts',
                        'ends',
                        'regexp',
                        'regexp_inverted',
                        'phone',
                        'email',
                        'number',
                        'int',
                    ],
                    default: 'equal',
                },
            ],
            [{
                name: 'values',
                type: 'tags',
                features: ['adding'],
                depends_on: {method: textValuedMethods, by_json: false}
            }],
            [{
                name: 'json_values',
                type: 'textarea',
                features: ['vars'],
                depends_on: {method: textValuedMethods, by_json: true},
                localeRoot: 'condition.core.var.text.match',
            }],
            [{
                description: '',
                name: 'cs',
                type: 'switcher',
                default: false,
                depends_on: {method: textValuedMethods}
            }],
            [{
                description: '',
                name: 'by_json',
                type: 'switcher',
                default: false,
                depends_on: {method: textValuedMethods},
                localeRoot: 'condition.core.var.text.match',
            }],
        ]
    },
    {
        type: 'condition/core/crm/contact/exists',
        icon: 'address-card',
        fields: [
            [{
                description: '',
                placeholder: '',
                name: 'contact_type',
                type: 'menu',
                values: contact_types,
                default: 'phone',
                localized: true,
            },],
            [{
                description: '',
                name: 'values',
                type: 'tags',
                features: ['adding'],
                values: [
                    '{var:object.text}',
                    '{num:{var:object.text}|phone}',
                    '{num:{var:phone}|phone}',
                    '{var:platform.user.phone}',
                    '{var:platform.user.email}',
                    '{var:request.query.id}',
                    '{var:request.query.uid}',
                ],
                localized: false,
            }],
            [{
                name: 'only_current',
                type: 'checkbox',
                default: false,
            }],
        ]
    },

    {
        type: 'condition/core/crm/contact/defined',
        icon: 'clipboard-check',
        inherit: 'condition/core/crm/contact/exists',
        replace: {
            values: {
                name: 'values',
                type: 'unset',
            },
            only_current: {
                name: 'only_current',
                type: 'unset',
            },
        }
    },
    {
        type: 'condition/core/crm/field/number',
        icon: 'input-numeric',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'crm', data_type_admin: 'float'},
                },
            ],
            [
                {
                    label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [ // <, >, <=, >=, !=, ==
                        'equal',
                        'more_equal',
                        'less_equal',
                        'more',
                        'less',
                        'not_equal'
                    ],
                    default: 'more_equal',
                    width: 10,
                },
                {
                    label: '',
                    description: '',
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                    width: 14,
                }
            ],
        ]
    },
    {
        type: 'condition/core/crm/profile/type',
        icon: 'user',
        fields: [
            [
                {
                    description: '',
                    placeholder: '',
                    name: 'profile',
                    type: 'menu',
                    values: author_types,
                    default: 'user',
                },
                {
                    description: '',
                    name: 'types',
                    type: 'tags',
                    values: profile_types,
                    localized: true,
                    default: ['user'],
                },
            ],
        ]
    },
    {
        type: 'condition/core/crm/utm/id',
        icon: 'tags',
        fields: [
            [{
                name: 'utm_ids',
                type: 'tags',
                placeholder: '{var:utm.id}',
                features: ['adding'],
                values: ['{utm:}', '{var:utm.utm_id}', '{var:result.id}'],
                localized: false,
            }],
        ]
    },

    // Games
    {
        type: 'condition/core/game/achievement/check',
        icon: 'square-star',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'achievement', data_type_admin: 'int'},
                }
            ]
        ],
    },
    {
        type: 'condition/core/game/achievement/count',
        icon: 'square-star',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    name: 'code',
                    type: 'menu',
                    features: ['creating'],
                    data: 'field',
                    data_filters: {system_field_type: 'achievement', data_type_admin: 'int'},
                },
            ],
            [
                {
                    label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [ // <, >, <=, >=, !=, ==
                        'more',
                        'less',
                        'equal',
                        'more_equal',
                        'less_equal',
                        'not_equal'
                    ],
                    default: 'more',
                    width: 10,
                },
                {
                    label: '',
                    description: '',
                    name: 'value',
                    type: 'text',
                    features: ['vars'],
                    default: '0',
                    width: 14,
                }
            ],
        ],
    },
    {
        type: 'condition/core/game/currency/count',
        icon: 'piggy-bank',
        inherit: 'condition/core/crm/field/number',
        replace: {
            code: {
                label: '',
                description: '',
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'currency', data_type_admin: 'int'},
            },
        }
    },
    // {
    //     type: 'condition/core/game/money/count',
    //     icon: 'piggy-bank',
    //     inherit: 'condition/core/crm/field/number',
    //     replace: {
    //         code: {
    //             label: '',
    //             description: '',
    //             name: 'code',
    //             type: 'menu',
    //             features: ['creating'],
    //             data: 'field',
    //             data_filters: {system_field_type: 'money', data_type_admin: 'float'},
    //         },
    //     }
    // },
    {
        type: 'condition/core/game/resource/count',
        icon: 'person-digging',
        inherit: 'condition/core/crm/field/number',
        replace: {
            code: {
                label: '',
                description: '',
                name: 'code',
                type: 'menu',
                features: ['creating'],
                data: 'field',
                data_filters: {system_field_type: 'resource', data_type_admin: 'int'},
            },
        }
    },

    // dates
    {
        type: 'condition/core/time/moment/check',
        icon: 'circle-check',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [
                        'less',
                        'more',
                    ],
                    default: 'less',
                    width: 10,
                },
                {
                    label: '',
                    description: '',
                    name: 'value',
                    type: 'datetime',
                    features: ['vars'],
                    width: 14,
                },
            ],
        ]
    },
    {
        type: 'condition/core/time/dates/compare',
        icon: 'calendars',
        fields: [
            [
                {
                    label: '',
                    description: '',
                    name: 'left',
                    type: 'text',
                    features: ['vars'],
                    width: 10,
                },
                {
                    label: '',
                    description: '',
                    placeholder: '',
                    name: 'method',
                    type: 'menu',
                    values: [ // <, >, <=, >=, !=, ==
                        'equal',
                        'more_equal',
                        'less_equal',
                        'more',
                        'less',
                        'not_equal'
                    ],
                    default: 'equal',
                    width: 4,
                },
                {
                    label: '',
                    description: '',
                    name: 'right',
                    type: 'text',
                    features: ['vars'],
                    width: 10,
                },
            ],
            [{
                name: 'with_time',
                type: 'checkbox',
                description: '',
            }]
        ]
    },
    {
        type: 'condition/core/time/week/day',
        icon: 'calendar-day',
        fields: [[
            {
                label: '',
                name: 'values',
                type: 'tags',
                values: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                ],
                localized: true,
            },
        ]]
    },
    {
        type: 'condition/core/time/date/range',
        icon: 'calendar-range',
        fields: [
            [
                {
                    description: '',
                    name: 'start',
                    type: 'date',
                },
                {
                    description: '',
                    name: 'finish',
                    type: 'date',
                },
            ],
        ],
    },
    {
        type: 'condition/core/time/time/range',
        icon: 'watch',
        fields: [
            [
                {
                    description: '',
                    name: 'start',
                    type: 'time',
                    wrapperClassName: 'margin-none',
                },
                {
                    description: '',
                    name: 'finish',
                    type: 'time',
                    wrapperClassName: 'margin-none',
                },
            ],
            [{
                name: 'description',
                type: 'description',
            }],
        ]
    },

    // sales
    {
        type: 'condition/core/sales/product/price',
        icon: 'money-check-dollar',
        inherit: 'condition/core/var/number/compare',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
            to_number: {
                name: 'to_number',
                type: 'unset',
            }
        }
    },
    {
        type: 'condition/core/sales/product/title',
        icon: 'money-check-pen',
        inherit: popularConditionTypes.object_text,
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },

    // other
    {
        type: 'condition/core/event/object/id',
        icon: 'file-circle-info',
        inherit: 'condition/core/var/number/compare',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },


    // {
    //     type: 'condition/core/var/text/length',
    //     icon: 'clipboard-question',
    //     inherit: 'condition/core/var/number/compare',
    //     replace: {
    //         var: {
    //             name: 'var',
    //             type: 'unset',
    //         },
    //     }
    // },

    // {
    //     type: 'condition/core/var/expression/check',
    //     icon: 'display-code',
    //     fields: [
    //         [
    //             {
    //                 label: '',
    //                 description: null,
    //                 name: 'value',
    //                 type: 'textarea',
    //                 features: ['vars'],
    //             },
    //         ],
    //     ]
    // },


    {
        type: 'condition/integration/delivery/senler/group',
        icon: 'users-line',
        fields: [
            [
                {
                    name: 'integration_id',
                    type: 'menu',
                    data: 'integration',
                    data_filters: {'integration.platform': 'senler'},
                    // features: ['creating']
                },
            ],
            [
                {
                    name: 'values',
                    type: 'tags',
                    features: ['adding', 'creating'],
                    data: 'senlerGroup',
                    data_spec_query: {integration_id: 'integration_id'}
                },
            ],
        ]
    },
    {
        type: 'condition/integration/delivery/senler/bot',
        icon: 'users-line',
        inherit: 'condition/integration/delivery/senler/group',
        replace: {
            values: {
                name: 'values',
                type: 'tags',
                features: ['adding'],
                data: 'senlerBot',
                data_spec_query: {integration_id: 'integration_id'}
            },
        }
    },
    {
        type: 'condition/integration/crm/sm/group',
        icon: 'school-circle-check',
        fields: [
            [{
                name: 'integration_var',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'sm'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'values_var',
                type: 'textarea',
                features: ['vars'],
                placeholder: '{var:groups}',
                data_spec_query: {integration_id: 'integration_id'},
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'values',
                type: 'tags',
                data: 'smGroup',
                data_spec_query: {integration_id: 'integration_id'},
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'email',
                type: 'textarea',
                features: ['vars'],
                default: '{contact:email}',
                placeholder: '{var:email}',
            }],
            // [{
            //     name: 'user_id',
            //     type: 'textarea',
            //     features: ['vars'],
            //     default: '{contact:sm_id}',
            //     placeholder: '{var:sm_id}',
            // }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'condition/integration/crm/sm/product',
        icon: 'school-lock',
        fields: [
            [{
                name: 'integration_var',
                type: 'text',
                // default: '{var:graph.integration.id}', // do not turn it on!
                placeholder: '{var:integration_id}',
                depends_on: {set_by_var: true},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'integration_id',
                type: 'menu',
                data: 'integration',
                data_filters: {'integration.platform': 'sm'},
                depends_on: {set_by_var: false},
                localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'values_var',
                type: 'textarea',
                features: ['vars'],
                placeholder: '{var:groups}',
                data_spec_query: {integration_id: 'integration_id'},
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'values',
                type: 'menu',
                data: 'smProduct',
                data_spec_query: {integration_id: 'integration_id'},
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'email',
                type: 'textarea',
                features: ['vars'],
                default: '{contact:email}',
                placeholder: '{var:email}',
                localeRoot: 'condition.integration.crm.sm.group'
            }],
            // [{
            //     name: 'user_id',
            //     type: 'textarea',
            //     features: ['vars'],
            //     default: '{contact:sm_id}',
            //     placeholder: '{var:sm_id}',
            //     localeRoot: 'condition.integration.crm.sm.group'
            // }],
            [{
                name: 'set_by_var',
                type: 'checkbox',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
        ]
    },
    {
        type: 'condition/integration/vk/topic/id',
        icon: 'file-circle-check',
        inherit: 'condition/core/var/number/search',
        replace: {
            var: {
                name: 'var',
                type: 'unset',
            },
        }
    },
    {
        type: 'condition/core/crm/is/admin',
        icon: 'user-group-crown',
        system: true,
        fields: []
    },
]